import format from 'date-fns/format';
import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePatientPersonalInformation } from '../actions';
import { UPDATE_PATIENT_PERSONAL_INFORMATION } from '../constants';
import { updatePatientPersonalInformationEndpoint } from '../endpoints';

const updatePatientPersonalInformationOperation = createLogic<typeof updatePatientPersonalInformation>({
  type: UPDATE_PATIENT_PERSONAL_INFORMATION,
  latest: true,

  /* istanbul ignore next */
  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PATIENT.CONTACT_INFORMATION.PATH;

    const { endpoint, url } = updatePatientPersonalInformationEndpoint;

    const mapFormikValuesToApiKeys = {
      firstName: 'first_name',
      lastName: 'last_name',
      birthDate: 'date_of_birth',
      timeZone: 'timezone',
      sex: 'sex',
      age: 'age',
      maritalStatus: 'marital_status',
      genderIdentity: 'gender_identity',
      about: 'about_me',
      avatarFile: 'avatar',
    };

    const formData = new FormData();

    Object.keys(mapFormikValuesToApiKeys).forEach((key) => {
      const apiKey = mapFormikValuesToApiKeys[key as keyof typeof mapFormikValuesToApiKeys];

      const formikValue = action.values[key as keyof typeof action.values];

      if (typeof formikValue !== 'undefined' && !(formikValue instanceof Date)) {
        formData.append(apiKey, formikValue);
      }

      if (typeof formikValue !== 'undefined' && formikValue instanceof Date && key === 'birthDate') {
        formData.append(apiKey, format(formikValue, 'yyyy-MM-dd'));
      }
    });

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updatePatientPersonalInformationOperation;
