import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { createWorkingBreak } from '../actions';
import { fetchProvideCalendar } from '../actions';
import { CREATE_WORKING_BREAK } from '../constants';
import { createWorkingBreakEndpoint } from '../endpoints';

const createWorkingBreakOperation = createLogic<typeof createWorkingBreak>({
  type: CREATE_WORKING_BREAK,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = createWorkingBreakEndpoint;

    const body: { date_specific_brakes: { date: string }[] } = {
      date_specific_brakes: [],
    };

    try {
      if (action.values.start instanceof Date && action.values.end instanceof Date) {
        const arr = eachDayOfInterval({
          start: action.values.start,
          end: action.values.end,
        });

        body.date_specific_brakes = arr.map((item) => ({
          date: format(item, 'yyyy-MM-dd'),
        }));
      }

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      action.form.setFieldValue('break', {
        start: null,
        end: null,
      });

      dispatch(fetchProvideCalendar());

      redirect(ROUTES.DASHBOARD.PROVIDER.SPECIALITY_AND_HOURS_CALENDAR.PATH);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default createWorkingBreakOperation;
