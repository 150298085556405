import downloadFile from './downloadFile';
import fetchAvailableStates from './fetchAvailableStates';
import fetchAvailableTimezones from './fetchAvailableTimezones';
import fetchBoardLicenses from './fetchBoardLicenses';
import fetchProviderMedicalTitles from './fetchProviderMedicalTitles';
import fetchProviderSpecialties from './fetchProviderSpecialties';
import fetchSocialLinksOperation from './fetchSocialLinks';
import fetchSymptoms from './fetchSymptoms';
import sendContactUsLink from './sendContactUs';

const operations = [
  fetchProviderSpecialties,
  fetchAvailableTimezones,
  fetchProviderMedicalTitles,
  fetchSocialLinksOperation,
  fetchAvailableStates,
  fetchBoardLicenses,
  sendContactUsLink,
  fetchSymptoms,
  downloadFile,
];

export default operations;
