import type { RcFile } from 'antd/lib/upload';
import type { FormikHelpers } from 'formik';

import type { SendMessage } from 'hooks/useChatMessages/type';
import { actionCreator } from 'utils/state';

import * as constants from './constants';

export const fetchChatMessages = actionCreator(
  ({ chatId, after = null, before = null }: { chatId: string; after?: string | null; before?: string | null }) => ({
    type: constants.FETCH_CHAT_MESSAGES,
    chatId,
    after,
    before,
  }),
);

export const setMessages = actionCreator((messageIds: string[]) => ({
  type: constants.SET_MESSAGES,
  messageIds,
}));
export const deleteChatMessage = actionCreator(({ chatId, messageId }: { chatId: string; messageId: string }) => ({
  type: constants.DELETE_CHAT_MESSAGE,
  chatId,
  messageId,
}));

export const deleteChatMessageSuccess = actionCreator((id: string) => ({
  type: constants.DELETE_CHAT_MESSAGE_SUCCESS,
  id,
}));

export const flushMessages = actionCreator(constants.FLUSH_MESSAGES);
export const createChatMessage = actionCreator(
  (chatId: string, text: string, attachments: RcFile[], form: FormikHelpers<SendMessage>) => ({
    type: constants.CREATE_CHAT_MESSAGE,
    chatId,
    text,
    attachments,
    form,
  }),
);
export const updateChatMessage = actionCreator(
  (chatId: string, messageId: string, text: string, form: FormikHelpers<SendMessage>) => ({
    type: constants.UPDATE_CHAT_MESSAGE,
    chatId,
    messageId,
    text,
    form,
  }),
);

export const setEditingChatMessage = actionCreator(
  (message: { chatId: string; messageId: string; text: string } | null) => ({
    type: constants.SET_EDITING_CHAT_MESSAGE,
    message,
  }),
);

export const setUploadProgress = actionCreator(
  ({ progress, fileName }: { progress: any; fileName: string | null }) => ({
    type: constants.SET_UPLOAD_PROGRESS,
    progress,
    fileName,
  }),
);

export const cancelUploadFile = actionCreator(constants.CANCEL_UPLOAD_FILE);
export const setSelectedAttachmentId = actionCreator((id: string | null) => ({
  type: constants.SET_SELECTED_ATTACHMENT_ID,
  id,
}));
export const setAttachmentIds = actionCreator((ids: string[]) => ({
  type: constants.SET_ATTACHMENT_IDS,
  ids,
}));
export const fetchChatAttachments = actionCreator((chatId: string) => ({
  type: constants.FETCH_CHAT_ATTACHMENTS,
  chatId,
}));
export const deleteChatAttachment = actionCreator(({ chatId, messageId }: { chatId: string; messageId: string }) => ({
  type: constants.DELETE_CHAT_ATTACHMENT,
  chatId,
  messageId,
}));
export const deleteChatAttachmentSuccess = actionCreator((id: string) => ({
  type: constants.DELETE_CHAT_ATTACHMENT_SUCCESS,
  id,
}));
export const markMessagesAsRead = actionCreator(({ chatId }: { chatId: string }) => ({
  type: constants.MARK_MESSAGES_AS_READ,
  chatId,
}));
