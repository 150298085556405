import omitBy from 'lodash/omitBy';
import { without, pick, mergeDeepRight, map, isNil } from 'ramda';

const constructRemovedAssessmentOrders = (allAssessmentOrders: any, choosenCategories: any, assessment: any) => {
  const removedOrders = without(choosenCategories, allAssessmentOrders);
  const selectedChanges = pick<any, any>(removedOrders, assessment);
  const preparedAssessment = omitBy(selectedChanges, isNil);

  return map((item) => mergeDeepRight(item, { _destroy: true }), preparedAssessment as any);
};

export default constructRemovedAssessmentOrders;
