import normalize from 'json-api-normalizer';

import { PROVIDER_PROFILE_INCLUSIONS } from 'constants/profile';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPayoutMethod } from '../actions';
import { fetchProviderProfile } from '../actions';
import { FETCH_PAYOUT_METHOD } from '../constants';
import { fetchPayoutMethodEndpoint } from '../endpoints';

const fetchPayoutMethodOperation = createLogic<typeof fetchPayoutMethod>({
  type: FETCH_PAYOUT_METHOD,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchPayoutMethodEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);

      const response = normalize(data);
      const inclusions = [PROVIDER_PROFILE_INCLUSIONS.PAYOUT_METHOD];

      dispatch(fetchProviderProfile({ include: inclusions.join(',') }));
      dispatch(dataApiSuccess({ endpoint, response }));

      const stripeAccountLinkUrl = data?.meta?.stripe_account_link_url;
      if (stripeAccountLinkUrl) {
        window.open(stripeAccountLinkUrl);
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchPayoutMethodOperation;
