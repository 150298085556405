const constructSpecialties = <T, K extends string>(existedSpecialties: T, newSpecialityId: K) => {
  let specialties = [{ speciality_id: newSpecialityId }];

  if (Array.isArray(existedSpecialties)) {
    for (let i = 0; i < existedSpecialties.length; i += 1) {
      const speciality = existedSpecialties[i];

      if (typeof speciality.id !== 'undefined' && typeof speciality.specialityId !== 'undefined') {
        // Remove from DB
        if (parseInt(speciality.specialityId, 10) !== parseInt(newSpecialityId, 10)) {
          const destroyed = {
            id: speciality.id as string,
            speciality_id: speciality.specialityId,
            _destroy: true,
          };

          specialties.push(destroyed);
        }

        // Remove from body
        if (parseInt(speciality.specialityId, 10) === parseInt(newSpecialityId, 10)) {
          specialties = specialties.filter(
            (item) => parseInt(item.speciality_id as string, 10) !== parseInt(speciality.specialityId, 10),
          );
        }
      }
    }
  }

  return specialties;
};

export default constructSpecialties;
