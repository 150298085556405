import type { FormikHelpers } from 'formik';
import { StatusCodes } from 'http-status-codes';
import { cond, equals, T, pathOr, includes, always } from 'ramda';
import type { Store } from 'redux';

import { dataApiFailure } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import showErrorNotifications from 'utils/showErrorNotifications';

import makeClearAccount from './makeClearAccount';
import makeHandleForm from './makeHandleForm';
import makeSetErrorToStore from './makeSetErrorToStore';

export interface HandleErrorProps {
  dispatch: Store['dispatch'];
  error: MiddlewareErrorType;
  skipHandlers?: boolean;
  endpoint?: string;
  form?: FormikHelpers<any>;
  formStatusKeys?: string[];
  excludeErrorArray?: number[];
  showFlashMessage?: boolean;
  withStatus?: boolean;
}

const requestErrorHandler = ({
  error,
  dispatch,
  skipHandlers = false,
  endpoint = '',
  form = undefined,
  formStatusKeys = ['base'],
  excludeErrorArray = [],
  withStatus = false,
}: HandleErrorProps) => {
  const requestMethod = pathOr(null, ['response', 'config', 'method'], error);
  const requestStatus = pathOr(404, ['response', 'status'], error);

  const handleGetRequest = cond<any, any>([
    [equals(StatusCodes.UNAUTHORIZED), makeClearAccount(dispatch)],
    [equals(StatusCodes.FORBIDDEN), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(StatusCodes.NOT_FOUND), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(StatusCodes.INTERNAL_SERVER_ERROR), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(StatusCodes.UNPROCESSABLE_ENTITY), always(undefined)],
    [T, showErrorNotifications({ error, dispatch })],
  ]);

  const handleOtherRequests = cond([
    [equals(StatusCodes.UNAUTHORIZED), makeClearAccount(dispatch)],
    [equals(StatusCodes.FORBIDDEN), makeSetErrorToStore(dispatch, requestStatus)],
    [equals(StatusCodes.UNPROCESSABLE_ENTITY), makeHandleForm(form, error, formStatusKeys)],
    [T, showErrorNotifications({ error, dispatch })],
  ]);

  if (endpoint) {
    dispatch(dataApiFailure(withStatus ? { endpoint, status: requestStatus } : { endpoint }));
  }
  if (!skipHandlers && !includes(requestStatus, excludeErrorArray)) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    requestMethod === 'get' ? handleGetRequest(requestStatus) : handleOtherRequests(requestStatus);
  }
};

export default requestErrorHandler;
