import { without, concat } from 'ramda';
import { combineReducers } from 'redux';

import { WS_STATUSES } from 'constants/webSockets';

import * as constants from './constants';
import type { SocketActions } from './types';

const status = (state = WS_STATUSES.closed, action: SocketActions) => {
  switch (action.type) {
    case constants.SET_CONNECTION_STATUS:
      return action.status;
    default:
      return state;
  }
};

const channels = (state = [], action: SocketActions) => {
  switch (action.type) {
    case constants.ADD_SUBSCRIPTION_CHANNELS:
      return concat(state, action.channels);
    case constants.REMOVE_SUBSCRIPTION_CHANNELS:
      return without(action.channels, state);
    case constants.RESET_SUBSCRIPTION_CHANNELS:
      return [];
    default:
      return state;
  }
};
const webSocketsReducer = combineReducers({
  status,
  channels,
});

export default webSocketsReducer;
