import normalize from 'json-api-normalizer';
import { path } from 'ramda';

import { UserAccountType } from 'constants/app';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/nextApiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import type { JsonApiErrorObject } from 'types/json-api/raw';
import hasIncorrectEmailOrPasswordError from 'utils/hasIncorrectEmailOrPasswordError';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { userSignIn } from '../actions';
import { USER_SIGN_IN } from '../constants';
import { userLoginEndpoint } from '../endpoints';

const userSignInOperation = createLogic<typeof userSignIn>({
  type: USER_SIGN_IN,
  latest: true,

  async process({ action }, dispatch, done) {
    const { values, form } = action;

    const { endpoint, url } = userLoginEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const body = {
        email: values.email,
        password: values.password,
      };

      const { data } = await httpClient.post(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));

      let redirectRoute = ROUTES.DASHBOARD.PATIENT.PERSONAL_INFORMATION.PATH;

      const userAccountType = path(['data', 'type'], data);
      if (userAccountType === UserAccountType.provider) {
        redirectRoute = ROUTES.DASHBOARD.PROVIDER.PERSONAL_INFORMATION.PATH;
      }
      redirect(redirectRoute, { reload: true });
    } catch (error) {
      const errors = path<JsonApiErrorObject[]>(['response', 'data', 'errors'], error) || [];

      const isInvalidEmailOrPassword = hasIncorrectEmailOrPasswordError(errors);

      if (isInvalidEmailOrPassword) {
        form.setStatus(isInvalidEmailOrPassword);
      }

      if (!isInvalidEmailOrPassword) {
        showErrorNotifications({ error, dispatch })();
      }

      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });
    }

    done();
  },
});

export default userSignInOperation;
