import { StatusCodes } from 'http-status-codes';

import httpClient from 'lib/clients/apiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import type { JsonApiError } from 'types/json-api/raw';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { checkPasswordRecoveryTokenCorrectness } from '../actions';
import { setPasswordRecoveryStatus } from '../actions';
import { CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS } from '../constants';
import { checkPasswordRecoveryTokenCorrectnessEndpoint } from '../endpoints';

const checkPasswordRecoveryTokenCorrectnessOperation = createLogic<typeof checkPasswordRecoveryTokenCorrectness>({
  type: CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = checkPasswordRecoveryTokenCorrectnessEndpoint;

    const params = {
      token: action.token,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.get(url, { params });

      dispatch(dataApiSuccess({ endpoint }));

      dispatch(setPasswordRecoveryStatus(StatusCodes.OK));
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(setPasswordRecoveryStatus(status as StatusCodes));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default checkPasswordRecoveryTokenCorrectnessOperation;
