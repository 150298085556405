import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import type { JsonApiError } from 'types/json-api/raw';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { fetchAvailableTimezones } from '../actions';
import { setAvailableTimezonesIds } from '../actions';
import { FETCH_AVAILABLE_TIMEZONES } from '../constants';
import { fetchAvailableTimezonesEndpoint } from '../endpoints';

const fetchAvailableTimezonesOperation = createLogic<typeof fetchAvailableTimezones>({
  type: FETCH_AVAILABLE_TIMEZONES,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchAvailableTimezonesEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      // @ts-ignore
      dispatch(setAvailableTimezonesIds(pluck('id', data.data)));
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(dataApiFailure({ endpoint, status }));

      showErrorNotifications({ error, dispatch });
    }

    done();
  },
});

export default fetchAvailableTimezonesOperation;
