import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import * as constants from './constants';

const settingsInitialState = {
  audioDevice: null,
  videoDevice: null,
  isVideoEnabled: true,
  isAudioEnabled: true,
};

export const localSettings = (state = settingsInitialState, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_LOCAL_SETTINGS:
      return {
        ...state,
        ...action.settings,
      };
    case constants.RESET_LOCAL_SETTINGS:
      return settingsInitialState;
    default:
      return state;
  }
};

export const callId = (state = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_CALL_ID:
      return action.id;
    default:
      return state;
  }
};

export const chatVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_CHAT_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

export const questionnaireVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_QUESTIONNAIRE_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};
export const assessmentVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_ASSESSMENT_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

const videoConferenceReducer = combineReducers({
  localSettings,
  callId,
  chatVisible,
  questionnaireVisible,
  assessmentVisible,
});

export default videoConferenceReducer;
