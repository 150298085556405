import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { CancellationReasonOption } from 'types/entities/cancellationReasonOption';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { setCancellationReasonOptionIds, type fetchCancellationReasonOptions } from '../actions';
import { FETCH_CANCELLATION_REASON_OPTIONS } from '../constants';
import { fetchCancellationReasonOptionsEndpoint } from '../endpoints';

const fetchCancellationReasonOptionsOperation = createLogic<typeof fetchCancellationReasonOptions>({
  type: FETCH_CANCELLATION_REASON_OPTIONS,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchCancellationReasonOptionsEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));
      const params = {};

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setCancellationReasonOptionIds(data.data.map(({ id }: CancellationReasonOption) => id)));
    } catch (error) {
      dispatch(setCancellationReasonOptionIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchCancellationReasonOptionsOperation;
