import httpClient from 'lib/clients/proxyClient';
import { providerProfileSelector } from 'state/concepts/session/selectors';
import { dataApiSuccess, dataApiRequest, dataUpdateItems } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { ProviderProfile } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { deactivateProviderAccount } from '../actions';
import { DEACTIVATE_PROVIDER_ACCOUNT } from '../constants';
import { activateProviderAccountEndpoint, deactivateProviderAccountEndpoint } from '../endpoints';

const deactivateProviderAccountOperation = createLogic<typeof deactivateProviderAccount>({
  type: DEACTIVATE_PROVIDER_ACCOUNT,
  latest: true,

  async process({ getState }, dispatch, done) {
    let endpoint = '';
    let url = '';

    try {
      const { id, active: isInitialActive } = providerProfileSelector(getState()) as ProviderProfile;

      const accountOperationEndpoint = isInitialActive
        ? deactivateProviderAccountEndpoint
        : activateProviderAccountEndpoint;
      endpoint = accountOperationEndpoint.endpoint;
      url = accountOperationEndpoint.url;

      dispatch(dataApiRequest({ endpoint }));

      await httpClient.put(url);

      dispatch(dataApiSuccess({ endpoint }));

      dispatch(
        showMessage({
          messageText: isInitialActive
            ? { id: 'account.accountHasBeenDeactivated' }
            : { id: 'account.accountHasBeenActivated' },
        }),
      );

      const kind = 'userProfile';
      const ids = [id];
      const data = {
        attributes: {
          active: !isInitialActive,
        },
      };

      dispatch(dataUpdateItems({ kind, ids, data }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    } finally {
      dispatch(hideModal());
    }

    done();
  },
});

export default deactivateProviderAccountOperation;
