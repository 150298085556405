import { CONCEPT_NAME } from 'state/data/constants';
import { createActionType } from 'utils/state';

const namespace = 'app';

export const SEND_CONTACT_US = createActionType(namespace, 'SEND_CONTACT_US');
export const SET_NETWORK_CONNECTION_STATUS = createActionType(namespace, 'SET_NETWORK_CONNECTION_STATUS');
export const SET_SERVER_CONNECTION_STATUS = createActionType(namespace, 'SET_SERVER_CONNECTION_STATUS');
export const FETCH_PROVIDER_SPECIALTIES = createActionType(namespace, 'FETCH_PROVIDER_SPECIALTIES');
export const SET_PROVIDER_SPECIALTIES_IDS = createActionType(namespace, 'SET_PROVIDER_SPECIALTIES_IDS');
export const FETCH_AVAILABLE_TIMEZONES = createActionType(namespace, 'FETCH_AVAILABLE_TIMEZONES');
export const SET_AVAILABLE_TIMEZONES_IDS = createActionType(namespace, 'SET_AVAILABLE_TIMEZONES_IDS');
export const FETCH_BOARD_LICENSES = createActionType(namespace, 'FETCH_BOARD_LICENSES');
export const SET_BOARD_LICENCE_IDS = createActionType(namespace, 'SET_BOARD_LICENCE_IDS');
export const FETCH_AVAILABLE_MEDICAL_TITLES = createActionType(namespace, 'FETCH_AVAILABLE_MEDICAL_TITLES');
export const SET_AVAILABLE_MEDICAL_TITLES_IDS = createActionType(namespace, 'SET_AVAILABLE_MEDICAL_TITLES_IDS');
export const FETCH_AVAILABLE_STATES = createActionType(namespace, 'FETCH_AVAILABLE_STATES');
export const SET_AVAILABLE_STATES_IDS = createActionType(namespace, 'SET_AVAILABLE_STATES_IDS');
export const SET_CURRENT_PAGE = createActionType(namespace, 'SET_CURRENT_PAGE');
export const SET_CURRENT_AND_PREVIOUS_PAGES = createActionType(namespace, 'SET_CURRENT_AND_PREVIOUS_PAGES');
export const FETCH_SYMPTOMS = createActionType(namespace, 'FETCH_SYMPTOMS');
export const SET_SYMPTOMS_IDS = createActionType(namespace, 'SET_SYMPTOMS_IDS');
export const DOWNLOAD_FILE = createActionType(namespace, 'DOWNLOAD_FILE');
export const SET_SOCIAL_LINK_LIST = createActionType(CONCEPT_NAME, 'SET_SOCIAL_LINK_LIST');
export const FETCH_SOCIAL_LINK_LIST = createActionType(CONCEPT_NAME, 'FETCH_SOCIAL_LINK_LIST');
