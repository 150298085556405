export const WS_STATUSES = {
  opened: 'opened',
  closed: 'closed',
};

export const MESSAGE_TYPES = {
  welcome: 'welcome',
  ping: 'ping',
  confirmSubscription: 'confirm_subscription',
};

export const COMMANDS = {
  subscribe: 'subscribe',
  unsubscribe: 'unsubscribe',
};

export const WS_CHANNELS = {
  ACCOUNT: 'AccountChannel',
  CHAT: 'ChatChannel',
  MESSAGE: 'MessageChannel',
  NOTIFICATION: 'NotificationChannel',
} as const;

export const WS_SUBSCRIPTIONS = {
  getChatChannel: (id: string) =>
    JSON.stringify({
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: WS_CHANNELS.CHAT,
        id,
      }),
    }),
  getNotificationChannel: () =>
    JSON.stringify({
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: WS_CHANNELS.NOTIFICATION,
      }),
    }),
};
