import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Appointment } from 'types/entities/appointment';
import type { MiddlewareErrorType } from 'types/errors';
import isPresent from 'utils/isPresent';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderAppointments, fetchProviderInitialAppointments } from '../actions';
import { setAppointmentIds } from '../actions';
import { FETCH_PROVIDER_APPOINTMENTS, FETCH_PROVIDER_INITIAL_APPOINTMENTS } from '../constants';
import { fetchProviderAppointmentsEndpoint } from '../endpoints';

const fetchProviderAppointmentsOperation = createLogic<
  typeof fetchProviderAppointments | typeof fetchProviderInitialAppointments
>({
  type: [FETCH_PROVIDER_APPOINTMENTS, FETCH_PROVIDER_INITIAL_APPOINTMENTS],
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchProviderAppointmentsEndpoint;

    try {
      const params: any = {
        include: 'user_profile,patient_profile,symptom,appointment_cancellation_reason',
        filter: {},
      };

      if (action.type === FETCH_PROVIDER_INITIAL_APPOINTMENTS) {
        const { status, startTime, endTime, timezone } = action;

        params.filter = {};
        if (isPresent(status)) {
          params.filter.status = status;
        }

        if (startTime instanceof Date) {
          params.filter.start_time = startTime.toString();
        }

        if (endTime instanceof Date) {
          params.filter.end_time = endTime.toString();
        }
        if (typeof timezone === 'string') {
          params.filter.timezone = timezone;
        }
      }

      if (action.type === FETCH_PROVIDER_APPOINTMENTS) {
        const { status } = action.values.confirmed;
        const { startTime, endTime, timezone } = action.values;

        params.filter = {};
        if (isPresent(status)) {
          params.filter.status = status;
        }

        if (startTime instanceof Date) {
          params.filter.start_time = startTime.toString();
        }

        if (endTime instanceof Date) {
          params.filter.end_time = endTime.toString();
        }
        if (typeof timezone === 'string') {
          params.filter.timezone = timezone;
        }
      }

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setAppointmentIds(data.data.map(({ id }: Appointment) => id)));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderAppointmentsOperation;
