import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { setProviderProfileId } from 'state/concepts/session/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderOnboarding } from '../actions';
import { FETCH_PROVIDER_ONBOARDING } from '../constants';
import { fetchProviderOnboardingEndpoint } from '../endpoints';

// TODO: add test after API bug fixes
/* istanbul ignore next */
const fetchProviderOnboardingOperation = createLogic<typeof fetchProviderOnboarding>({
  type: FETCH_PROVIDER_ONBOARDING,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchProviderOnboardingEndpoint;

    const params = {
      include: action.include,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setProviderProfileId(data.data.id));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
        skipHandlers: action.skipHandlers,
      });
    }

    done();
  },
});

export default fetchProviderOnboardingOperation;
