import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import type { JsonApiError } from 'types/json-api/raw';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderSpecialties } from '../actions';
import { setProviderSpecialtiesIds } from '../actions';
import { FETCH_PROVIDER_SPECIALTIES } from '../constants';
import { fetchProviderSpecialtiesEndpoint } from '../endpoints';

const fetchProviderSpecialtiesOperation = createLogic<typeof fetchProviderSpecialties>({
  type: FETCH_PROVIDER_SPECIALTIES,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchProviderSpecialtiesEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      // @ts-ignore
      dispatch(setProviderSpecialtiesIds(pluck('id', data.data)));
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(dataApiFailure({ endpoint, status }));

      showErrorNotifications({ error, dispatch });
    }

    done();
  },
});

export default fetchProviderSpecialtiesOperation;
