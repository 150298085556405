import {
  patientAppointmentDetailsRoute,
  patientAppointmentGeneralInfoRoute,
  patientAppointmentPaymentRoute,
  patientAppointmentQuestionnairesRoute,
  patientAppointmentsRoute,
  patientBookingAppointmentRoute,
  patientProviderProfileRoute,
  patientProviderProfileTimeSlotsRoute,
  patientSearchAutocompleteRoute,
  patientSearchRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchProvidersSearchListEndpoint = endpoint(
  constants.FETCH_PROVIDERS_SEARCH_LIST,
  'GET',
  patientSearchRoute,
);

export const fetchProvidersSearchOptionsEndpoint = endpoint(
  constants.FETCH_PROVIDERS_SEARCH_OPTIONS,
  'GET',
  patientSearchAutocompleteRoute,
);

export const fetchProviderAvailableTimeSlotsByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PROVIDER_AVAILABLE_TIME_SLOTS_BY_ID, 'GET', patientProviderProfileTimeSlotsRoute(id));

export const fetchPatientProviderProfileByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PATIENT_PROVIDER_PROFILE_BY_ID, 'GET', patientProviderProfileRoute(id));

export const createNewAppointmentEndpoint = endpoint(constants.CREATE_NEW_APPOINTMENT, 'GET', patientAppointmentsRoute);
export const fetchPatientBookAppointmentByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PATIENT_BOOK_APPOINTMENT_BY_ID, 'GET', patientBookingAppointmentRoute(id));
export const submitAppointmentGeneralInfoEndpoint = (id: string) =>
  endpoint(constants.SUBMIT_GENERAL_INFO, 'PUT', patientAppointmentGeneralInfoRoute(id));
export const submitAppointmentQuestionnaireEndpoint = (id: string) =>
  endpoint(constants.SUBMIT_QUESTIONNAIRE, 'PUT', patientAppointmentQuestionnairesRoute(id));
export const payAppointmentEndpoint = (id: string) =>
  endpoint(constants.PAY_APPOINTMENT, 'PUT', patientAppointmentPaymentRoute(id));
export const deletePatientAppointmentEndpoint = (id: string) =>
  endpoint(constants.PATIENT_DELETE_APPOINTMENT, 'DELETE', patientAppointmentDetailsRoute(id));
