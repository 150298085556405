import { combineReducers } from 'redux';

import type { DoctorsListSearchOption } from 'types/entities/doctorsSearch';

import * as constants from './constants';
import type { BookAppointmentActions, BookAppointmentState } from './types';

export const providersSearchListIds = (state: string[] | null = [], action: BookAppointmentActions) => {
  switch (action.type) {
    case constants.SET_PROVIDERS_SEARCH_LIST_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const providersSearchListTotal = (state: number | null = null, action: BookAppointmentActions) => {
  switch (action.type) {
    case constants.SET_PROVIDERS_SEARCH_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

export const providersSearchOptionsIds = (state: string[] | null = null, action: BookAppointmentActions) => {
  switch (action.type) {
    case constants.SET_PROVIDERS_SEARCH_OPTIONS_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const providersSearchActiveOption = (
  state: DoctorsListSearchOption | null = null,
  action: BookAppointmentActions,
) => {
  switch (action.type) {
    case constants.SET_PROVIDERS_SEARCH_ACTIVE_OPTION:
      return action.option;
    default:
      return state;
  }
};

export const providerAvailableTimeSlotsIds = (state: string[] | null = null, action: BookAppointmentActions) => {
  switch (action.type) {
    case constants.SET_PROVIDER_AVAILABLE_TIME_SLOTS:
      return action.ids;
    default:
      return state;
  }
};

export const providerAllowedStateMeta = (state: boolean | true = true, action: BookAppointmentActions) => {
  switch (action.type) {
    case constants.SET_PROVIDER_ALLOWED_STATE_META:
      return action.allowedState;
    default:
      return state;
  }
};

const bookAppointmentReducer = combineReducers<BookAppointmentState, BookAppointmentActions>({
  providersSearchListIds,
  providersSearchListTotal,
  providersSearchOptionsIds,
  providersSearchActiveOption,
  providerAvailableTimeSlotsIds,
  providerAllowedStateMeta,
});

export default bookAppointmentReducer;
