import Axios from 'axios';

import { API_HOST } from 'constants/app';

import paramsSerializer from './paramsSerializer';

const apiClient = Axios.create({
  baseURL: `${API_HOST}/api/v1`,
  paramsSerializer,
});

export default apiClient;
