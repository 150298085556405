import type { AxiosError } from 'axios';
import { pathOr, isNil, isEmpty } from 'ramda';
import type { AnyAction } from 'redux';

import { showMessage } from 'state/flash-messages/actions';
import { MessageType } from 'state/flash-messages/messagesTypes';

interface CurrentError extends Error, AxiosError {
  detail?: string;
}

type ShowErrorNotificationsArgs = {
  error: CurrentError | unknown;
  dispatch: (fn: AnyAction) => void;
  displayStatus?: boolean;
};

const showErrorNotifications =
  ({ error, dispatch, displayStatus = false }: ShowErrorNotificationsArgs) =>
  () => {
    if (typeof window !== 'undefined') {
      const errors = pathOr<CurrentError[]>([], ['response', 'data', 'errors'], error);
      const status = pathOr(null, ['response', 'status'], error);
      const statusText = pathOr({ id: 'shared.error.generalNotification' }, ['response', 'statusText'], error);

      if (!status) {
        return;
      }

      const apiErrors =
        isNil(errors) || isEmpty(errors)
          ? [statusText]
          : errors.map((e: CurrentError) => e.detail).filter((value, index, self) => self.indexOf(value) === index); // Unique

      apiErrors.forEach((currentError) => {
        const messageText = displayStatus ? `${status} - ${currentError}` : currentError;

        dispatch(
          showMessage({
            messageText: messageText as string,
            messageType: MessageType.ERROR,
          }),
        );
      });
    }
  };

export default showErrorNotifications;
