import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'onboarding';

// Patient
export const UPDATE_PATIENT_PERSONAL_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PATIENT_PERSONAL_INFORMATION',
);
export const UPDATE_PATIENT_CONTACT_INFORMATION = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_CONTACT_INFORMATION');
export const UPDATE_PATIENT_HEALTH_PROFILE = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_HEALTH_PROFILE');
export const FETCH_PATIENT_ONBOARDING = createActionType(CONCEPT_NAME, 'FETCH_PATIENT_ONBOARDING');

// Provider
export const UPDATE_PROVIDER_PERSONAL_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_PERSONAL_INFORMATION',
);
export const UPDATE_PROVIDER_CONTACT_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_CONTACT_INFORMATION',
);
export const UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS',
);
export const UPDATE_PROVIDER_MEDICAL_LICENSE = createActionType(CONCEPT_NAME, 'UPDATE_PROVIDER_MEDICAL_LICENSE');

export const UPDATE_PROVIDER_CANCELLATION_SCENARIO = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_CANCELLATION_SCENARIO',
);
export const FETCH_PROVIDER_ONBOARDING = createActionType(CONCEPT_NAME, 'FETCH_PROVIDER_ONBOARDING');

export const UPDATE_PROVIDER_SIGNATURE = createActionType(CONCEPT_NAME, 'UPDATE_PROVIDER_SIGNATURE');
