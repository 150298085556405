import httpClient from 'lib/clients/nextApiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePassword } from '../actions';
import { UPDATE_PASSWORD } from '../constants';
import { updatePasswordEndpoint } from '../endpoints';

const updatePasswordOperation = createLogic<typeof updatePassword>({
  type: UPDATE_PASSWORD,
  latest: true,

  async process({ action }, dispatch, done) {
    const { values, form } = action;
    const { endpoint, url } = updatePasswordEndpoint(action.values.userRole);

    const body = {
      old_password: values.password,
      password: values.passwordChanged,
    };

    try {
      form.setStatus(undefined);
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));

      dispatch(showMessage({ messageText: { id: 'account.passwordUpdatedSuccessfully' } }));
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form,
      });
    } finally {
      // for change status in modal form
      form.setSubmitting(false);
    }

    done();
  },
});

export default updatePasswordOperation;
