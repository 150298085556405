import normalize from 'json-api-normalizer';
import { equals, propOr } from 'ramda';
import build from 'redux-object';

import { ONBOARDING_STEPS_PROVIDER, ProviderOnboardingSteps } from 'constants/onboarding';
import httpClient from 'lib/clients/proxyClient';
import { setProviderProfileId } from 'state/concepts/session/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchInitialProviderProfile, fetchProviderProfile } from '../actions';
import { FETCH_PROVIDER_PROFILE, INITIAL_FETCH_PROVIDER_PROFILE } from '../constants';
import { fetchProviderProfileEndpoint } from '../endpoints';

const fetchProviderProfileOperation = createLogic<typeof fetchProviderProfile | typeof fetchInitialProviderProfile>({
  type: [FETCH_PROVIDER_PROFILE, INITIAL_FETCH_PROVIDER_PROFILE],
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchProviderProfileEndpoint(action.type as any);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, {
        params: { ...((action as any)?.include && { include: (action as any).include }) },
      });

      const response = normalize(data);

      dispatch(setProviderProfileId(data.data.id));
      dispatch(dataApiSuccess({ endpoint, response }));

      const currentProfile: any = build(response, 'userProfile', data.data.id);
      const redirectTo: any = propOr(undefined, currentProfile.onboardingStatus, ONBOARDING_STEPS_PROVIDER);
      if (redirectTo && !equals(ProviderOnboardingSteps.completed, currentProfile.onboardingStatus)) {
        redirect(redirectTo as string);
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderProfileOperation;
