import fileDownload from 'js-file-download';

import { ASSESSMENT_CATEGORIES } from 'constants/assessments';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { downloadPatientAssessmentById } from '../actions';
import * as constants from '../constants';
import { downloadPatientAssessmentByIdEndpoint } from '../endpoints';

const downloadPatientAssessmentByIdOperation = createLogic<typeof downloadPatientAssessmentById>({
  type: constants.DOWNLOAD_PATIENT_ASSESSMENT_BY_ID,
  latest: true,

  async process({ action: { id, assessmentOrder } }, dispatch, done) {
    const { endpoint, url } = downloadPatientAssessmentByIdEndpoint(id);

    try {
      const body = {
        categories: assessmentOrder ? ASSESSMENT_CATEGORIES?.[assessmentOrder] : ASSESSMENT_CATEGORIES.default,
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.post(url, body, {
        responseType: 'blob',
      });

      dispatch(dataApiSuccess({ endpoint }));
      await fileDownload(data, `assessment.pdf`);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default downloadPatientAssessmentByIdOperation;
