import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import { head, last, path } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { PROVIDERS_SEARCH_INPUT_OPTIONS_ORDER } from 'constants/bookAppointment';
import type { RootState } from 'state/store/types';
import type { DoctorsListSearchOption } from 'types/entities/doctorsSearch';
import type { ProviderProfile } from 'types/entities/session';
import type { NormalizedResponseData } from 'types/json-api/normalized';

const dataSelector = (state: RootState) => state.data;

export const providersSearchListIdsSelector = path(['bookAppointment', 'providersSearchListIds']);

export const providersSearchListTotalSelector = path(['bookAppointment', 'providersSearchListTotal']);

export const providersSearchActiveOptionSelector = path(['bookAppointment', 'providersSearchActiveOption']);

export const providersSearchListSelector = createSelector(
  providersSearchListIdsSelector,
  dataSelector,
  (id, data) => build(data as NormalizedResponseData, 'userProfile', id as string[]) as unknown as ProviderProfile[],
);

export const providersSearchOptionsIdsSelector = path(['bookAppointment', 'providersSearchOptionsIds']);
export const providerAvailableTimeSlotsIdsSelector = path(['bookAppointment', 'providerAvailableTimeSlotsIds']);
export const providerAllowedStateMetaSelector = path(['bookAppointment', 'providerAllowedStateMeta']);

export const providersSearchGroupedOptionsSelector = createSelector(
  providersSearchOptionsIdsSelector,
  dataSelector,
  (id, data) => {
    const groupedOptions: Record<string, DoctorsListSearchOption[]> = {};

    const options = build(data as NormalizedResponseData, 'autocompleteResult', id as string[]);

    if (Array.isArray(options)) {
      // Prefill OptionGroup keys
      PROVIDERS_SEARCH_INPUT_OPTIONS_ORDER.forEach((key) => {
        groupedOptions[key] = [];
      });

      // Fill with Options
      options.forEach((item) => {
        groupedOptions[item.sectionType].push({
          id: item.id,
          name: item.name,
          value: item.id,
          sectionType: item.sectionType,
          sectionTypeId: item.sectionTypeId,
          avatar: item.avatarUrls?.small,
        });
      });
    }

    return groupedOptions;
  },
);

export const patientProviderByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build<ProviderProfile>(data, 'userProfile', id),
);

export const patientProviderTimeSlotsForCurrentDatesSelector = createSelector(
  (_: RootState, currentDates: Date[]) => currentDates,
  providerAvailableTimeSlotsIdsSelector,
  dataSelector,
  (currentDates, ids, data) => {
    const availableTimeSlots = build(data, 'timeSlot', ids as string[]);
    const weekdays = eachDayOfInterval({
      start: head(currentDates) as Date,
      end: last(currentDates) as Date,
    });

    const updatedWeekDays = weekdays?.map((day) => ({
      weekday: format(day, 'EEEE'),
      date: format(day, 'd MMMM'),
      value:
        availableTimeSlots?.filter(({ startTimeInPatientTz }) =>
          isSameDay(new Date(startTimeInPatientTz), new Date(day)),
        ) || [],
    }));

    return updatedWeekDays;
  },
);
