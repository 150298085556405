import { createActionType } from 'utils/state';

const CONCEPT_NAME = 'webSockets';

export const OPEN_CONNECTION = createActionType(CONCEPT_NAME, 'OPEN_CONNECTION');
export const CLOSE_CONNECTION = createActionType(CONCEPT_NAME, 'CLOSE_CONNECTION');
export const SET_CONNECTION_STATUS = createActionType(CONCEPT_NAME, 'SET_CONNECTION_STATUS');
export const ADD_SUBSCRIPTION_CHANNELS = createActionType(CONCEPT_NAME, 'ADD_SUBSCRIPTION_CHANNELS');
export const REMOVE_SUBSCRIPTION_CHANNELS = createActionType(CONCEPT_NAME, 'REMOVE_SUBSCRIPTION_CHANNELS');
export const RESET_SUBSCRIPTION_CHANNELS = createActionType(CONCEPT_NAME, 'RESET_SUBSCRIPTION_CHANNELS');
export const SEND_COMMAND = createActionType(CONCEPT_NAME, 'SEND_COMMAND');
export const RECEIVE_DATA = createActionType(CONCEPT_NAME, 'RECEIVE_DATA');
