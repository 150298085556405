import { combineReducers } from 'redux';

import * as constants from './constants';
import type { CancellationReasonOptionActions } from './types';

export const cancellationReasonOptionIds = (state = [], action: CancellationReasonOptionActions) => {
  switch (action.type) {
    case constants.SET_CANCELLATION_REASON_OPTION_IDS:
      return action.ids;
    default:
      return state;
  }
};

const cancellationReasonOptionReducer = combineReducers({
  cancellationReasonOptionIds,
});

export default cancellationReasonOptionReducer;
