export const ASSESSMENT_ORDERS = {
  general: 'general',
  prescriptionMedication: 'prescriptionMedication',
  imaging: 'imaging',
  bloodWork: 'bloodWork',
  biopsy: 'biopsy',
  therapy: 'therapy',
  medicalEquipment: 'medicalEquipment',
  sickNote: 'sickNote',
};

export const ASSESSMENT_MENU_ORDERS = [
  'prescriptionMedication',
  'imaging',
  'bloodWork',
  'biopsy',
  'therapy',
  'medicalEquipment',
  'sickNote',
];

export const ASSESSMENT_PAGE_LENGTH = 15;

export const ASSESSMENT_ORDERS_ITEMS = [
  'drugName',
  'doseStrength',
  'dosageForm',
  'quantityPrescribed',
  'directionsForUse',
  'numberOfRefills',
];
export const ASSESSMENT_IMAGING_ITEMS = ['imagingModality', 'directionsForPatientPreparations'];
export const ASSESSMENT_BLOOD_WORK_ITEMS = ['bloodWorkBeingOrdered', 'directionsForPatientPreparations'];
export const ASSESSMENT_BIOPSY_ITEMS = ['describeLesionBeingBiopsied', 'directionsForPatientPreparations'];
export const ASSESSMENT_THERAPY_ITEMS = ['lengthInTimesPerWeekPerMonth', 'directionsForPatientPreparations'];
export const ASSESSMENT_MEDICAL_EQUIPMENT_ITEMS = ['freeTextEquipmentNeeded', 'directionsForPatientUse'];

export enum AssessmentsType {
  prescription = 'prescription',
  imaging = 'imaging',
  bloodWork = 'blood_work',
  biopsy = 'biopsy',
  therapy = 'therapy',
  medicalEquipment = 'medical_equipment',
  sickNote = 'sick_note',
}

export const CATEGORIES_MULTISELECT_OPTIONS = [
  { value: AssessmentsType.prescription, name: { id: 'appointment.assessment.prescription' } },
  { value: AssessmentsType.imaging, name: { id: 'appointment.assessment.imaging' } },
  { value: AssessmentsType.bloodWork, name: { id: 'appointment.assessment.bloodWork' } },
  { value: AssessmentsType.biopsy, name: { id: 'appointment.assessment.biopsy' } },
  { value: AssessmentsType.therapy, name: { id: 'appointment.assessment.therapy' } },
  { value: AssessmentsType.medicalEquipment, name: { id: 'appointment.assessment.medicalEquipment' } },
  { value: AssessmentsType.sickNote, name: { id: 'appointment.assessment.sickNote' } },
];
export const THERAPY_TYPES = [
  { value: 'physical', label: { id: 'appointment.assessment.therapy.physical' } },
  { value: 'occupational', label: { id: 'appointment.assessment.therapy.occupational' } },
];

export enum AssessmentsTabs {
  general = 'general',
  orders = 'orders',
}

enum AssessmentCategories {
  general = '',
  prescription = 'prescription',
  imaging = 'imaging',
  bloodWork = 'blood_work',
  biopsy = 'biopsy',
  therapy = 'therapy',
  medicalEquipment = 'medical_equipment',
  sickNote = 'sick_note',
  default = 'biopsy,blood_work,imaging,prescription,medical_equipment,sick_note,therapy',
}

export enum AssessmentStatuses {
  draft = 'draft',
  completed = 'completed',
}

export const ASSESSMENT_CATEGORIES = {
  [ASSESSMENT_ORDERS.general]: AssessmentCategories.general,
  [ASSESSMENT_ORDERS.prescriptionMedication]: AssessmentCategories.prescription,
  [ASSESSMENT_ORDERS.imaging]: AssessmentCategories.imaging,
  [ASSESSMENT_ORDERS.bloodWork]: AssessmentCategories.bloodWork,
  [ASSESSMENT_ORDERS.biopsy]: AssessmentCategories.biopsy,
  [ASSESSMENT_ORDERS.therapy]: AssessmentCategories.therapy,
  [ASSESSMENT_ORDERS.medicalEquipment]: AssessmentCategories.medicalEquipment,
  [ASSESSMENT_ORDERS.sickNote]: AssessmentCategories.sickNote,
  default: AssessmentCategories.default,
};
