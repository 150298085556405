import useModalRoot from './hook';
import { MODAL_COMPONENTS } from './modalComponents';

export default function ModalRoot() {
  const { modalType, modalProps } = useModalRoot();

  if (!modalType) {
    return null;
  }

  const ModalComponent = (MODAL_COMPONENTS as any)[modalType];

  return <ModalComponent {...(modalProps as any)} />;
}
