import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import type { AnyAction } from 'redux';

function useDispatchAction<SAs extends unknown[], DAs extends unknown[]>(
  actionCreator: (...args: [...SAs, ...DAs]) => AnyAction,
  ...staticArgs: SAs
) {
  const dispatch = useDispatch();
  return useCallback((...dynamicArgs: DAs) => {
    dispatch(actionCreator(...staticArgs, ...dynamicArgs));
    // eslint-disable-next-line react-hooks/exhaustive-deps -- deps propagated from arguments
  }, staticArgs);
}

export default useDispatchAction;
