import format from 'date-fns/format';
import normalize from 'json-api-normalizer';

import { PATIENT_PROFILE_INCLUSIONS } from 'constants/profile';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import isPresent from 'utils/isPresent';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePatientPersonalInformation } from '../actions';
import { UPDATE_PATIENT_PERSONAL_INFORMATION } from '../constants';
import { updatePatientPersonalInformationEndpoint } from '../endpoints';

const updatePatientPersonalInformationOperation = createLogic<typeof updatePatientPersonalInformation>({
  type: UPDATE_PATIENT_PERSONAL_INFORMATION,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.DASHBOARD.PATIENT.PERSONAL_INFORMATION.PATH;

    const { endpoint, url } = updatePatientPersonalInformationEndpoint;

    const formData = new FormData();
    formData.append('first_name', action.values.firstName || '');
    formData.append('last_name', action.values.lastName || '');
    formData.append('date_of_birth', format(action.values.birthDate as Date, 'yyyy-MM-dd'));
    formData.append('timezone', action.values.timeZone || '');
    formData.append('gender_identity', action.values.genderIdentity || '');
    formData.append('sex', action.values.sex || '');
    formData.append('marital_status', action.values.maritalStatus || '');
    formData.append('race', action.values.race || '');
    formData.append('ethnicity', action.values.ethnicity || '');
    formData.append('about_me', action.values.about || '');
    formData.append('include', PATIENT_PROFILE_INCLUSIONS.CONTACT_INFORMATION);

    if (Array.isArray(action.values.languages)) {
      if (isPresent(action.values.languages)) {
        action.values.languages.forEach((lang) => {
          formData.append('languages[]', lang);
        });
      } else {
        formData.append('languages', '');
      }
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);

      dispatch(showMessage({ messageText: { id: 'account.personalInformationUpdatedSuccessfully' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updatePatientPersonalInformationOperation;
