import normalize from 'json-api-normalizer';
import camelCase from 'lodash/camelCase';
import { pick, keys } from 'ramda';

import { AssessmentsType } from 'constants/assessments';
import mapKeysToSnakeCase from 'lib/clients/mapKeysToSnakeCase';
import httpClient from 'lib/clients/proxyClient';
import { setAssessmentVisible } from 'state/concepts/appointment/actions';
import { setAssessmentVisible as setAssessmentVisibleVideo } from 'state/concepts/videoConference/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import constructRemovedAssessmentOrders from 'utils/constructRemovedAssessmentOrders';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateAssessment } from '../actions';
import { UPDATE_ASSESSMENT } from '../constants';
import { updateAssessmentEndpoint } from '../endpoints';
import { assessmentByIdSelector } from '../selectors';

const updateAssessmentOperation = createLogic<typeof updateAssessment>({
  type: UPDATE_ASSESSMENT,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const state = getState();
    const { assessmentId, chosenCategories } = action.values;
    const { endpoint, url } = updateAssessmentEndpoint(assessmentId);
    const assessment = assessmentByIdSelector(state, assessmentId);
    const assessmentOrders = pick(keys(AssessmentsType), assessment);
    const chosenCategoriesToCamelCase = chosenCategories.map((order) => camelCase(order));
    const body = pick(
      ['isDraft', 'assessment', 'objective', 'subjective', 'plan', 'diagnosis', ...chosenCategoriesToCamelCase],
      action.values,
    );

    const removedAssessmentOrders: any = constructRemovedAssessmentOrders(
      keys(assessmentOrders),
      chosenCategoriesToCamelCase,
      assessment,
    );
    const snakeBody = mapKeysToSnakeCase({ ...body, ...removedAssessmentOrders });

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.patch(url, snakeBody);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setAssessmentVisible({ visible: false }));
      dispatch(setAssessmentVisibleVideo({ visible: false }));
      dispatch(showMessage({ messageText: { id: 'assessment.updated.success' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default updateAssessmentOperation;
