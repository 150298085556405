import formatISO from 'date-fns/formatISO';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';
import updateDataHelper from 'utils/updateDataHelper';

import { decUnreadNotificationsCount } from '../actions';
import type { readNotification } from '../actions';
import { READ_NOTIFICATION } from '../constants';
import { readNotificationEndpoint } from '../endpoints';
import { unreadNotificationsCountSelector } from '../selectors';

const readNotificationOperation = createLogic<typeof readNotification>({
  type: READ_NOTIFICATION,
  latest: true,

  async process({ getState, action: { id } }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = readNotificationEndpoint(id);
    const unreadCount = unreadNotificationsCountSelector(state);

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.put(url);

      const response = updateDataHelper(state.data, 'notification', [id] as string[], {
        attributes: { readAt: formatISO(new Date()) },
      });

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(decUnreadNotificationsCount(unreadCount));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default readNotificationOperation;
