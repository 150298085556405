import { createCachedSelector } from 're-reselect';

import type { RootState } from 'state/store/types';

const metaSelector = (state: RootState) => state.data.meta;

export const dataSelector = (state: RootState) => state.data;

const keyEndpointSelector = (_: unknown, endpoint: string) => endpoint;

export const endpointSelector = createCachedSelector(
  metaSelector,
  keyEndpointSelector,
  (meta, endpoint) => meta?.[endpoint],
)(keyEndpointSelector);

export const endpointErrorSelector = createCachedSelector(
  metaSelector,
  keyEndpointSelector,
  (meta, endpoint) => meta?.[endpoint]?.status,
)(keyEndpointSelector);

export const loadingSelector = createCachedSelector(
  endpointSelector,
  (endpoint) => endpoint?.loading,
)(keyEndpointSelector);
