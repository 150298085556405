import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientBookAppointmentById } from '../actions';
import * as constants from '../constants';
import { fetchPatientBookAppointmentByIdEndpoint } from '../endpoints';

const fetchPatientBookAppointmentByIdOperation = createLogic<typeof fetchPatientBookAppointmentById>({
  type: constants.FETCH_PATIENT_BOOK_APPOINTMENT_BY_ID,
  latest: true,

  async process({ action: { id } }, dispatch, done) {
    const { endpoint, url } = fetchPatientBookAppointmentByIdEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));
      const { data } = await httpClient.get(url, {
        params: {
          include:
            'user_profile,user_profile.cancellation_policy,user_profile.specialities,user_profile.specialities.symptoms,speciality,patient_profile,symptom',
        },
      });

      const response = normalize(data);
      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchPatientBookAppointmentByIdOperation;
