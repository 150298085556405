import normalize from 'json-api-normalizer';
import { omit } from 'ramda';

import { PROVIDER_ONBOARDING_INCLUSIONS } from 'constants/onboarding';
import httpClient from 'lib/clients/proxyClient';
import { providerMedicalTitlesSelector } from 'state/app/selectors';
import { EDIT_PROVIDER_MEDICAL_LICENSE } from 'state/concepts/profile/constants';
import { editProviderMedicalLicenseEndpoint } from 'state/concepts/profile/endpoints';
import { providerProfileSelector } from 'state/concepts/session/selectors';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { BoardLicense, StateLicense } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import { constructBoardLicenses, constructStateLicenses } from 'utils/constructProviderOnboardingBody';
import omitFieldsArray from 'utils/medicalTitles/omitFieldsArray';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderMedicalLicense } from '../actions';
import { UPDATE_PROVIDER_MEDICAL_LICENSE } from '../constants';
import { updateProviderMedicalLicenseEndpoint } from '../endpoints';

const updateProviderMedicalLicenseOperation = createLogic<typeof updateProviderMedicalLicense>({
  type: [UPDATE_PROVIDER_MEDICAL_LICENSE, EDIT_PROVIDER_MEDICAL_LICENSE as any],
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const neededEndpoint =
      action.type === UPDATE_PROVIDER_MEDICAL_LICENSE
        ? updateProviderMedicalLicenseEndpoint
        : editProviderMedicalLicenseEndpoint;

    const { endpoint, url } = neededEndpoint;

    const providerProfile = providerProfileSelector(getState());
    const providerMedicalTitles = providerMedicalTitlesSelector(getState());

    const inclusions = [
      PROVIDER_ONBOARDING_INCLUSIONS.BOARD_LICENSES,
      PROVIDER_ONBOARDING_INCLUSIONS.STATE_LICENSES,
      PROVIDER_ONBOARDING_INCLUSIONS.BOARD_CERTIFICATION,
    ];

    const defaultBody: Record<string, any> = {
      include: inclusions.join(','),
      user_title_id: action.values.title,
      national_provider_identifier: action.values.nationalProviderIdentifier,
      medical_degree: action.values.medicalDegree,
      state_licenses: constructStateLicenses(
        providerProfile?.stateLicenses as StateLicense[],
        action.values.stateLicenses,
      ),
      board_license_certifications: constructBoardLicenses(
        providerProfile?.boardLicenseCertifications as BoardLicense[],
        action.values.boardLicenses,
      ),
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const body = omit(omitFieldsArray(action.values.title as string, providerMedicalTitles), defaultBody);

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      if (typeof action.values.redirectRoute !== 'undefined') {
        redirect(action.values.redirectRoute);
      }

      if (action.type === (EDIT_PROVIDER_MEDICAL_LICENSE as any)) {
        dispatch(showMessage({ messageText: { id: 'account.medicalTitleLicenses' } }));
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updateProviderMedicalLicenseOperation;
