import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'calendar';

export const FETCH_PATIENT_APPOINTMENTS = createActionType(CONCEPT_NAME, 'FETCH_PATIENT_APPOINTMENTS');
export const FETCH_PROVIDER_APPOINTMENTS = createActionType(CONCEPT_NAME, 'FETCH_PROVIDER_APPOINTMENTS');
export const FETCH_PROVIDER_INITIAL_APPOINTMENTS = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_INITIAL_APPOINTMENTS',
);
export const SET_APPOINTMENT_IDS = createActionType(CONCEPT_NAME, 'SET_APPOINTMENT_IDS');
export const SET_APPOINTMENTS_LIST_TOTAL = createActionType(CONCEPT_NAME, 'SET_APPOINTMENTS_LIST_TOTAL');
export const SET_CURRENT_APPOINTMENTS_PAGE = createActionType(CONCEPT_NAME, 'SET_CURRENT_APPOINTMENTS_PAGE');
export const PATIENT_CANCEL_APPOINTMENT = createActionType(CONCEPT_NAME, 'PATIENT_CANCEL_APPOINTMENT');
export const PROVIDER_CANCEL_APPOINTMENT = createActionType(CONCEPT_NAME, 'PROVIDER_CANCEL_APPOINTMENT');
export const FETCH_PROVIDER_APPOINTMENT_BY_ID_WITH_CHAT = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_APPOINTMENT_BY_ID_WITH_CHAT',
);
export const RESCHEDULE_PATIENT_APPOINTMENT = createActionType(CONCEPT_NAME, 'RESCHEDULE_PATIENT_APPOINTMENT');
export const FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID',
);
export const FETCH_PATIENT_APPOINTMENT_BY_ID_WITH_CHAT = createActionType(
  CONCEPT_NAME,
  'FETCH_PATIENT_APPOINTMENT_BY_ID_WITH_CHAT',
);
export const SUBMIT_EDIT_QUESTIONNAIRE = createActionType(CONCEPT_NAME, 'SUBMIT_EDIT_QUESTIONNAIRE');
export const SET_ASSESSMENT_VISIBLE = createActionType(CONCEPT_NAME, 'SET_ASSESSMENT_VISIBLE');
export const SET_DRAWER_VISIBLE = createActionType(CONCEPT_NAME, 'SET_DRAWER_VISIBLE');
export const SET_QUESTIONNAIRE_VISIBLE = createActionType(CONCEPT_NAME, 'SET_QUESTIONNAIRE_VISIBLE');
