import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateChatMessage } from '../actions';
import { setEditingChatMessage } from '../actions';
import { UPDATE_CHAT_MESSAGE } from '../constants';
import { updateChatMessageEndpoint } from '../endpoints';

const updateChatMessageOperation = createLogic<typeof updateChatMessage>({
  type: UPDATE_CHAT_MESSAGE,
  latest: true,

  async process({ action: { chatId, text, messageId, form } }, dispatch, done) {
    const { endpoint, url } = updateChatMessageEndpoint(chatId, messageId);
    const params = new FormData();

    params.append('text', text);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, params);
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setEditingChatMessage(null));
      form.resetForm();
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default updateChatMessageOperation;
