interface CreateRouteFromPathNameArgs {
  pathname: string;
  pathChunkName: string;
  pathChunkReplacer: string;
}

export const createRouteFromPathname = ({
  pathname,
  pathChunkName,
  pathChunkReplacer,
}: CreateRouteFromPathNameArgs) => {
  let route = pathname;

  route = pathname.replace(`[${pathChunkName}]`, pathChunkReplacer);

  return route;
};
