import format from 'date-fns/format';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { resetToWeeklyHours } from '../actions';
import { fetchProvideCalendar } from '../actions';
import { RESET_TO_WEEKLY_HOURS } from '../constants';
import { resetToWeeklyHoursEndpoint } from '../endpoints';

const resetToWeeklyHoursOperation = createLogic<typeof resetToWeeklyHours>({
  type: RESET_TO_WEEKLY_HOURS,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = resetToWeeklyHoursEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const data = {
        date: format(action.date, 'yyyy-MM-dd'),
      };

      await httpClient.delete(url, { data });

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(fetchProvideCalendar());
      dispatch(showMessage({ messageText: { id: 'shared.changesHasBeenSaved' } }));
    } catch (error) {
      dispatch(dataApiFailure({ endpoint }));

      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default resetToWeeklyHoursOperation;
