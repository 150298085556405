export const APPOINTMENT_PAGE_LENGTH = 9;
export const patientAppointmentFilterStatuses = {
  past: 'past',
  canceled: 'canceled',
  upcoming: 'upcoming',
  inProgress: 'inProgress',
};
export enum AppointmentTabs {
  past = 'past',
  canceled = 'canceled',
  upcoming = 'upcoming',
  inProgress = 'inProgress',
}

export enum EditState {
  edit = 'edit',
  preview = 'preview',
}

export enum ProviderAppointmentsTabs {
  day = 'day',
  list = 'list',
}

export const APPOINTMENT_RESCHEDULE_MAX_COUNT = 2;

export const eventAppointmentColors = [
  'rbc-event--lightcyan',
  'rbc-event--lightcyan-light',
  'rbc-event--lavender',
  'rbc-event--lavender-light',
  'rbc-event--oldlace',
  'rbc-event--oldlace-light',
  'rbc-event--paleturquoise',
  'rbc-event--lavenderblush',
];
export const eventAppointmentColorsList = [
  'appointment__list-view__item-disc--lightgreen',
  'appointment__list-view__item-disc--orchid',
  'appointment__list-view__item-disc--khaki',
  'appointment__list-view__item-disc--cornflowerblue',
  'appointment__list-view__item-disc--greenyellow',
  'appointment__list-view__item-disc--cadetblue',
  'appointment__list-view__item-disc--violet',
  'appointment__list-view__item-disc--skyblue',
];

export enum RescheduleAppointmentState {
  idle = 'idle',
  form = 'form',
}

export const GET_PROVIDER_APPOINTMENTS_INITIAL_VALUES = (timezone: string) => ({
  unconfirmed: {
    status: [],
  },
  confirmed: {
    status: [],
  },
  timezone,
});
