import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataUpdate } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';
import updateDataHelper from 'utils/updateDataHelper';

import type { createNewAppointmentForm, createNewAppointment } from '../actions';
import { CREATE_NEW_APPOINTMENT } from '../constants';
import { createNewAppointmentEndpoint } from '../endpoints';

const createNewAppointmentOperation = createLogic<typeof createNewAppointmentForm | typeof createNewAppointment>({
  type: CREATE_NEW_APPOINTMENT,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { endpoint, url } = createNewAppointmentEndpoint;
    const { startTime, userProfileId } = action.values;

    const body: { user_profile_id: string | undefined; start_time: string | undefined } = {
      user_profile_id: userProfileId,
      start_time: startTime,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.post(url, body);

      const response = normalize(data);

      const updatedData = updateDataHelper(getState().data, 'timeSlot', [startTime] as string[], {
        attributes: {
          available: false,
        },
      });
      redirect(ROUTES.DASHBOARD.PATIENT.BOOK_APPOINTMENT.PATH(data.data.id));

      dispatch(dataUpdate({ updatedData }));
      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default createNewAppointmentOperation;
