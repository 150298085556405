import type { JsonApiErrorObject } from 'types/json-api/raw';

const hasIncorrectEmailOrPasswordError = (errors: JsonApiErrorObject[]) => {
  for (let i = 0; i < errors.length; i += 1) {
    const error = errors[i];

    if (error.source?.pointer === '/base') {
      return error.detail;
    }
  }

  return null;
};

export default hasIncorrectEmailOrPasswordError;
