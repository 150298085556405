import { pathOr } from 'ramda';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/apiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import type { JsonApiError } from 'types/json-api/raw';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { sendPasswordRecoveryLink } from '../actions';
import { SEND_PASSWORD_RECOVERY_LINK } from '../constants';
import { sendPasswordRecoveryLinkEndpoint } from '../endpoints';

const sendPasswordRecoveryLinkOperation = createLogic<typeof sendPasswordRecoveryLink>({
  type: SEND_PASSWORD_RECOVERY_LINK,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = sendPasswordRecoveryLinkEndpoint;

    const body = {
      email: action.values.email,
      redirect_to: ROUTES.PASSWORD_RECOVERY_NEW_PASSWORD.PATH,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));

      redirect(ROUTES.PASSWORD_RECOVERY_CHECK_EMAIL.PATH, { query: { email: action.values.email } });
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      if (status === 422) {
        const errorMsg = pathOr([], ['response', 'data', 'errors'], error);

        errorMsg.forEach((e: any) => {
          action.form.setStatus(e.detail);
        });
      }

      if (status !== 422) {
        showErrorNotifications({ error, dispatch })();
      }

      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });
    }

    done();
  },
});

export default sendPasswordRecoveryLinkOperation;
