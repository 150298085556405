import normalize from 'json-api-normalizer';

import { dataApiSuccess } from 'state/data/actions';
import createLogic from 'utils/state/createLogic';

import type { receiveNotification } from '../actions';
import { setNotificationsIds, incUnreadNotificationsCount } from '../actions';
import { RECEIVE_NOTIFICATION } from '../constants';
import { unreadNotificationsCountSelector } from '../selectors';

const receiveNotificationOperation = createLogic<typeof receiveNotification>({
  type: RECEIVE_NOTIFICATION,
  latest: true,

  async process({ getState, action: { notification } }, dispatch, done) {
    const state = getState();
    const response = normalize(notification);
    const unreadCount = unreadNotificationsCountSelector(state);

    dispatch(dataApiSuccess({ response }));
    dispatch(setNotificationsIds([notification.data.id]));
    dispatch(incUnreadNotificationsCount(unreadCount));

    done();
  },
});

export default receiveNotificationOperation;
