import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientAssessmentById } from '../actions';
import * as constants from '../constants';
import { fetchPatientAssessmentByIdEndpoint } from '../endpoints';

const fetchPatientAssessmentByIdOperation = createLogic<typeof fetchPatientAssessmentById>({
  type: constants.FETCH_PATIENT_ASSESSMENT_BY_ID,
  latest: true,

  async process({ action: { id } }, dispatch, done) {
    const { endpoint, url } = fetchPatientAssessmentByIdEndpoint(id);

    try {
      const params = {
        include: 'blood_work,imaging,medical_equipment,prescription,sick_note,therapy,biopsy',
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchPatientAssessmentByIdOperation;
