import {
  userLoginRoute,
  userLogoutRoute,
  userSignUpRoute,
  resendVerificationLinkRoute,
  checkEmailCorrectnessRoute,
  resetPasswordRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const userLoginEndpoint = endpoint(constants.USER_LOGIN, 'GET', userLoginRoute);
export const userSignUpEndpoint = (role: string) => endpoint(constants.USER_SIGN_UP, 'POST', userSignUpRoute(role));
export const userLogoutEndpoint = endpoint(constants.USER_LOGOUT, 'GET', userLogoutRoute);

// Sign Up
export const resendVerificationLinkEndpoint = endpoint(
  constants.RESEND_VERIFICATION_LINK,
  'POST',
  resendVerificationLinkRoute,
);

export const checkEmailCorrectnessEndpoint = endpoint(
  constants.CHECK_EMAIL_TOKEN_CORRECTNESS,
  'POST',
  checkEmailCorrectnessRoute,
);

// Password recovery
export const sendPasswordRecoveryLinkEndpoint = endpoint(
  constants.SEND_PASSWORD_RECOVERY_LINK,
  'POST',
  resetPasswordRoute,
);

export const saveNewPasswordEndpoint = endpoint(constants.SEND_PASSWORD_RECOVERY_LINK, 'PUT', resetPasswordRoute);

export const checkPasswordRecoveryTokenCorrectnessEndpoint = endpoint(
  constants.CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS,
  'GET',
  resetPasswordRoute,
);
