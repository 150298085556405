import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderPatientProfileAssessmentById } from '../actions';
import { FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID } from '../constants';
import { fetchProviderPatientProfileAssessmentByIdEndpoint } from '../endpoints';

const fetchProviderPatientProfileAssessmentByIdOperation = createLogic<
  typeof fetchProviderPatientProfileAssessmentById
>({
  type: FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID,
  latest: true,

  async process({ action: { patientId, assessmentId } }, dispatch, done) {
    const { endpoint, url } = fetchProviderPatientProfileAssessmentByIdEndpoint(patientId, assessmentId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderPatientProfileAssessmentByIdOperation;
