import actionCreator from 'utils/state/actionCreator';

import * as constants from './constants';

export const fetchNotificationSettings = actionCreator(constants.FETCH_NOTIFICATION_SETTINGS);

export const setNotificationSettingsId = actionCreator((id: string) => ({
  type: constants.SET_NOTIFICATION_SETTINGS_ID,
  id,
}));

export const updateNotificationSettings = actionCreator(
  (settings: {
    emailAppointment: boolean;
    emailCommunication: boolean;
    emailReminder: boolean;
    inAppAppointment: boolean;
    inAppCommunication: boolean;
    inAppReminder: boolean;
  }) => ({
    type: constants.UPDATE_NOTIFICATION_SETTINGS,
    settings,
  }),
);

export const setNotificationSidebarVisibility = actionCreator((isVisible: boolean) => ({
  type: constants.SET_SIDEBAR_VISIBILITY,
  isVisible,
}));

export const readAllNotifications = actionCreator(constants.READ_ALL_NOTIFICATIONS);

export const fetchNotifications = actionCreator((before = null) => ({
  type: constants.FETCH_NOTIFICATIONS,
  before,
}));

export const setNotificationsIds = actionCreator((ids: string[]) => ({
  type: constants.SET_NOTIFICATIONS_IDS,
  ids,
}));

export const receiveNotification = actionCreator((notification) => ({
  type: constants.RECEIVE_NOTIFICATION,
  notification,
}));

export const setNotificationsHasMore = actionCreator((hasMore: boolean) => ({
  type: constants.SET_NOTIFICATIONS_HAS_MORE,
  hasMore,
}));

export const setUnreadNotificationsCount = actionCreator((unreadCount: number) => ({
  type: constants.SET_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
}));

export const incUnreadNotificationsCount = actionCreator((unreadCount: number) => ({
  type: constants.INC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
}));

export const decUnreadNotificationsCount = actionCreator((unreadCount: number) => ({
  type: constants.DEC_UNREAD_NOTIFICATIONS_COUNT,
  unreadCount,
}));

export const readNotification = actionCreator((id: string) => ({
  type: constants.READ_NOTIFICATION,
  id,
}));

export const unreadNotification = actionCreator((id: string) => ({
  type: constants.UNREAD_NOTIFICATION,
  id,
}));

export const deleteNotification = actionCreator((id: string) => ({
  type: constants.DELETE_NOTIFICATION,
  id,
}));

export const deleteNotificationFromPanel = actionCreator((id: string) => ({
  type: constants.DELETE_NOTIFICATION_FROM_PANEL,
  id,
}));
