import add from 'date-fns/add';
import format from 'date-fns/format';
import sub from 'date-fns/sub';

import { CALENDAR_COUNT_FETCHED_MONTHS } from 'constants/calendar';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { DateAvailability } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProvideCalendar } from '../actions';
import { setWorkingHours, setWorkingBrakes } from '../actions';
import { FETCH_PROVIDER_CALENDAR } from '../constants';
import { fetchProvideCalendarEndpoint } from '../endpoints';

const fetchProvideCalendarOperation = createLogic<typeof fetchProvideCalendar>({
  type: FETCH_PROVIDER_CALENDAR,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchProvideCalendarEndpoint;

    try {
      const params = {
        'filter[date_from]': action.start,
        'filter[date_to]': action.end,
      };

      if (!action.start) {
        const now = new Date();

        const from = sub(now, { months: 1 });
        const to = add(now, { months: CALENDAR_COUNT_FETCHED_MONTHS });

        const formatString = 'yyyy-MM-dd';

        params['filter[date_from]'] = format(from, formatString);
        params['filter[date_to]'] = format(to, formatString);
      }

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const workingBrakes = data.data || [];
      const workingHours = data.meta?.calendar || [];
      const maxLength = Math.max(workingBrakes.length, workingHours.length);

      const workingBrakesByDate: Record<
        string,
        {
          id: string;
          type: string;
          attributes: {
            date: string;
          };
        }
      > = {};
      const workingHoursByDate: Record<string, DateAvailability[]> = {};

      for (let i = 0; i < maxLength; i += 1) {
        // Working brakes
        const brake = workingBrakes[i];
        if (brake) {
          workingBrakesByDate[brake.attributes.date] = brake;
        }

        // Working hours
        const workingHour = workingHours[i];
        if (workingHour) {
          const date = Object.keys(workingHour)[0];

          const hours = workingHour[date];

          workingHoursByDate[date] = [];

          for (let j = 0; j < hours.length; j += 1) {
            const hour = hours[j];

            workingHoursByDate[date] = [
              ...workingHoursByDate[date],
              { ...hour, time_string: `${hour.begin_time} - ${hour.end_time}` },
            ];
          }
        }
      }

      dispatch(setWorkingBrakes(workingBrakesByDate));
      dispatch(setWorkingHours(workingHoursByDate));
      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProvideCalendarOperation;
