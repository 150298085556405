import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';

const createDateFromTimeString = (time: string) => {
  const array = time.split(':');

  const hour = parseInt(array[0], 10);
  const minutes = parseInt(array[1], 10);

  // UTC date
  let date = new Date(Date.UTC(0, 0, 0, 0, 0, 0));
  date = setHours(date, hour);
  date = setMinutes(date, minutes);
  date = setSeconds(date, 0);

  return date;
};

export default createDateFromTimeString;
