import type { IncomingHttpHeaders, ServerResponse } from 'http';

function addToCookies(headers: IncomingHttpHeaders, res: ServerResponse) {
  const responseSetCookie = (headers?.['set-cookie'] as string[]) ?? [];
  let existingSetCookie = (res.getHeader('set-cookie') as string[] | undefined) ?? [];
  if (typeof existingSetCookie === 'string') {
    existingSetCookie = [existingSetCookie];
  }

  res.setHeader('set-cookie', [...existingSetCookie, ...responseSetCookie]);
}

export default addToCookies;
