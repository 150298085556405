import normalize from 'json-api-normalizer';

import { PROVIDER_PROFILE_INCLUSIONS } from 'constants/profile';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { createPayoutMethod } from '../actions';
import { fetchProviderProfile } from '../actions';
import { CREATE_PAYOUT_METHOD } from '../constants';
import { createPayoutMethodEndpoint } from '../endpoints';

const createPayoutMethodOperation = createLogic<typeof createPayoutMethod>({
  type: CREATE_PAYOUT_METHOD,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = createPayoutMethodEndpoint;
    const { routingNumber, accountNumber } = action.values;

    try {
      dispatch(dataApiRequest({ endpoint }));
      const body = { routing_number: routingNumber, account_number: accountNumber };

      const { data } = await httpClient.post(url, body);

      const response = normalize(data);

      const inclusions = [PROVIDER_PROFILE_INCLUSIONS.PAYOUT_METHOD];
      dispatch(fetchProviderProfile({ include: inclusions.join(',') }));

      dispatch(dataApiSuccess({ endpoint, response }));

      dispatch(hideModal());

      const stripeAccountLinkUrl = data?.meta?.stripe_account_link_url;
      if (stripeAccountLinkUrl) {
        window.open(stripeAccountLinkUrl);
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default createPayoutMethodOperation;
