import type { WS_STATUSES } from 'constants/webSockets';
import { actionCreator } from 'utils/state';

import * as constants from './constants';

export const openConnection = actionCreator(constants.OPEN_CONNECTION);

export const closeConnection = actionCreator(constants.CLOSE_CONNECTION);

export const setConnectionStatus = actionCreator((status: keyof typeof WS_STATUSES) => {
  return {
    type: constants.SET_CONNECTION_STATUS,
    status,
  };
});

export const addSubscriptionChannels = actionCreator((channels) => ({
  type: constants.ADD_SUBSCRIPTION_CHANNELS,
  channels,
}));

export const removeSubscriptionChannels = actionCreator((channels) => ({
  type: constants.REMOVE_SUBSCRIPTION_CHANNELS,
  channels,
}));

export const resetSubscriptionChannels = actionCreator(constants.RESET_SUBSCRIPTION_CHANNELS);

export const sendCommand = actionCreator((channelConfig) => ({
  type: constants.SEND_COMMAND,
  channelConfig,
}));

export const receiveData = actionCreator((data) => ({
  type: constants.RECEIVE_DATA,
  data,
}));
