import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchChecklist } from '../actions';
import { FETCH_CHECKLIST } from '../constants';
import { fetchChecklistEndpoint } from '../endpoints';

const fetchChecklistOperation = createLogic<typeof fetchChecklist>({
  type: FETCH_CHECKLIST,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchChecklistEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchChecklistOperation;
