import normalize from 'json-api-normalizer';

import { PATIENT_LIST_OF_APPOINTMENTS_LENGTH } from 'constants/patients';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderPatientProfileAppointmentsById } from '../actions';
import { setProviderPatientProfileAppointmentsIds, setProviderPatientProfileAppointmentsListTotal } from '../actions';
import { FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID } from '../constants';
import { fetchProviderPatientProfileAppointmentsByIdEndpoint } from '../endpoints';
import {
  providerPatientProfileAppointmentsPageNumberSelector,
  providerPatientProfileAppointmentsSearchSelector,
  providerPatientProfileAppointmentsSortParamsSelector,
} from '../selectors';

const fetchProviderPatientProfileAppointmentsByIdOperation = createLogic<
  typeof fetchProviderPatientProfileAppointmentsById
>({
  type: FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID,
  latest: true,

  async process({ getState, action: { patientId } }, dispatch, done) {
    const { endpoint, url } = fetchProviderPatientProfileAppointmentsByIdEndpoint(patientId);
    const state = getState();

    try {
      const params = {
        include: 'symptom,speciality',
        sort: providerPatientProfileAppointmentsSortParamsSelector(state),
        search: providerPatientProfileAppointmentsSearchSelector(state),
        page: {
          number: providerPatientProfileAppointmentsPageNumberSelector(state),
          size: PATIENT_LIST_OF_APPOINTMENTS_LENGTH,
        },
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setProviderPatientProfileAppointmentsIds(data.data.map(({ id }: any) => id)));
      dispatch(setProviderPatientProfileAppointmentsListTotal(data.meta.total));
    } catch (error) {
      dispatch(setProviderPatientProfileAppointmentsIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderPatientProfileAppointmentsByIdOperation;
