import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'videoConference';

export const SET_IS_PREVIEW = createActionType(CONCEPT_NAME, 'SET_IS_PREVIEW');
export const SET_CALL_ID = createActionType(CONCEPT_NAME, 'SET_CALL_ID');
export const SET_LOCAL_SETTINGS = createActionType(CONCEPT_NAME, 'SET_LOCAL_SETTINGS');
export const RESET_LOCAL_SETTINGS = createActionType(CONCEPT_NAME, 'RESET_LOCAL_SETTINGS');
export const GENERATE_PATIENT_VIDEO_CONFERENCE_ACCESS = createActionType(
  CONCEPT_NAME,
  'GENERATE_PATIENT_VIDEO_CONFERENCE_ACCESS',
);
export const GENERATE_PROVIDER_VIDEO_CONFERENCE_ACCESS = createActionType(
  CONCEPT_NAME,
  'GENERATE_PROVIDER_VIDEO_CONFERENCE_ACCESS',
);
export const SET_CHAT_VISIBLE = createActionType(CONCEPT_NAME, 'SET_CHAT_VISIBLE');
export const SET_QUESTIONNAIRE_VISIBLE = createActionType(CONCEPT_NAME, 'SET_QUESTIONNAIRE_VISIBLE');
export const SET_ASSESSMENT_VISIBLE = createActionType(CONCEPT_NAME, 'SET_ASSESSMENT_VISIBLE');
