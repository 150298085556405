import cancelPatientAppointmentOperation from './cancelPatientAppointment';
import cancelProviderAppointmentOperation from './cancelProviderAppointment';
import fetchPatientAppointmentByIdWithChatOperation from './fetchPatientAppointmentByIdWithChat';
import fetchPatientAppointmentsOperation from './fetchPatientAppointments';
import fetchProviderAppointmentByIdOperation from './fetchProviderAppointmentById';
import fetchProviderAppointmentsOperation from './fetchProviderAppointments';
import fetchProviderAppointmentsAssessmentByIdOperation from './fetchProviderAppointmentsAssessmentById';
import reschedulePatientAppointmentOperation from './reschedulePatientAppointment';
import submitEditAppointmentQuestionnaireOperation from './submitEditAppointmentQuestionnaire';

const operations = [
  fetchPatientAppointmentsOperation,
  fetchProviderAppointmentsOperation,
  submitEditAppointmentQuestionnaireOperation,
  reschedulePatientAppointmentOperation,
  fetchPatientAppointmentByIdWithChatOperation,
  cancelPatientAppointmentOperation,
  fetchProviderAppointmentByIdOperation,
  fetchProviderAppointmentsAssessmentByIdOperation,
  cancelProviderAppointmentOperation,
];

export default operations;
