import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { providerProfileSelector } from 'state/concepts/session/selectors';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { WorkingHour } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import { constructSpecialties, constructWorkingHours } from 'utils/constructProviderOnboardingBody';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderSpecialityAndWorkingHours } from '../actions';
import { UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS } from '../constants';
import { updateProviderSpecialityAndWorkingHoursEndpoint } from '../endpoints';

const updateProviderSpecialityAndWorkingHoursOperation = createLogic<typeof updateProviderSpecialityAndWorkingHours>({
  type: UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PROVIDER.CANCELLATION_SCENARIO.PATH;

    const { endpoint, url } = updateProviderSpecialityAndWorkingHoursEndpoint;

    const body = {
      include: 'working_hours,user_profiles_specialities',
      consultation_price_cents: action.values.consultationPrice,
      user_profiles_specialities: [],
      working_hours: [],
    };

    const providerProfile = providerProfileSelector(getState());

    // Construct specialties
    (body.user_profiles_specialities as any) = constructSpecialties(
      providerProfile?.userProfilesSpecialities || [],
      action.values.speciality as string,
    );

    // Construct working hours
    (body.working_hours as any) = constructWorkingHours(
      providerProfile?.workingHours as WorkingHour[],
      action.values.hours,
    );

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updateProviderSpecialityAndWorkingHoursOperation;
