import {
  providerProfileCalendarRoute,
  providerProfileDateAvailabilityRoute,
  providerProfileRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchProvideCalendarEndpoint = endpoint(
  constants.FETCH_PROVIDER_CALENDAR,
  'GET',
  providerProfileCalendarRoute,
);

export const resetToWeeklyHoursEndpoint = endpoint(
  constants.RESET_TO_WEEKLY_HOURS,
  'DELETE',
  providerProfileDateAvailabilityRoute,
);

export const createWorkingBreakEndpoint = endpoint(constants.CREATE_WORKING_BREAK, 'PUT', providerProfileRoute);

export const createSingleDateAvailabilityEndpoint = endpoint(
  constants.CREATE_SINGLE_DATE_AVAILABILITY,
  'PUT',
  providerProfileDateAvailabilityRoute,
);
