import format from 'date-fns/format';
import normalize from 'json-api-normalizer';
import { isEmpty } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';
import type { WorkingHour } from 'views/shared/WorkingHourField/types';

import type { createSingleDateAvailability } from '../actions';
import { fetchProvideCalendar } from '../actions';
import { CREATE_SINGLE_DATE_AVAILABILITY } from '../constants';
import { createSingleDateAvailabilityEndpoint, createWorkingBreakEndpoint } from '../endpoints';

const createSingleDateAvailabilityOperation = createLogic<typeof createSingleDateAvailability>({
  type: CREATE_SINGLE_DATE_AVAILABILITY,
  latest: true,

  async process({ action }, dispatch, done) {
    const body = {
      include: 'date_availabilities',
      date: '',
      date_availabilities: [
        {
          begin_time: '',
          end_time: '',
        },
      ],
    };

    if (action.values.date instanceof Date) {
      body.date = format(action.values.date, 'yyyy-MM-dd');

      const weekday = format(action.values.date, 'EEEE').toLowerCase();

      body.date_availabilities = [];

      const hours = action.values.hours[weekday] as WorkingHour[];
      hours.forEach((element) => {
        if (element.start instanceof Date && element.end instanceof Date) {
          body.date_availabilities.push({
            begin_time: format(element.start, 'HH:mm'),
            end_time: format(element.end, 'HH:mm'),
          });
        }
      });
    }

    const { endpoint, url } = isEmpty(body.date_availabilities)
      ? createWorkingBreakEndpoint
      : createSingleDateAvailabilityEndpoint;

    try {
      if (isEmpty(body.date_availabilities) && action.values.date instanceof Date) {
        // TODO: optimize me in future
        // dublicate logic from create working break
        dispatch(dataApiRequest({ endpoint }));

        const { data } = await httpClient.put(url, {
          date_specific_brakes: [{ date: format(action.values.date, 'yyyy-MM-dd') }],
        });
        dispatch(fetchProvideCalendar());

        const response = normalize(data);

        dispatch(dataApiSuccess({ endpoint, response }));
      } else {
        dispatch(dataApiRequest({ endpoint }));

        const { data } = await httpClient.put(url, body);

        dispatch(fetchProvideCalendar());

        const response = normalize(data);

        dispatch(dataApiSuccess({ endpoint, response }));
      }

      dispatch(showMessage({ messageText: { id: 'shared.changesHasBeenSaved' } }));
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default createSingleDateAvailabilityOperation;
