import isSameDay from 'date-fns/isSameDay';
import { path } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { FAMILY_MEDICINE_PROVIDERS_APPOINTMENT_IN_MINUTES } from 'constants/bookAppointment';
import { dataSelector } from 'state/data/selectors';
import type { RootState } from 'state/store/types';
import type { Appointment, EventTypes } from 'types/entities/appointment';
import type { Assessment } from 'types/entities/assessments';
import type { NormalizedResponseData } from 'types/json-api/normalized';
import appointmentTimeAndDate from 'utils/dateHelpers/appointmentTimeAndDate';
import createDateIncludeOffset from 'utils/dateHelpers/createDateIncludeOffset';
import isPresent from 'utils/isPresent';
import getFloatPrice from 'utils/price';

export const appointmentIdsSelector = path<string[] | null>(['appointment', 'appointmentIds']);
export const appointmentsListTotalSelector = path<number | null>(['appointment', 'appointmentsListTotal']);
export const appointmentsPageNumberSelector = path<number>(['appointment', 'appointmentsPageNumber']);
export const assessmentVisibleSelector = path<boolean>(['appointment', 'assessmentVisible']);
export const drawerVisibleSelector = path<boolean>(['appointment', 'drawerVisible']);
export const questionnaireVisibleSelector = path<boolean>(['appointment', 'questionnaireVisible']);

export const appointmentsSelector = createSelector(
  appointmentIdsSelector,
  dataSelector,
  (ids, data) => build<Appointment>(data, 'appointment', ids as string[]) as Appointment[] | null,
);

export const appointmentsProviderEventsSelector = createSelector(
  appointmentsSelector,
  (appointments: Appointment[] | null) =>
    isPresent(appointments)
      ? (appointments?.map((appointment: Appointment) => ({
          start: createDateIncludeOffset(
            appointment.startTimeInCustomTz as string,
            appointment.userTimezone?.offset as string,
          ),
          end: createDateIncludeOffset(
            appointment.endTimeInCustomTz as string,
            appointment.userTimezone?.offset as string,
          ),
          data: {
            id: appointment.id,
            type: appointment.status,
            time: appointmentTimeAndDate({
              startTime: appointment.startTimeInCustomTz as string,
              endTime: appointment.endTimeInCustomTz as string,
              offset: appointment.userTimezone?.offset as string,
            }),
            endTime: appointmentTimeAndDate({
              startTime: appointment.oldStartTimeInCustomTz as string,
              endTime: appointment.oldEndTimeInCustomTz as string,
              offset: appointment.userTimezone?.offset as string,
            }),
            symptom: appointment.symptom?.name,
            price: getFloatPrice(appointment.price.cents),
            patientProfile: appointment.patientProfile,
            userTitle: appointment.userProfile.title,
            duration: `${FAMILY_MEDICINE_PROVIDERS_APPOINTMENT_IN_MINUTES}m`,
            cancellationReason: appointment.appointmentCancellationReason,
            refunded: getFloatPrice(appointment.refunded?.cents),
          },
        })) as EventTypes[])
      : ([] as EventTypes[]),
);

export const appointmentEventsForDaySelector = createSelector(
  (_: RootState, day: Date) => day,
  appointmentsProviderEventsSelector,
  (day: Date, events: EventTypes[]) => events?.filter(({ start }) => isSameDay(start, day)),
);

export const appointmentsAssessmentByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build<Assessment>(data, 'assessment', id),
);

export const appointmentByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build<Appointment>(data, 'appointment', id),
);
