import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import { createRouteFromPathname } from 'utils/createRouteHelper';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { RESEND_VERIFICATION_LINK } from '../constants';
import { resendVerificationLinkEndpoint } from '../endpoints';

const resendVerificationLinkOperation = createLogic({
  type: RESEND_VERIFICATION_LINK,
  latest: true,

  async process({ action }, dispatch, done) {
    const { pathname, userRole } = action;
    const { endpoint, url } = resendVerificationLinkEndpoint;

    try {
      const redirectTo = userRole
        ? createRouteFromPathname({
            pathname: ROUTES.EMAIL_VERIFICATION.PATH,
            pathChunkName: 'role',
            pathChunkReplacer: userRole,
          })
        : pathname;

      const body = {
        redirect_to: redirectTo,
      };

      dispatch(dataApiRequest({ endpoint }));

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));

      dispatch(showMessage({ messageText: { id: 'auth.emailConfirmationLinkHasBeenResent' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default resendVerificationLinkOperation;
