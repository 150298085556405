import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { setPatientProfileId } from 'state/concepts/session/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientOnboarding } from '../actions';
import { FETCH_PATIENT_ONBOARDING } from '../constants';
import { fetchPatientOnboardingEndpoint } from '../endpoints';

// TODO: add test after API bug fixes
/* istanbul ignore next */
const fetchPatientOnboardingOperation = createLogic<typeof fetchPatientOnboarding>({
  type: FETCH_PATIENT_ONBOARDING,
  latest: true,
  warnTimeout: 10000,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchPatientOnboardingEndpoint;

    const params = {
      include: action.include,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setPatientProfileId(data.data.id));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
        skipHandlers: action.skipHandlers,
      });
    }

    done();
  },
});

export default fetchPatientOnboardingOperation;
