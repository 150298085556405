import { uniq, without, inc, dec } from 'ramda';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import { USER_LOGOUT } from 'state/concepts/session/constants';

import * as constants from './constants';

export const notificationSettingsId = (state = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_NOTIFICATION_SETTINGS_ID:
      return action.id;
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
};

export const notificationSidebarVisible = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SIDEBAR_VISIBILITY:
      return action.isVisible;
    case USER_LOGOUT:
      return false;
    default:
      return state;
  }
};

export const notificationsIds = (state: string[] = [], action: AnyAction) => {
  switch (action.type) {
    case constants.SET_NOTIFICATIONS_IDS:
      return uniq([...action.ids, ...state]);
    case constants.DELETE_NOTIFICATION_FROM_PANEL:
      return without([action.id], state);
    case USER_LOGOUT:
      return null;
    default:
      return state;
  }
};

export const notificationsHasMore = (state = false, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_NOTIFICATIONS_HAS_MORE:
      return action.hasMore;
    default:
      return state;
  }
};

export const unreadNotificationsCount = (state = 0, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_UNREAD_NOTIFICATIONS_COUNT:
      return action.unreadCount;
    case constants.DEC_UNREAD_NOTIFICATIONS_COUNT:
      return dec(action.unreadCount);
    case constants.INC_UNREAD_NOTIFICATIONS_COUNT:
      return inc(action.unreadCount);
    default:
      return state;
  }
};

const calendarReducer = combineReducers({
  notificationSettingsId,
  notificationSidebarVisible,
  notificationsIds,
  notificationsHasMore,
  unreadNotificationsCount,
});

export default calendarReducer;
