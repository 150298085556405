import format from 'date-fns/format';
import utcToZonedTime from 'date-fns-tz/utcToZonedTime';

interface IAppointmentTimeAndDate {
  startTime: string | null;
  endTime: string | null;
  offset: string;
}
const appointmentTimeAndDate = ({ startTime, endTime, offset }: IAppointmentTimeAndDate) => {
  if (!startTime || !endTime) return { time: '', date: '' };
  const startTimeIncludeOffset = utcToZonedTime(new Date(startTime), offset);
  const endTimeIncludeOffset = utcToZonedTime(new Date(endTime), offset);

  const date = format(startTimeIncludeOffset, 'EEE, LLLL dd, yyyy');
  const since = format(startTimeIncludeOffset, 'hh:mm aaa');
  const until = format(endTimeIncludeOffset, 'hh:mm aaa');

  return { time: `${since} - ${until}`, date, startTime: since, endTime: until };
};

export default appointmentTimeAndDate;
