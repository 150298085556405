/* eslint-disable @next/next/no-page-custom-font -- it's a document page */
import Head from 'next/head';
import { useIntl } from 'react-intl';

import type { MetaProps } from './types';

function MetaComponent(props: MetaProps) {
  const { title } = props;

  const intl = useIntl();

  const titleString = typeof title?.id !== 'undefined' ? intl.formatMessage(title) : undefined;

  return (
    <Head>
      {typeof titleString !== 'undefined' && <title>{titleString}</title>}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no maximum-scale=1.0 user-scalable=no"
      />
      <meta content="ie=edge" httpEquiv="x-ua-compatible" />
      <meta charSet="utf-8" />
      <meta name="description" content={intl.formatMessage({ id: 'shared.seo.meta.description' })} />
      <meta name="robots" content="noindex" />
      <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap" rel="stylesheet" />
    </Head>
  );
}

export default MetaComponent;

/* eslint-enable @next/next/no-page-custom-font */
