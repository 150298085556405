import checkEmailTokenCorrectness from './checkEmailTokenCorrectness';
import checkPasswordRecoveryTokenCorrectness from './checkPasswordRecoveryTokenCorrectness';
import resendVerificationLink from './resendVerificationLink';
import saveNewPassword from './saveNewPassword';
import sendPasswordRecoveryLink from './sendPasswordRecoveryLink';
import userLogout from './userLogout';
import userSignIn from './userSignIn';
import userSignUp from './userSignUp';

const operations = [
  checkEmailTokenCorrectness,
  checkPasswordRecoveryTokenCorrectness,
  resendVerificationLink,
  saveNewPassword,
  sendPasswordRecoveryLink,
  userLogout,
  userSignIn,
  userSignUp,
];

export default operations;
