import { useSelector } from 'react-redux';

import useDispatchAction from 'hooks/useDispatchAction';
import { hideModal } from 'state/modal/actions';
import { modalStateSelector } from 'state/modal/selectors';

const useModalRoot = () => {
  const modalState = useSelector(modalStateSelector);
  const handleHideModal = useDispatchAction(hideModal);

  if (modalState.modalProps) {
    modalState.modalProps = { ...modalState.modalProps, onClose: handleHideModal };
  }

  return modalState;
};

export default useModalRoot;
