import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderAppointmentsAssessmentById } from '../actions';
import { FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID } from '../constants';
import { fetchProviderAppointmentsAssessmentByIdEndpoint } from '../endpoints';

const fetchProviderAppointmentsAssessmentByIdOperation = createLogic<typeof fetchProviderAppointmentsAssessmentById>({
  type: FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID,
  latest: true,

  async process({ action: { id } }, dispatch, done) {
    const { endpoint, url } = fetchProviderAppointmentsAssessmentByIdEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.post(url);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderAppointmentsAssessmentByIdOperation;
