import { path, equals } from 'ramda';
import { createSelector } from 'reselect';

import { WS_STATUSES } from 'constants/webSockets';

export const connectionStatusSelector = path(['webSockets', 'status']);

export const subscriptionChannelsSelector = path(['webSockets', 'channels']);

export const isOpenedConnectionSelector = createSelector(connectionStatusSelector, equals<any>(WS_STATUSES.opened));

export const isClosedConnectionSelector = createSelector(connectionStatusSelector, equals<any>(WS_STATUSES.closed));
