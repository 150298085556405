import normalize from 'json-api-normalizer';
import snakeCase from 'lodash/snakeCase';

import { APPOINTMENT_PAGE_LENGTH } from 'constants/appointment';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Appointment } from 'types/entities/appointment';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientAppointments } from '../actions';
import { setAppointmentsListTotal, setAppointmentIds } from '../actions';
import { FETCH_PATIENT_APPOINTMENTS } from '../constants';
import { fetchPatientAppointmentsEndpoint } from '../endpoints';
import { appointmentsPageNumberSelector } from '../selectors';

const fetchPatientAppointmentsOperation = createLogic<typeof fetchPatientAppointments>({
  type: FETCH_PATIENT_APPOINTMENTS,
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { status, sort } = action;
    const { endpoint, url } = fetchPatientAppointmentsEndpoint;
    const pageNumber = appointmentsPageNumberSelector(getState());

    try {
      const params = {
        include: 'appointment_cancellation_reason',
        sort,
        filter: {
          status: snakeCase(status),
        },
        page: {
          number: pageNumber,
          size: APPOINTMENT_PAGE_LENGTH,
        },
      };

      dispatch(dataApiRequest({ endpoint }));
      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));
      dispatch(setAppointmentIds(data.data.map(({ id }: Appointment) => id)));
      dispatch(setAppointmentsListTotal(data.meta.total));
    } catch (error) {
      dispatch(setAppointmentIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchPatientAppointmentsOperation;
