import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/nextApiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { resetStore } from 'state/store/action';
import { closeConnection } from 'state/webSockets/actions';
import type { MiddlewareErrorType } from 'types/errors';
import isServer from 'utils/isServer';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { userLogoutSuccess } from '../actions';
import { USER_LOGOUT } from '../constants';
import { userLogoutEndpoint } from '../endpoints';

const userLogoutOperation = createLogic({
  type: USER_LOGOUT,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = userLogoutEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(userLogoutSuccess());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });
    } finally {
      if (!isServer()) {
        redirect(ROUTES.LOGIN.PATH);
      }
      dispatch(closeConnection());
      dispatch(resetStore());
    }

    done();
  },
});

export default userLogoutOperation;
