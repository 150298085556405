import { is } from 'ramda';

import type { Action, ActionCreator, ActionCreatorFn } from './types';

function actionCreator<T extends string>(type: T): ActionCreator<[], { type: T }>;
function actionCreator<T extends string, As extends any[], P extends object>(
  type: T,
  payloadCreator: (...args: As) => P,
): ActionCreator<As, { type: T } & P>;
function actionCreator<AC extends ActionCreatorFn<any[], Action>>(
  fn: AC,
): ActionCreator<Parameters<AC>, ReturnType<AC>>;
function actionCreator(
  typeOrFn: string | ActionCreator<any[], Action>,
  maybePayloadCreator?: (...args: any[]) => object,
) {
  if (is(String, typeOrFn)) {
    return (...args: any[]) => ({ ...maybePayloadCreator?.(...args), type: typeOrFn });
  }
  return typeOrFn;
}

export default actionCreator;
