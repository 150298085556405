import { v4 as uuid } from 'uuid';

import * as constants from './constants';
import { MessageType, MESSAGE_DURATION } from './messagesTypes';
import type { ShowMessageArgs, HideMessageArgs } from './types';

export const showMessage = ({
  id = uuid(),
  messageType = MessageType.SUCCESS,
  duration = MESSAGE_DURATION,
  messageText,
}: ShowMessageArgs) => ({
  type: constants.SHOW_MESSAGE,
  id,
  messageType,
  messageText,
  duration,
});

export const hideMessage = ({ id }: HideMessageArgs) => ({
  type: constants.HIDE_MESSAGE,
  id,
});

export const hideAll = () => ({
  type: constants.HIDE_ALL,
});
