import {
  specialtiesRoute,
  timezonesRoute,
  medicalTitlesRoute,
  statesRoute,
  boardCertificationsRoute,
  symptomsRoute,
  contactUsRoute,
  socialLinkListRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchProviderSpecialtiesEndpoint = endpoint(constants.FETCH_PROVIDER_SPECIALTIES, 'GET', specialtiesRoute);

export const fetchAvailableTimezonesEndpoint = endpoint(constants.FETCH_AVAILABLE_TIMEZONES, 'GET', timezonesRoute);

export const fetchProviderMedicalTitlesEndpoint = endpoint(
  constants.FETCH_AVAILABLE_MEDICAL_TITLES,
  'GET',
  medicalTitlesRoute,
);
export const downloadFileEndpoint = (url: string, documentName: string) =>
  endpoint(`DOWNLOAD_FILE_${documentName}`, 'GET', url);
export const fetchAvailableStatesEndpoint = endpoint(constants.FETCH_AVAILABLE_STATES, 'GET', statesRoute);
export const sendContactUsEndpoint = endpoint(constants.SEND_CONTACT_US, 'POST', contactUsRoute);

export const fetchBoardLicensesEndpoint = endpoint(constants.FETCH_BOARD_LICENSES, 'GET', boardCertificationsRoute);
export const fetchSymptomsEndpoint = endpoint(constants.FETCH_SYMPTOMS, 'GET', symptomsRoute);

export const fetchSocialLinkListEndpoint = endpoint(constants.FETCH_SOCIAL_LINK_LIST, 'GET', socialLinkListRoute);
