import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import type { JsonApiError } from 'types/json-api/raw';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { fetchSymptoms } from '../actions';
import { setSymptomsIds } from '../actions';
import { FETCH_SYMPTOMS } from '../constants';
import { fetchSymptomsEndpoint } from '../endpoints';

const fetchSymptomsOperation = createLogic<typeof fetchSymptoms>({
  type: FETCH_SYMPTOMS,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchSymptomsEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      // FIXME: custom approach agreed with BE
      const { data } = await httpClient.get(url, {
        params: {
          page: {
            number: 1,
            size: 100,
          },
        },
      });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      // @ts-ignore
      dispatch(setSymptomsIds(pluck('id', data.data)));
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(dataApiFailure({ endpoint, status }));

      showErrorNotifications({ error, dispatch });
    }

    done();
  },
});

export default fetchSymptomsOperation;
