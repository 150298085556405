import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderSignature } from '../actions';
import { UPDATE_PROVIDER_SIGNATURE } from '../constants';
import { updateProviderSignatureEndpoint } from '../endpoints';

const updateProviderSignatureOperation = createLogic<typeof updateProviderSignature>({
  type: UPDATE_PROVIDER_SIGNATURE,
  latest: true,

  async process({ action: { body } }, dispatch, done) {
    const { endpoint, url } = updateProviderSignatureEndpoint;
    const redirectRoute = ROUTES.ONBOARDING.PROVIDER.DONE.PATH;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data: responseData } = await httpClient.put(url, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = normalize(responseData);

      dispatch(dataApiSuccess({ endpoint, response }));
      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updateProviderSignatureOperation;
