import type { ServerResponse } from 'http';
import Router from 'next/router';
import qs from 'qs';

import isPresent from './isPresent';

export const buildQuery = (query?: string | object) =>
  qs.stringify(query)
    ? qs.stringify(query, {
        arrayFormat: 'repeat',
      })
    : '';

const serverRedirect = (response: ServerResponse, destination: string, query?: string | object) => {
  response.writeHead(302, {
    Location: `${destination}${isPresent(query) ? `?${buildQuery(query)}` : ''}`,
  });
  response.end();
};

const clientRedirect = (pathname: string, query?: string | object, reload?: boolean) =>
  reload
    ? window.location.replace(`${pathname}${buildQuery(query)}`)
    : Router.push({
        pathname,
        query: buildQuery(query),
      });

interface IRedirectOption {
  response?: ServerResponse;
  query?: string | object;
  reload?: boolean;
}

const redirect = (destination: string, options?: IRedirectOption) => {
  // check if server-side render
  if (options?.response) {
    serverRedirect(options.response, destination, options?.query);
  } else {
    clientRedirect(destination, options?.query, options?.reload);
  }
};

export default redirect;
