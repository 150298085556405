import normalize from 'json-api-normalizer';
import { keys, propOr } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchChatMessages } from '../actions';
import { fetchChatAttachments, setMessages } from '../actions';
import * as constants from '../constants';
import { fetchChatMessagesEndpoint } from '../endpoints';

const fetchChatMessagesOperation = createLogic<typeof fetchChatMessages>({
  type: constants.FETCH_CHAT_MESSAGES,
  latest: true,

  async process({ action: { chatId, after, before } }, dispatch, done) {
    const { endpoint, url } = fetchChatMessagesEndpoint(chatId);

    try {
      dispatch(dataApiRequest({ endpoint }));
      dispatch(fetchChatAttachments(chatId));

      const { data } = await httpClient.get(url, {
        params: {
          include: 'chat-user,attachment',
          page: {
            before,
            after,
          },
        },
      });

      const response = normalize(data, { endpoint });
      const messageIds = keys(propOr({}, 'message', response));

      dispatch(setMessages(messageIds as string[]));
      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchChatMessagesOperation;
