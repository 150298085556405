import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderCancellationScenario } from '../actions';
import { UPDATE_PROVIDER_CANCELLATION_SCENARIO } from '../constants';
import { updateProviderCancellationScenarioEndpoint } from '../endpoints';

const updateProviderCancellationScenarioOperation = createLogic<typeof updateProviderCancellationScenario>({
  type: UPDATE_PROVIDER_CANCELLATION_SCENARIO,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PROVIDER.SIGNATURE.PATH;

    const { endpoint, url } = updateProviderCancellationScenarioEndpoint;

    const body = {
      include: 'cancellation_policy',
      rules: {
        one_hour: parseFloat(action.values.hour as string),
        one_day: parseFloat(action.values.hours24 as string),
        two_days: parseFloat(action.values.hours48 as string),
        three_days: parseFloat(action.values.hours72 as string),
      },
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updateProviderCancellationScenarioOperation;
