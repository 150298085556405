import normalize from 'json-api-normalizer';
import { pick } from 'ramda';

import mapKeysToSnakeCase from 'lib/clients/mapKeysToSnakeCase';
import httpClient from 'lib/clients/proxyClient';
import { setAssessmentVisible } from 'state/concepts/appointment/actions';
import { setAssessmentVisible as setAssessmentVisibleVideo } from 'state/concepts/videoConference/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { createAssessment } from '../actions';
import { CREATE_ASSESSMENT } from '../constants';
import { createAssessmentEndpoint } from '../endpoints';

const createAssessmentOperation = createLogic<typeof createAssessment>({
  type: CREATE_ASSESSMENT,
  latest: true,

  async process({ action }, dispatch, done) {
    const { appointmentId, chosenCategories } = action.values;
    const { endpoint, url } = createAssessmentEndpoint(appointmentId);

    try {
      const body = pick(
        ['is_draft', 'assessment', 'objective', 'subjective', 'plan', 'diagnosis', ...chosenCategories],
        mapKeysToSnakeCase(action.values),
      );
      dispatch(dataApiRequest({ endpoint }));
      const snakeBody = mapKeysToSnakeCase(body);
      const { data } = await httpClient.post(url, snakeBody);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setAssessmentVisible({ visible: false }));
      dispatch(setAssessmentVisibleVideo({ visible: false }));
      dispatch(showMessage({ messageText: { id: 'assessment.added.success' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default createAssessmentOperation;
