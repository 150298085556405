import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'data' as const;

export const DATA_API_REQUEST = createActionType(CONCEPT_NAME, 'DATA_API_REQUEST');
export const DATA_API_SUCCESS = createActionType(CONCEPT_NAME, 'DATA_API_SUCCESS');
export const DATA_API_FAILURE = createActionType(CONCEPT_NAME, 'DATA_API_FAILURE');
export const DATA_DELETE = createActionType(CONCEPT_NAME, 'DATA_DELETE');
export const DATA_CLEAR_RELATIONSHIP = createActionType(CONCEPT_NAME, 'DATA_CLEAR_RELATIONSHIP');
export const DATA_REMOVE_RELATIONSHIP = createActionType(CONCEPT_NAME, 'DATA_REMOVE_RELATIONSHIP');
export const META_REMOVE_BATCH_OF_ENDPOINTS = createActionType(CONCEPT_NAME, 'META_REMOVE_BATCH_OF_ENDPOINTS');
export const META_REMOVE_FIELDS_BATCH_OF_ENDPOINTS = createActionType(
  CONCEPT_NAME,
  'META_REMOVE_FIELDS_BATCH_OF_ENDPOINTS',
);
export const DATA_UPDATE = createActionType(CONCEPT_NAME, 'DATA_UPDATE');
export const DATA_UPDATE_ITEMS = createActionType(CONCEPT_NAME, 'DATA_UPDATE_ITEMS');
