import { path } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { dataSelector } from 'state/data/selectors';
import type { RootState } from 'state/store/types';
import type { Assessment, Diagnose } from 'types/entities/assessments';
import type { NormalizedResponseData } from 'types/json-api/normalized';
import { makeOptionsWithValueCode } from 'utils/form/makeOptions';

export const assessmentIdsSelector = path<string[] | null>(['assessments', 'assessmentIds']);
export const assessmentsListTotalSelector = path<number | null>(['assessments', 'assessmentsListTotal']);
export const assessmentsPageNumberSelector = path<number>(['assessments', 'assessmentsPageNumber']);
export const diagnosesIdsSelector = path<string[]>(['assessments', 'diagnosesIds']);

export const assessmentsSelector = createSelector(
  assessmentIdsSelector,
  dataSelector,
  (ids, data) => build<Assessment>(data, 'assessment', ids as string[]) as Assessment[],
);

export const assessmentByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build<Assessment>(data, 'assessment', id),
);

export const diagnosesSelector = createSelector(diagnosesIdsSelector, dataSelector, (ids, data) =>
  build<Diagnose>(data, 'diagnosis', ids),
);

export const diagnosesOptionsSelector = createSelector(diagnosesSelector, (diagnoses) =>
  makeOptionsWithValueCode(diagnoses),
);
