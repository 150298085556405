import { pickAll } from 'ramda';

import type { NormalizedResponse } from 'types/json-api/normalized';
import { actionCreator } from 'utils/state';

import * as constants from './constants';

const pickPayload: <T>(names: Array<Extract<keyof T, string>>) => (obj: T) => T = pickAll;

export const dataApiRequest = actionCreator(
  constants.DATA_API_REQUEST,
  pickPayload<{ endpoint: string }>(['endpoint']),
);

export const dataApiSuccess = actionCreator(
  constants.DATA_API_SUCCESS,
  pickPayload<{ endpoint?: string; response?: NormalizedResponse }>(['endpoint', 'response']),
);

export const dataApiFailure = actionCreator(
  ({ endpoint, status }: { endpoint: string; status?: number | undefined }) => ({
    type: constants.DATA_API_FAILURE,
    endpoint,
    status,
  }),
);

export const dataUpdate = actionCreator(
  constants.DATA_UPDATE,
  pickPayload<{ updatedData: NormalizedResponse }>(['updatedData']),
);

export const dataDelete = actionCreator(
  constants.DATA_DELETE,
  pickPayload<{ kind: string; ids: string[] }>(['kind', 'ids']),
);

export const metaRemoveBatchOfEndpoints = actionCreator((endpoints: string[]) => ({
  type: constants.META_REMOVE_BATCH_OF_ENDPOINTS,
  endpoints,
}));

export const removeMetaFieldsOfEndpoint = actionCreator((endpoint: string, field: string) => ({
  type: constants.META_REMOVE_FIELDS_BATCH_OF_ENDPOINTS,
  endpoint,
  field,
}));

export const dataClearRelationship = actionCreator(
  constants.DATA_CLEAR_RELATIONSHIP,
  pickPayload<{
    kind: string;
    ids: string[];
    relationship: string;
    isPlural: boolean;
  }>(['kind', 'ids', 'relationship', 'isPlural']),
);

export const dataRemoveRelationship = actionCreator(
  constants.DATA_REMOVE_RELATIONSHIP,
  pickPayload<{
    kind: string;
    ids: string[];
    ownerId: string;
    relationship: string;
  }>(['kind', 'ids', 'ownerId', 'relationship']),
);

export const dataUpdateItems = actionCreator(
  constants.DATA_UPDATE_ITEMS,
  pickPayload<{
    kind: string;
    ids: (string | number)[];
    data: any;
  }>(['kind', 'ids', 'data']),
);
