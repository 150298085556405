import type { RcFile } from 'antd/lib/upload';
import format from 'date-fns/format';
import normalize from 'json-api-normalizer';

import { PROVIDER_PROFILE_INCLUSIONS } from 'constants/profile';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import isPresent from 'utils/isPresent';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderPersonalInformation } from '../actions';
import { UPDATE_PROVIDER_PERSONAL_INFORMATION } from '../constants';
import { updateProviderPersonalInformationEndpoint } from '../endpoints';

const updateProviderPersonalInformationOperation = createLogic<typeof updateProviderPersonalInformation>({
  type: UPDATE_PROVIDER_PERSONAL_INFORMATION,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.DASHBOARD.PROVIDER.PERSONAL_INFORMATION.PATH;

    const { endpoint, url } = updateProviderPersonalInformationEndpoint;

    const formData = new FormData();
    formData.append('about_me', action.values.about || '');
    formData.append('date_of_birth', format(action.values.birthDate as Date, 'yyyy-MM-dd'));
    formData.append('ethnicity', action.values.ethnicity || '');
    formData.append('fellowship', action.values.fellowship || '');
    formData.append('focus', action.values.focus || '');
    formData.append('further_professional_interests', action.values.furtherProfessionalInterests || '');
    formData.append('gender_identity', action.values.genderIdentity || '');
    formData.append('goals', action.values.goals || '');
    formData.append('graduate_school', action.values.graduateSchool || '');
    formData.append('hobbies', action.values.hobbies || '');
    formData.append('include', PROVIDER_PROFILE_INCLUSIONS.CONTACT_INFORMATION);
    formData.append('internship', action.values.internship || '');
    formData.append('marital_status', action.values.maritalStatus || '');
    formData.append('medical_education', action.values.collegeUniversitySchool || '');
    formData.append('published_research', action.values.publishedResearch || '');
    formData.append('race', action.values.race || '');
    formData.append('residency', action.values.residency || '');
    formData.append('sex', action.values.sex || '');
    formData.append('timezone', action.values.timeZone || '');
    formData.append('undergraduate_school_and_major', action.values.undergraduateSchool || '');
    formData.append('volunteer_work', action.values.volunteerWork || '');

    if (Array.isArray(action.values.languages)) {
      if (isPresent(action.values.languages)) {
        action.values.languages.forEach((lang) => {
          formData.append('languages[]', lang);
        });
      } else {
        formData.append('languages', '');
      }
    }

    if (Array.isArray(action.values.attachments)) {
      action.values.attachments.forEach((attachment) => {
        if (attachment.fromBackend !== true) {
          formData.append('profile_attachments[]', attachment as unknown as RcFile);
        }
      });
    }

    if (action.values.avatarFile) {
      formData.append('avatar', action.values.avatarFile as File);
    }

    if (Array.isArray(action.values.deletedAttachmentsIds)) {
      action.values.deletedAttachmentsIds.forEach((id) => {
        formData.append('delete_attachments[]', id);
      });
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);

      dispatch(showMessage({ messageText: { id: 'account.personalInformationUpdatedSuccessfully' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updateProviderPersonalInformationOperation;
