import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { reschedulePatientAppointment } from '../actions';
import { RESCHEDULE_PATIENT_APPOINTMENT } from '../constants';
import { reschedulePatientAppointmentByIdEndpoint } from '../endpoints';

const reschedulePatientAppointmentOperation = createLogic<typeof reschedulePatientAppointment>({
  type: RESCHEDULE_PATIENT_APPOINTMENT,
  latest: true,

  async process({ action }, dispatch, done) {
    const { startTime, appointmentId } = action.values;
    const { endpoint, url } = reschedulePatientAppointmentByIdEndpoint(appointmentId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.post(url, { start_time: startTime });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());

      dispatch(showMessage({ messageText: { id: 'shared.reschedule.success.message' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default reschedulePatientAppointmentOperation;
