import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import actionCreator from 'utils/state/actionCreator';
import type { WorkingHour } from 'views/shared/WorkingHourField/types';

import * as constants from './constants';

export const setSelectedMonth = actionCreator((date: Date) => ({
  type: constants.SET_SELECTED_DATE,
  date,
}));

export const setCalendarBoundaries = actionCreator(
  ({ min, current, max }: { min: Date; current: Date; max: Date }) => ({
    type: constants.SET_CALENDAR_BOUNDARIES,
    min,
    current,
    max,
  }),
);

export const setWorkingBrakes = actionCreator((workingBrakes: Record<string, any>) => ({
  type: constants.SET_WORKING_BRAKES,
  workingBrakes,
}));

export const resetToWeeklyHours = actionCreator((date: Date) => ({
  type: constants.RESET_TO_WEEKLY_HOURS,
  date,
}));

export const setWorkingHours = actionCreator((workingHours: Record<string, any[]>) => ({
  type: constants.SET_WORKING_HOURS,
  workingHours,
}));

export const fetchProvideCalendar = actionCreator(({ start, end }: { start?: string; end?: string } = {}) => ({
  type: constants.FETCH_PROVIDER_CALENDAR,
  start,
  end,
}));

export const createWorkingBreak = makeFormSubmitAction<{
  start: Date | null;
  end: Date | null;
}>()(constants.CREATE_WORKING_BREAK);

export const createSingleDateAvailability = makeFormSubmitAction<{
  date: Date | null;
  hours: {
    [weekday: string]: WorkingHour[] | null;
  };
}>()(constants.CREATE_SINGLE_DATE_AVAILABILITY);
