export const PATIENT_PROFILE_INCLUSIONS = {
  CONTACT_INFORMATION: 'contact_information',
  HEALTH_PROFILE: 'health_profile',
};

export const PROVIDER_PROFILE_INCLUSIONS = {
  BOARD_LICENSES: 'board_licenses',
  CANCELLATION_POLICY: 'cancellation_policy',
  CONTACT_INFORMATION: 'contact_information',
  PERSONAL_INFORMATION: 'personal_information',
  PROFILE_ATTACHMENTS: 'profile_attachments',
  USER_PROFILES_SPECIALTIES: 'user_profiles_specialities',
  SPECIALTIES: 'specialities',
  WORKING_HOURS: 'working_hours',
  PAYOUT_METHOD: 'payout_method',
};

export enum CalendarPageMode {
  'view' = 'view',
  'edit' = 'edit',
}
