import normalize from 'json-api-normalizer';
import { path } from 'ramda';

import type { UserRole } from 'constants/app';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/nextApiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import type { JsonApiErrorObject } from 'types/json-api/raw';
import { createRouteFromPathname } from 'utils/createRouteHelper';
import hasEmailIsAlreadyTakenError from 'utils/hasEmailIsAlreadyTakenError';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { userSignUp } from '../actions';
import { USER_SIGN_UP } from '../constants';
import { userSignUpEndpoint } from '../endpoints';

const userSignUpOperation = createLogic<typeof userSignUp>({
  type: USER_SIGN_UP,
  latest: true,

  async process({ action }, dispatch, done) {
    const { values, form } = action;

    const { endpoint, url } = userSignUpEndpoint(values.userRole);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const redirectToRoute = createRouteFromPathname({
        pathname: ROUTES.EMAIL_VERIFICATION.PATH,
        pathChunkName: 'role',
        pathChunkReplacer: values.userRole,
      });

      const body = {
        email: values.email,
        password: values.password,
        password_confirmation: values.password,
        notifications_turn_on: values.notificationsTurnOn,
        redirect_to: redirectToRoute,
      };

      const { data } = await httpClient.post(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ response, endpoint }));

      const redirectTo = createRouteFromPathname({
        pathname: ROUTES.VERIFY_EMAIL.PATH,
        pathChunkName: 'role',
        pathChunkReplacer: values.userRole as UserRole,
      });
      redirect(redirectTo);
    } catch (error) {
      const errors = path<JsonApiErrorObject[]>(['response', 'data', 'errors'], error) || [];

      const isEmailAlreadyTakenError = hasEmailIsAlreadyTakenError(errors);

      if (isEmailAlreadyTakenError) {
        form.setStatus({ id: 'shared.emailAddressAlreadyRegistered' });
      }

      if (!isEmailAlreadyTakenError) {
        showErrorNotifications({ error, dispatch })();
      }

      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });
    }

    done();
  },
});

export default userSignUpOperation;
