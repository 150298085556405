import { HYDRATE } from 'next-redux-wrapper';
import type { Action, Reducer } from 'redux';

export type HydrateAction<S> = {
  type: typeof HYDRATE;
  payload: S;
};

const hydrateReducer =
  <S, A extends Action | HydrateAction<S>>(reducer: Reducer<S, A>) =>
  (state: S | undefined, action: A) => {
    if (action.type === HYDRATE && 'payload' in action) {
      const nextState = { ...state, ...action.payload };
      return reducer(nextState, action);
    }
    return reducer(state, action);
  };

export default hydrateReducer;
