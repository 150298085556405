import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { setProviderPatientProfileIds, type fetchProviderPatientProfiles, setPatientsListTotal } from '../actions';
import { FETCH_PROVIDER_PATIENT_PROFILES, PATIENT_LIST_LENGTH } from '../constants';
import { fetchProviderPatientProfilesEndpoint } from '../endpoints';
import { patientsPageNumberSelector, searchSelector, sortParamsSelector } from '../selectors';

const fetchProviderPatientProfilesOperation = createLogic<typeof fetchProviderPatientProfiles>({
  type: FETCH_PROVIDER_PATIENT_PROFILES,
  latest: true,

  async process({ getState }, dispatch, done) {
    const { endpoint, url } = fetchProviderPatientProfilesEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const state = getState();

      const params = {
        search: searchSelector(state),
        sort: sortParamsSelector(state),
        page: { number: patientsPageNumberSelector(state), size: PATIENT_LIST_LENGTH },
      };

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setProviderPatientProfileIds(data.data.map(({ id }: any) => id)));
      dispatch(setPatientsListTotal(data.meta.total));
    } catch (error) {
      dispatch(setProviderPatientProfileIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderPatientProfilesOperation;
