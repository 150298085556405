export const APP_NAME = 'airPMD';
export const SENTRY_ENV = process.env.NODE_ENV;
export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || 'https://stagingapi.airpmd.com';
// export const API_HOST = process.env.NEXT_PUBLIC_API_HOST || 'http://localhost:3000';
export const APP_HOST = process.env.NEXT_PUBLIC_APP_HOST || 'http://localhost:4000';

export const CABLE_HOST = process.env.CABLE_HOST || 'ws://localhost:3000/cable';

// eslint-disable-next-line prefer-destructuring
export const SENTRY_DSN = process.env.SENTRY_DSN;
export const IS_SENTRY_DISABLED = SENTRY_ENV !== 'production';

// eslint-disable-next-line prefer-destructuring
export const RELEASE_TAG = process.env.RELEASE_TAG;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 72;
export const MAX_EMAIL_LENGTH = 255;
export const MAX_ZIP_LENGTH = 10;
export const MAX_PHONE_LENGTH = 16;
export const MIN_PHONE_LENGTH = 8;
export const MAX_WORKING_HOURS_LENGTH = 12;
export const MAX_STATE_LICENSE_LENGTH = 50;

export const PASSWORDS_SHOULD_NOT_MATCH_ERROR = { id: 'shared.passwordsShouldNotMatch' };

export const MAX_INFO_LENGTHS = {
  SMALL: 72,
  MEDIUM: 255,
  LARGE: 1000,
};
export const MIN_INFO_LENGTHS = {
  SMALL: 3,
  MEDIUM: 5,
  LARGE: 15,
};

export const MAX_CANCELLATION_SCENARIO_PERCENT = 100;
export const MIN_CANCELLATION_SCENARIO_PERCENT = 0;
export const BYTES_INCLUDE_IN_MB = 1024 * 1024;
export const MAX_PHOTO_SIZE = 10;
export const MAX_ATTACHMENT_SIZE = 20;
export const IMAGE_TYPES = ['image/png', 'image/jpg', 'image/jpeg'];
export const AVATAR_BOUNDARIES = {
  TYPES: IMAGE_TYPES,
  MAX_SIZE: MAX_PHOTO_SIZE * BYTES_INCLUDE_IN_MB, // 10 Mb
};
export const CHAT_ATTACHMENT_BOUNDARIES = {
  TYPES: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
    ...IMAGE_TYPES,
    'video/mp4',
  ],
  MAX_SIZE: MAX_ATTACHMENT_SIZE * BYTES_INCLUDE_IN_MB, // 20 Mb
  MAX_COUNT: 1,
};

export const ATTACHMENT_BOUNDARIES = {
  TYPES: [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/pdf',
  ],
  MAX_SIZE: 20971520, // 20 Mb
  MAX_COUNT: 10,
};

export const ALERT_KINDS = {
  success: 'success',
  error: 'error',
  warning: 'warning',
};

export const SOCIAL_LINKS = {
  INSTAGRAM: 'https://www.instagram.com/',
  FACEBOOK: 'https://www.facebook.com/',
  TWITTER: 'https://www.twitter.com/',
};

export enum UserRole {
  'patient' = 'patient',
  'provider' = 'provider',
}

export enum UserAccountType {
  'patient' = 'patient_account',
  'provider' = 'user_account',
}

export enum FormikInitialStatus {
  'filled' = 'filled',
  'empty' = 'empty',
}

export enum EmailVerificationType {
  emailConfirm = 'emailConfirm',
  emailUpdate = 'emailUpdate',
}

export enum SkeletonType {
  agenda = 'agenda',
  textSmall = 'textSmall',
  textLarge = 'textLarge',
  avatarSmall = 'avatarSmall',
  avatarLarge = 'avatarLarge',
  assessment = 'assessment',
  assessmentCard = 'assessmentCard',
}

// 5 min
export const EMAIL_RESEND_LINK_TIMER = 1000 * 60 * 5;

export const EMAIL_REGEX = /^[a-zA-Z0-9._%!+-]+@([a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63})$/i;

export const WEEK_DAY = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
};

export const SEX_INTL_NAMES = {
  male: { id: 'shared.male' },
  female: { id: 'shared.female' },
  other: { id: 'shared.other' },
};

export const SEX = Object.entries(SEX_INTL_NAMES).map(([key, name]) => ({ value: key, name }));

export const USA_STATES = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'Florida',
  'Georgia',
  'Hawaii',
  'Idaho',
  'IllinoisIndiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'MontanaNebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'PennsylvaniaRhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const USA_STATE_OPTIONS = USA_STATES.map((item) => ({
  value: item,
  name: item,
}));

export const MEDICAL_DEGREE_OPTIONS = [
  { value: 'D.O.', name: 'D.O' },
  { value: 'M.D.', name: 'M.D' },
];

export const MEDICAL_TITLE_OPTIONS = [
  { value: 'Medical Doctor', name: 'Medical Doctor' },
  { value: 'Licensed Clinical Social Worker (LCSW)', name: 'Licensed Clinical Social Worker (LCSW)' },
  { value: 'Therapist', name: 'Therapist' },
  { value: 'Counselor', name: 'Counselor' },
  { value: 'Nutritionist', name: 'Nutritionist' },
  { value: 'Dietician', name: 'Dietician' },
  { value: 'Health Coach', name: 'Health Coach' },
  { value: 'Chiropractor', name: 'Chiropractor' },
];

export const HOURS_OPTIONS = (() => {
  const arrayOfHours = Array.from(Array(13).keys());

  arrayOfHours.shift();

  const hours = arrayOfHours
    .map((item) => {
      const hour = `${item}`.length === 1 ? `0${item}` : `${item}`;

      return [`${hour}:00`, `${hour}:15`, `${hour}:30`, `${hour}:45`];
    })
    .reduce((acc, item) => {
      return acc.concat(item);
    }, [])
    .map((item) => ({
      value: item,
      label: item,
    }));

  return hours;
})();

export const HELLO_EMAIL = 'hello@airpmd.com';
export const SUPPORT_EMAIL = 'support@airpmd.com';
export const PRIVACY_EMAIL = 'privacy@airpmd.com';
export const CONSUMER_PRIVACY_EMAIL = 'consumer-privacy@airpmd.com';

export const ADDRESS = '[address]';
export const PHONE = '[phone_number]';
export const FAX = '[fax]';

export const PHONE_NUMBER_COUNTRIES = {
  PROVIDER: ['us'],
  PATIENT: [
    'us',
    'ag',
    'bb',
    'bs',
    'bz',
    'ca',
    'cr',
    'cu',
    'dm',
    'do',
    'gd',
    'gt',
    'hn',
    'ht',
    'jm',
    'kn',
    'lc',
    'mx',
    'ni',
    'pa',
    'sv',
    'tt',
    'vc',
  ],
};

export const LANGUAGES = [
  'English',
  'Spanish',
  'Arabic',
  'French',
  'Portuguese',
  'Chinese',
  'German',
  'Korean',
  'Vietnamese',
  'Tagalog (Filipino)',
];

export const LANGUAGES_OPTIONS = LANGUAGES.map((item) => ({
  value: item,
  name: item,
}));

export const PROVIDER_BOARD_LICENSES_MAX_COUNT = 5;
export const PROVIDER_STATE_LICENSES_MAX_COUNT = 15;

export const BOTH_NUMBERS_AND_LETTERS_REGEX = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/;

export const ONLY_NUMBERS_REGEX = /^\d+$/;
