import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'modal' as const;

export const HIDE_MODAL = createActionType(CONCEPT_NAME, 'HIDE_MODAL');
export const SHOW_MODAL = createActionType(CONCEPT_NAME, 'SHOW_MODAL');

export const initialState = {
  modalType: null,
  modalProps: null,
};
