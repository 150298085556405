import {
  chatAttachmentRoute,
  chatAttachmentsRoute,
  chatMessagesMessageRoute,
  chatMessagesRoute,
  chatsMessagesReadRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import {
  CREATE_CHAT_MESSAGE,
  FETCH_CHAT_MESSAGES,
  DELETE_CHAT_MESSAGE,
  UPDATE_CHAT_MESSAGE,
  FETCH_CHAT_ATTACHMENTS,
  DELETE_CHAT_ATTACHMENT,
  MARK_MESSAGES_AS_READ,
} from './constants';

export const fetchChatMessagesEndpoint = (chatId: string) =>
  endpoint(FETCH_CHAT_MESSAGES, 'GET', chatMessagesRoute(chatId));
export const createChatMessageEndpoint = (chatId: string) =>
  endpoint(CREATE_CHAT_MESSAGE, 'POST', chatMessagesRoute(chatId));
export const updateChatMessageEndpoint = (chatId: string, messageId: string) =>
  endpoint(UPDATE_CHAT_MESSAGE, 'PUT', chatMessagesMessageRoute(chatId, messageId));
export const deleteChatMessageEndpoint = (chatId: string, messageId: string) =>
  endpoint(DELETE_CHAT_MESSAGE, 'DELETE', chatMessagesMessageRoute(chatId, messageId));
export const deleteChatAttachmentEndpoint = (chatId: string, messageId: string) =>
  endpoint(DELETE_CHAT_ATTACHMENT, 'DELETE', chatAttachmentRoute(chatId, messageId));
export const fetchChatAttachmentsEndpoint = (chatId: string) =>
  endpoint(FETCH_CHAT_ATTACHMENTS, 'GET', chatAttachmentsRoute(chatId));
export const markChatMessagesAsReadEndpoint = (chatId: string) =>
  endpoint(MARK_MESSAGES_AS_READ, 'PUT', chatsMessagesReadRoute(chatId));
