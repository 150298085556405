import { evolve, mergeDeepLeft, omit, dissocPath, mergeDeepRight } from 'ramda';
import type { Reducer } from 'redux';

import updateDataHelper from 'utils/updateDataHelper';

import * as constants from './constants';
import type { DataActions, DataState } from './types';

const initialState: DataState = {
  meta: {},
};

const dataReducer: Reducer<DataState, DataActions> = (state = initialState, action): DataState => {
  switch (action.type) {
    case constants.DATA_API_REQUEST:
      return mergeDeepLeft({ meta: { [action.endpoint]: { loading: true, status: null } } }, state);
    case constants.DATA_API_SUCCESS: {
      let newState: any = { ...state };

      if (typeof action.response !== 'undefined') {
        newState = mergeDeepLeft(action.response, newState);
      }

      if (typeof action.endpoint !== 'undefined') {
        newState = mergeDeepLeft({ meta: { [action.endpoint]: { loading: false } } }, newState);
      }

      return newState;
    }
    case constants.META_REMOVE_BATCH_OF_ENDPOINTS: {
      let newState: any = { ...state };

      for (let i = 0; i < action.endpoints.length; i += 1) {
        const endpoint = action.endpoints[i];

        newState = dissocPath(['meta', endpoint], newState);
      }

      return newState;
    }
    case constants.META_REMOVE_FIELDS_BATCH_OF_ENDPOINTS: {
      return evolve(
        {
          meta: {
            [action.endpoint]: {
              meta: omit([action.field]),
            },
          },
        },
        state,
      );
    }
    case constants.DATA_UPDATE:
      return mergeDeepRight(state, action.updatedData) as any;

    case constants.DATA_API_FAILURE: {
      let newState: any = mergeDeepLeft({ meta: { [action.endpoint]: { loading: false, status: null } } }, state);

      if (typeof action.status !== 'undefined') {
        newState = mergeDeepLeft({ meta: { [action.endpoint]: { status: action.status } } }, newState);
      }

      return newState;
    }
    case constants.DATA_DELETE: {
      return evolve({ [action.kind]: omit(action.ids) }, state);
    }
    case constants.DATA_UPDATE_ITEMS: {
      return mergeDeepLeft(updateDataHelper(state, action.kind, action.ids, action.data), state);
    }
    default:
      return state;
  }
};

export default dataReducer;
