import normalize from 'json-api-normalizer';

import { ASSESSMENT_PAGE_LENGTH } from 'constants/assessments';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Assessment } from 'types/entities/assessments';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientAssessments } from '../actions';
import { setAssessmentIds, setAssessmentsListTotal } from '../actions';
import { FETCH_PATIENT_ASSESSMENTS } from '../constants';
import { fetchPatientAssessmentsEndpoint } from '../endpoints';
import { assessmentsPageNumberSelector } from '../selectors';

const fetchPatientAssessmentsOperation = createLogic<typeof fetchPatientAssessments>({
  type: FETCH_PATIENT_ASSESSMENTS,
  latest: true,

  async process({ getState }, dispatch, done) {
    const { endpoint, url } = fetchPatientAssessmentsEndpoint;
    const pageNumber = assessmentsPageNumberSelector(getState());

    try {
      const params = {
        page: {
          number: pageNumber,
          size: ASSESSMENT_PAGE_LENGTH,
        },
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(setAssessmentIds(data.data.map(({ id }: Assessment) => id)));
      dispatch(setAssessmentsListTotal(data.meta.total));
      dispatch(dataApiSuccess({ response, endpoint }));
    } catch (error) {
      dispatch(setAssessmentIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchPatientAssessmentsOperation;
