export const PROVIDERS_SEARCH_PAGE_LENGTH = 9;
export const PROVIDERS_SEARCH_PRICE_RANGE_MIN = 0;
export const PROVIDERS_SEARCH_PRICE_RANGE_MAX = 100;
export const PROVIDERS_SEARCH_PRICE_RANGE_STEP = 10;
export const PROVIDERS_SEARCH_INPUT_SYMBOLS_MIN = 3;
export const FAMILY_MEDICINE_PROVIDERS_APPOINTMENT_IN_MINUTES = 15;

export enum ProvidersSearchSortTypes {
  relevance = 'user_profiles.first_name',
  consultationPriceAsc = 'user_profiles.consultation_price_cents',
  consultationPriceDesc = '-user_profiles.consultation_price_cents',
}

export const PROVIDERS_SEARCH_SORT_OPTIONS = [
  {
    value: ProvidersSearchSortTypes.relevance,
    name: { id: 'shared.relevance' },
  },
  {
    value: ProvidersSearchSortTypes.consultationPriceDesc,
    name: { id: 'shared.priceHighToLow' },
  },
  {
    value: ProvidersSearchSortTypes.consultationPriceAsc,
    name: { id: 'shared.priceLowToHigh' },
  },
];

export const PROVIDERS_SEARCH_INPUT_OPTIONS_ORDER = ['speciality', 'symptom', 'user_profile'];

export const PROVIDERS_SEARCH_INPUT_OPTION_GROUPS = {
  speciality: { id: 'shared.speciality' },
  symptom: { id: 'shared.symptom' },
  user_profile: { id: 'shared.doctors' },
};

export enum AppointmentStatuses {
  generalInfo = 'general_info',
  questionnaire = 'questionnaire',
  payment = 'payment',
  waitingForPaymentConfirmation = 'waiting_for_payment_confirmation',
  scheduled = 'scheduled',
  rescheduled = 'rescheduled',
  canceled = 'canceled',
  passed = 'passed',
  inProgress = 'in_progress',
  assessment = 'assessment',
}

export const appointmentProviderFilterStatuses = [
  AppointmentStatuses.scheduled,
  AppointmentStatuses.rescheduled,
  AppointmentStatuses.inProgress,
  AppointmentStatuses.canceled,
  AppointmentStatuses.passed,
];

export const appointmentAllowedStatusesForCancellation = [
  AppointmentStatuses.waitingForPaymentConfirmation,
  AppointmentStatuses.scheduled,
  AppointmentStatuses.rescheduled,
];

export enum InfoState {
  idle = 'idle',
  edit = 'edit',
  preview = 'preview',
}

export const PATIENT_DOCTORS_SEARCH_FILTERS_INITIAL_VALUES = {
  unconfirmed: {
    date: undefined,
    speciality: [],
    sex: [],
    price: [PROVIDERS_SEARCH_PRICE_RANGE_MIN, PROVIDERS_SEARCH_PRICE_RANGE_MAX],
  },
  confirmed: {
    date: undefined,
    speciality: [],
    sex: [],
    price: [PROVIDERS_SEARCH_PRICE_RANGE_MIN, PROVIDERS_SEARCH_PRICE_RANGE_MAX],
  },
  search: undefined,
  state: undefined,
  sort: ProvidersSearchSortTypes.relevance,
  page: 1,
};
