import { CABLE_HOST } from 'constants/app';
import { WS_CHANNELS } from 'constants/webSockets';
import { webSocketsAuthenticateRoute } from 'lib/apiRoutes';
import httpClient from 'lib/clients/proxyClient';
import createLogic from 'utils/state/createLogic';
import { parseData, parseIdentifier } from 'utils/webSockets';

import type { openConnection } from '../actions';
import { receiveData, resetSubscriptionChannels, setConnectionStatus } from '../actions';
import { CLOSE_CONNECTION, OPEN_CONNECTION, SEND_COMMAND } from '../constants';

const openConnectionOperation = createLogic<typeof openConnection>({
  type: OPEN_CONNECTION,
  latest: true,
  warnTimeout: 0,

  async process({ action$ }, dispatch, done) {
    let socket: WebSocket | undefined;

    try {
      const {
        data: {
          meta: { token },
        },
      } = await httpClient.post(webSocketsAuthenticateRoute);
      socket = new WebSocket(`${CABLE_HOST}?token=${token}`);

      socket.onopen = () => {
        dispatch(setConnectionStatus('opened'));
      };

      socket.onclose = () => {
        dispatch(setConnectionStatus('closed'));
        dispatch(resetSubscriptionChannels());
        done();
      };

      socket.onmessage = ({ data }) => {
        const identifier = parseIdentifier(data);
        const { message } = parseData(data);
        const { channel } = parseData(identifier);
        if (channel === WS_CHANNELS.CHAT && message) {
          dispatch(receiveData(message));
        }
      };

      action$.subscribe((action: any) => {
        if (action.type === SEND_COMMAND && socket && socket.readyState === 1) {
          socket.send(action.channelConfig);
        }

        if (action.type === CLOSE_CONNECTION && socket && socket.readyState >= 1) {
          socket.close();
        }
      });
    } catch (error) {
      dispatch(setConnectionStatus('closed'));
      dispatch(resetSubscriptionChannels());
      socket?.close();
      done();
    }
  },
});

export default openConnectionOperation;
