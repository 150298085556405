import { useSelector } from 'react-redux';

import { errorStatusCodeSelector } from 'state/concepts/errors/selectors';

const useContainer = () => {
  const errorStatus = useSelector(errorStatusCodeSelector);

  return { errorStatus };
};

export default useContainer;
