import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'session';

export const USER_LOGIN = createActionType(CONCEPT_NAME, 'USER_LOGIN');
export const USER_SIGN_UP = createActionType(CONCEPT_NAME, 'USER_SIGN_UP');
export const USER_SIGN_IN = createActionType(CONCEPT_NAME, 'USER_SIGN_IN');
export const USER_LOGIN_SUCCESS = createActionType(CONCEPT_NAME, 'USER_LOGIN_SUCCESS');
export const USER_LOGOUT = createActionType(CONCEPT_NAME, 'USER_LOGOUT');
export const USER_LOGOUT_SUCCESS = createActionType(CONCEPT_NAME, 'USER_LOGOUT_SUCCESS');
export const RESEND_VERIFICATION_LINK = createActionType(CONCEPT_NAME, 'RESEND_VERIFICATION_LINK');
export const CHECK_EMAIL_TOKEN_CORRECTNESS = createActionType(CONCEPT_NAME, 'CHECK_EMAIL_TOKEN_CORRECTNESS');
export const SET_EMAIL_VERIFICATION_STATUS = createActionType(CONCEPT_NAME, 'SET_EMAIL_VERIFICATION_STATUS');
export const SET_PATIENT_PROFILE_ID = createActionType(CONCEPT_NAME, 'SET_PATIENT_PROFILE_ID');
export const SET_PROVIDER_PROFILE_ID = createActionType(CONCEPT_NAME, 'SET_PROVIDER_PROFILE_ID');
export const SEND_PASSWORD_RECOVERY_LINK = createActionType(CONCEPT_NAME, 'SEND_PASSWORD_RECOVERY_LINK');
export const SAVE_NEW_PASSWORD = createActionType(CONCEPT_NAME, 'SAVE_NEW_PASSWORD');
export const CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS = createActionType(
  CONCEPT_NAME,
  'CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS',
);
export const SET_PASSWORD_RECOVERY_STATUS = createActionType(CONCEPT_NAME, 'SET_PASSWORD_RECOVERY_STATUS');
