import axios from 'axios';

import { APP_HOST } from 'constants/app';

import paramsSerializer from './paramsSerializer';

const nextApiClient = axios.create({
  baseURL: `${APP_HOST}/api`,
  paramsSerializer,
});

export default nextApiClient;
