import actionCreator from 'utils/state/actionCreator';

import * as constants from './constants';
import type { SortingKeyTypes, AppointmentsSortingKeyTypes, AssessmentsSortingKeyTypes } from './types';

export const fetchProviderPatientProfiles = actionCreator(() => ({
  type: constants.FETCH_PROVIDER_PATIENT_PROFILES,
}));

export const setProviderPatientProfileIds = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_IDS,
  ids,
}));

export const setSortOrder = (sortKey: SortingKeyTypes) => ({
  type: constants.SET_PATIENT_SORT_ORDER,
  sortKey,
});

export const setCurrentPatientsPage = actionCreator((page: number) => ({
  type: constants.SET_CURRENT_PATIENTS_PAGE,
  page,
}));

export const setSearch = (search = '') => ({
  type: constants.SET_PATIENT_SEARCH,
  search,
});

export const setPatientsListTotal = actionCreator((total: number) => ({
  type: constants.SET_PATIENTS_LIST_TOTAL,
  total,
}));

export const fetchProviderPatientProfileById = actionCreator((patientId: string) => ({
  type: constants.FETCH_PROVIDER_PATIENT_PROFILE_BY_ID,
  patientId,
}));

export const fetchProviderPatientProfileAssessmentById = actionCreator((patientId: string, assessmentId: string) => ({
  type: constants.FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID,
  patientId,
  assessmentId,
}));

// Appointments
export const fetchProviderPatientProfileAppointmentsById = actionCreator((patientId: string) => ({
  type: constants.FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID,
  patientId,
}));

export const setProviderPatientProfileAppointmentsIds = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_IDS,
  ids,
}));

export const setProviderPatientProfileCurrentAppointmentsPage = actionCreator((page: number) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_CURRENT_APPOINTMENTS_PAGE,
  page,
}));

export const setProviderPatientProfileAppointmentsListTotal = actionCreator((total: number) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_LIST_TOTAL,
  total,
}));

export const setProviderPatientProfileAppointmentsSearch = actionCreator((search = '') => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SEARCH,
  search,
}));

export const setProviderPatientProfileAppointmentsSortOrder = (sortKey: AppointmentsSortingKeyTypes) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SORT_ORDER,
  sortKey,
});
// Assessments
export const fetchProviderPatientProfileAssessmentsById = actionCreator((patientId: string) => ({
  type: constants.FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID,
  patientId,
}));

export const setProviderPatientProfileAssessmentsIds = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_IDS,
  ids,
}));

export const setProviderPatientProfileCurrentAssessmentsPage = actionCreator((page: number) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_CURRENT_ASSESSMENTS_PAGE,
  page,
}));

export const setProviderPatientProfileAssessmentsListTotal = actionCreator((total: number) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_LIST_TOTAL,
  total,
}));

export const setProviderPatientProfileAssessmentsSearch = actionCreator((search = '') => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SEARCH,
  search,
}));

export const setProviderPatientProfileAssessmentsSortOrder = (sortKey: AssessmentsSortingKeyTypes) => ({
  type: constants.SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SORT_ORDER,
  sortKey,
});
