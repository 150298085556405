import normalize from 'json-api-normalizer';
import build from 'redux-object';

import httpClient from 'lib/clients/proxyClient';
import { fetchChatMessages } from 'state/concepts/chat/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Appointment } from 'types/entities/appointment';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderAppointmentByIdWithChat } from '../actions';
import * as constants from '../constants';
import { fetchProviderAppointmentByIdWithChatEndpoint } from '../endpoints';

const fetchProviderAppointmentByIdOperation = createLogic<typeof fetchProviderAppointmentByIdWithChat>({
  type: constants.FETCH_PROVIDER_APPOINTMENT_BY_ID_WITH_CHAT,
  latest: true,

  async process({ action: { id } }, dispatch, done) {
    const { endpoint, url } = fetchProviderAppointmentByIdWithChatEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, {
        params: {
          include:
            'user_profile,speciality,patient_profile,symptom,chat,appointment_cancellation_reason,assessment,assessment.biopsy,assessment.blood_work,assessment.imaging,assessment.medical_equipment,assessment.prescription,assessment.sick_note,assessment.therapy,assessment.diagnosis,questionnaire,state_license',
        },
      });

      const response = normalize(data);

      const appointment = build<Appointment>(response, 'appointment', data.data.id);
      dispatch(fetchChatMessages({ chatId: appointment?.chat?.id as string }));
      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchProviderAppointmentByIdOperation;
