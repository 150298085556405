import utcToZonedTime from 'date-fns-tz/utcToZonedTime';
import prettyBytes from 'pretty-bytes';
import { findLastIndex, head, includes, path, propOr } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { IMAGE_TYPES } from 'constants/app';
import { SlideType } from 'constants/chat';
import { dataSelector } from 'state/data/selectors';
import type { RootState } from 'state/store/types';
import type { Message, MessageAttachment } from 'types/entities/chat';
import type { NormalizedResponseData } from 'types/json-api/normalized';
import { groupMessagesByDate, isUnreadMessage } from 'utils/chat';
import isPresent from 'utils/isPresent';

import { currentUserProfileSelector } from '../session/selectors';

import type { EditingMessageTypes } from './types';

export const messageIdsSelector = path(['chat', 'messageIds']);
export const uploadProgressSelector = path(['chat', 'uploadProgress']);
export const editingMessageSelector = path<EditingMessageTypes>(['chat', 'editingMessage']);
export const attachmentIdsSelector = path<string[]>(['chat', 'attachmentsIds']);
export const selectedAttachmentIdSelector = path<string | null>(['chat', 'selectedAttachmentId']);

export const messageSelector = createSelector(
  messageIdsSelector as any,
  dataSelector,
  (ids: string[], data: NormalizedResponseData) => (isPresent(ids) ? build<Message>(data, 'message', ids) : []),
);

export const messageByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => (isPresent(id) ? build<Message>(data, 'message', id) : null),
);

export const attachmentsSelector = createSelector(
  attachmentIdsSelector as any,
  dataSelector,
  (ids: string[], data: NormalizedResponseData) =>
    isPresent(ids) ? build<MessageAttachment>(data, 'messageAttachment', ids) : [],
);

export const selectedAttachmentSelector = createSelector(
  selectedAttachmentIdSelector,
  dataSelector,
  (id, data: NormalizedResponseData) =>
    isPresent(id) ? build<MessageAttachment>(data, 'messageAttachment', id as string) : null,
);

export const attachmentsSlidesSelector = createSelector(
  attachmentsSelector as any,
  (attachments: MessageAttachment[]) =>
    attachments.map((attachment: MessageAttachment) => ({
      id: attachment.id,
      type: includes(attachment.mimeType, IMAGE_TYPES) ? SlideType.image : SlideType.file,
      mimeType: attachment.mimeType,
      title: attachment.filename,
      size: prettyBytes(attachment.size),
      url: attachment.url,
    })),
);

export const groupedMessagesSelector = createSelector(messageSelector as any, (messages: Message[]) =>
  isPresent(messages) ? groupMessagesByDate(messages) : null,
);

export const lastReadMessageSelector = createSelector(
  (_: RootState, chatUserId: string) => chatUserId,
  messageSelector,
  (chatUserId, messages) => {
    const firstUnreadMessageIndex = findLastIndex(
      (message) => isUnreadMessage(message, chatUserId),
      messages as Message[],
    );

    if (firstUnreadMessageIndex === -1) {
      return undefined;
    }

    const lastReadMessageIndex = firstUnreadMessageIndex + 1;

    return messages[lastReadMessageIndex];
  },
);

export const unreadMessagesSelector = createSelector(
  messageSelector,
  currentUserProfileSelector,
  (messages, currentUserProfile) =>
    messages.filter((message) => isUnreadMessage(message as Message, currentUserProfile?.id)),
);

export const firstUnreadMessageSelector = createSelector(unreadMessagesSelector, (unreadMessages) =>
  head(unreadMessages),
);

export const lastMessageCreatedAtSelector = createSelector(firstUnreadMessageSelector, (unreadMessage) => {
  const offset: string | undefined = path(['currentUserTimezone', 'offset'], unreadMessage);
  const createdAt: string | null = propOr(null, 'createdAt', unreadMessage);

  if (!createdAt || !offset) return null;
  const createdAtIncludeOffset = utcToZonedTime(new Date(createdAt), offset);

  return createdAtIncludeOffset;
});
