import type { AssessmentsType } from 'constants/assessments';
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import actionCreator from 'utils/state/actionCreator';

import * as constants from './constants';

export const fetchPatientAssessments = actionCreator(() => ({
  type: constants.FETCH_PATIENT_ASSESSMENTS,
}));

export const setAssessmentIds = actionCreator((ids: string[]) => ({
  type: constants.SET_ASSESSMENT_IDS,
  ids,
}));

export const setAssessmentsListTotal = actionCreator((total: number) => ({
  type: constants.SET_ASSESSMENTS_LIST_TOTAL,
  total,
}));

export const setCurrentAssessmentsPage = actionCreator((page: number) => ({
  type: constants.SET_CURRENT_ASSESSMENTS_PAGE,
  page,
}));

export const fetchPatientAssessmentById = actionCreator((id: string) => ({
  type: constants.FETCH_PATIENT_ASSESSMENT_BY_ID,
  id,
}));

export const downloadPatientAssessmentById = actionCreator((id: string, assessmentOrder: string | undefined) => ({
  type: constants.DOWNLOAD_PATIENT_ASSESSMENT_BY_ID,
  id,
  assessmentOrder,
}));

export type ProviderCreateAssessmentValues = {
  isDraft: boolean;
  appointmentId: string;
  assessment: string;
  objective: string;
  subjective: string;
  plan: string;
  diagnosis: string;
  chosenCategories: AssessmentsType[];
  biopsy?: {
    describeLesionBeingBiopsied?: string;
    directionsForPatientPreparations?: string;
  };
  bloodWork?: {
    bloodWorkBeingOrdered?: string;
    directionsForPatientPreparations?: string;
  };
  imaging?: {
    imagingModality?: string;
    directionsForPatientPreparations?: string;
  };
  prescription?: {
    directionsForUse?: string;
    dosageForm?: string;
    doseStrength?: string;
    drugName?: string;
    numberOfRefills?: string;
    quantityPrescribed?: string;
  };
  medicalEquipment?: {
    directionsForPatientUse?: string;
    freeTextEquipmentNeeded?: string;
  };
  sickNote?: {
    sickNote?: string;
  };
  therapy?: {
    directionsForPatientPreparations?: string;
    lengthInTimesPerWeekPerMonth?: string;
    other?: string;
    therapyType?: string;
  };
};

export type ProviderUpdateAssessmentValues = {
  isDraft: boolean;
  assessmentId: string;
  assessment: string;
  objective: string;
  subjective: string;
  plan: string;
  diagnosis: string;
  chosenCategories: AssessmentsType[];
  biopsy?: {
    describeLesionBeingBiopsied?: string;
    directionsForPatientPreparations?: string;
  };
  bloodWork?: {
    bloodWorkBeingOrdered?: string;
    directionsForPatientPreparations?: string;
  };
  imaging?: {
    imagingModality?: string;
    directionsForPatientPreparations?: string;
  };
  prescription?: {
    directionsForUse?: string;
    dosageForm?: string;
    doseStrength?: string;
    drugName?: string;
    numberOfRefills?: string;
    quantityPrescribed?: string;
  };
  medicalEquipment?: {
    directionsForPatientUse?: string;
    freeTextEquipmentNeeded?: string;
  };
  sickNote?: {
    sickNote?: string;
  };
  therapy?: {
    directionsForPatientPreparations?: string;
    lengthInTimesPerWeekPerMonth?: string;
    other?: string;
    therapyType?: string;
  };
};

export const createAssessment = makeFormSubmitAction<ProviderCreateAssessmentValues>()(constants.CREATE_ASSESSMENT);
export const updateAssessment = makeFormSubmitAction<ProviderUpdateAssessmentValues>()(constants.UPDATE_ASSESSMENT);
export const fetchDiagnoses = actionCreator((search = '') => ({
  type: constants.FETCH_DIAGNOSES,
  search,
}));
export const setDiagnosesIds = actionCreator((ids: string[]) => ({
  type: constants.SET_DIAGNOSES_IDS,
  ids,
}));
