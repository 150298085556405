import format from 'date-fns/format';
import normalize from 'json-api-normalizer';
import { isNil, multiply, pluck } from 'ramda';

import { ProvidersSearchSortTypes, PROVIDERS_SEARCH_PAGE_LENGTH } from 'constants/bookAppointment';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { DoctorsListSearchOption } from 'types/entities/doctorsSearch';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProvidersSearchList, fetchInitialProvidersSearchList } from '../actions';
import { setProvidersSearchListIds, setProvidersSearchListTotal } from '../actions';
import { FETCH_INITIAL_PROVIDERS_SEARCH_LIST, FETCH_PROVIDERS_SEARCH_LIST } from '../constants';
import * as constants from '../constants';
import { fetchProvidersSearchListEndpoint } from '../endpoints';
import { providersSearchActiveOptionSelector } from '../selectors';

const fetchProvidersSearchListOperation = createLogic<
  typeof fetchInitialProvidersSearchList | typeof fetchProvidersSearchList
>({
  type: [FETCH_INITIAL_PROVIDERS_SEARCH_LIST, FETCH_PROVIDERS_SEARCH_LIST],
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { endpoint, url } = fetchProvidersSearchListEndpoint;

    const activeOption = providersSearchActiveOptionSelector(getState()) as DoctorsListSearchOption;

    const params: any = {
      sort: ProvidersSearchSortTypes.relevance,
      page: {
        number: 1,
        size: PROVIDERS_SEARCH_PAGE_LENGTH,
      },
      include: 'time_slots',
    };

    try {
      if (action.type === constants.FETCH_PROVIDERS_SEARCH_LIST) {
        const { state, confirmed, search, sort, page } = action.values;
        const { date, speciality, sex, price } = confirmed;

        params.filter = {};

        /**
         * Filters
         */
        if (typeof state === 'string') {
          params.filter.state = state;
        }

        if (date instanceof Date) {
          params.filter.date = format(date, 'yyyy-MM-dd');
        }

        if (Array.isArray(speciality)) {
          params.filter.speciality_ids = speciality;
        }

        if (Array.isArray(sex)) {
          params.filter.sex = sex;
        }

        if (Array.isArray(price) && price.length === 2) {
          params.filter.price_cents = {
            // use multiply 100 for convert integer part of price to cents
            min: multiply(price[0], 100),
            max: multiply(price[1], 100),
          };
        }

        if (!isNil(activeOption) && search === activeOption.id) {
          params.filter.search = {
            section_type: activeOption.sectionType,
            section_type_id: activeOption.sectionTypeId,
          };
        }

        /**
         * Sorting
         */
        if (!isNil(sort)) {
          params.sort = sort;
        }

        /**
         * Pagination
         */
        if (!isNil(page)) {
          params.page.number = page;
        }
      }

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      dispatch(setProvidersSearchListIds(pluck<'id', any>('id', data.data)));

      dispatch(setProvidersSearchListTotal(data.meta.total));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchProvidersSearchListOperation;
