import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'notification';

export const FETCH_NOTIFICATION_SETTINGS = createActionType(CONCEPT_NAME, 'FETCH_NOTIFICATION_SETTINGS');
export const SET_NOTIFICATION_SETTINGS_ID = createActionType(CONCEPT_NAME, 'SET_NOTIFICATION_SETTINGS_ID');
export const SET_UNREAD_NOTIFICATIONS_COUNT = createActionType(CONCEPT_NAME, 'SET_UNREAD_NOTIFICATIONS_COUNT');
export const DEC_UNREAD_NOTIFICATIONS_COUNT = createActionType(CONCEPT_NAME, 'DEC_UNREAD_NOTIFICATIONS_COUNT');
export const INC_UNREAD_NOTIFICATIONS_COUNT = createActionType(CONCEPT_NAME, 'INC_UNREAD_NOTIFICATIONS_COUNT');
export const SET_NOTIFICATIONS_HAS_MORE = createActionType(CONCEPT_NAME, 'SET_NOTIFICATIONS_HAS_MORE');
export const UPDATE_NOTIFICATION_SETTINGS = createActionType(CONCEPT_NAME, 'UPDATE_NOTIFICATION_SETTINGS');
export const SET_SIDEBAR_VISIBILITY = createActionType(CONCEPT_NAME, 'SET_SIDEBAR_VISIBILITY');
export const FETCH_NOTIFICATIONS = createActionType(CONCEPT_NAME, 'FETCH_NOTIFICATIONS');
export const SET_NOTIFICATIONS_IDS = createActionType(CONCEPT_NAME, 'SET_NOTIFICATIONS_IDS');
export const RECEIVE_NOTIFICATION = createActionType(CONCEPT_NAME, 'RECEIVE_NOTIFICATION');
export const READ_ALL_NOTIFICATIONS = createActionType(CONCEPT_NAME, 'READ_ALL_NOTIFICATIONS');
export const READ_NOTIFICATION = createActionType(CONCEPT_NAME, 'READ_NOTIFICATION');
export const UNREAD_NOTIFICATION = createActionType(CONCEPT_NAME, 'UNREAD_NOTIFICATION');
export const DELETE_NOTIFICATION = createActionType(CONCEPT_NAME, 'DELETE_NOTIFICATION');
export const DELETE_NOTIFICATION_FROM_PANEL = createActionType(CONCEPT_NAME, 'DELETE_NOTIFICATION_FROM_PANEL');
