import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateNotificationSettings } from '../actions';
import { UPDATE_NOTIFICATION_SETTINGS } from '../constants';
import { updateNotificationSettingsEndpoint } from '../endpoints';

const updateNotificationSettingsOperation = createLogic<typeof updateNotificationSettings>({
  type: UPDATE_NOTIFICATION_SETTINGS,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = updateNotificationSettingsEndpoint;

    const body = {
      email_appointment: action.settings.emailAppointment,
      email_communication: action.settings.emailCommunication,
      email_reminder: action.settings.emailReminder,
      in_app_appointment: action.settings.inAppAppointment,
      in_app_communication: action.settings.inAppCommunication,
      in_app_reminder: action.settings.inAppReminder,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      dispatch(showMessage({ messageText: { id: 'shared.changesHasBeenSaved' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updateNotificationSettingsOperation;
