import httpClient from 'lib/clients/proxyClient';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import createLogic from 'utils/state/createLogic';

import { setSocialLinkList } from '../actions';
import { FETCH_SOCIAL_LINK_LIST } from '../constants';
import { fetchSocialLinkListEndpoint } from '../endpoints';

const fetchSocialLinksOperation = createLogic({
  type: FETCH_SOCIAL_LINK_LIST,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = fetchSocialLinkListEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));
      const { data } = await httpClient.get(url, {});
      dispatch(setSocialLinkList(data.meta.social_links));
      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      dispatch(setSocialLinkList([]));
    }

    done();
  },
});

export default fetchSocialLinksOperation;
