import { StatusCodes } from 'http-status-codes';
import normalize from 'json-api-normalizer';

import apiClient from 'lib/clients/apiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import type { JsonApiError } from 'types/json-api/raw';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { checkEmailTokenCorrectness } from '../actions';
import { setEmailVerificationStatus } from '../actions';
import { CHECK_EMAIL_TOKEN_CORRECTNESS } from '../constants';
import { checkEmailCorrectnessEndpoint } from '../endpoints';

const checkEmailCorrectnessOperation = createLogic<typeof checkEmailTokenCorrectness>({
  type: CHECK_EMAIL_TOKEN_CORRECTNESS,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = checkEmailCorrectnessEndpoint;

    const body = {
      email_token: action.emailToken,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await apiClient.post(url, body);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      dispatch(setEmailVerificationStatus(StatusCodes.OK));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });

      const status = (error as JsonApiError).response?.status;

      const isSpecificError =
        status === StatusCodes.BAD_REQUEST || status === StatusCodes.FORBIDDEN || status === StatusCodes.GONE;

      if (isSpecificError) {
        dispatch(setEmailVerificationStatus(status));
      }

      if (!isSpecificError) {
        showErrorNotifications({ error, dispatch })();
      }
    }

    done();
  },
});

export default checkEmailCorrectnessOperation;
