import type { StatusCodes } from 'http-status-codes';

import type { UserRole } from 'constants/app';
import type { UserAccount } from 'types/entities/session';
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import { actionCreator } from 'utils/state';

import * as constants from './constants';

export const userLogin = makeFormSubmitAction<{
  email: string;
  password: string;
  rememberMe: boolean;
}>()(constants.USER_LOGIN);

export const userSignUp = makeFormSubmitAction<{
  email: string;
  password: string;
  userRole: string;
  notificationsTurnOn: boolean;
}>()(constants.USER_SIGN_UP);

export const userSignIn = makeFormSubmitAction<{
  email: string;
  password: string;
}>()(constants.USER_SIGN_IN);

export const userLoginSuccess = actionCreator((currentUser: UserAccount) => ({
  type: constants.USER_LOGIN_SUCCESS,
  currentUser,
}));

export const checkEmailTokenCorrectness = actionCreator((emailToken: string) => ({
  type: constants.CHECK_EMAIL_TOKEN_CORRECTNESS,
  emailToken,
}));

export const setEmailVerificationStatus = actionCreator((status: StatusCodes) => ({
  type: constants.SET_EMAIL_VERIFICATION_STATUS,
  status,
}));

export const userLogout = actionCreator(constants.USER_LOGOUT);

export const resendVerificationLink = actionCreator(
  ({ userRole, pathname }: { userRole?: UserRole; pathname?: string }) => ({
    type: constants.RESEND_VERIFICATION_LINK,
    pathname,
    userRole,
  }),
);

export const sendPasswordRecoveryLink = makeFormSubmitAction<{
  email: string;
}>()(constants.SEND_PASSWORD_RECOVERY_LINK);

export const saveNewPassword = makeFormSubmitAction<{
  password: string;
  token: string;
}>()(constants.SAVE_NEW_PASSWORD);

export const userLogoutSuccess = actionCreator(constants.USER_LOGOUT_SUCCESS);

export const setPatientProfileId = actionCreator((id: string) => ({
  type: constants.SET_PATIENT_PROFILE_ID,
  id,
}));

export const setProviderProfileId = actionCreator((id: string) => ({
  type: constants.SET_PROVIDER_PROFILE_ID,
  id,
}));

export const checkPasswordRecoveryTokenCorrectness = actionCreator((token: string | string[] | undefined) => ({
  type: constants.CHECK_PASSWORD_RECOVERY_TOKEN_CORRECTNESS,
  token,
}));

export const setPasswordRecoveryStatus = actionCreator((status: StatusCodes) => ({
  type: constants.SET_PASSWORD_RECOVERY_STATUS,
  status,
}));
