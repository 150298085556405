import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderContactInformation } from '../actions';
import { UPDATE_PROVIDER_CONTACT_INFORMATION } from '../constants';
import { updateProviderContactInformationEndpoint } from '../endpoints';

const updateProviderContactInformationOperation = createLogic<typeof updateProviderContactInformation>({
  type: UPDATE_PROVIDER_CONTACT_INFORMATION,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PROVIDER.MEDICAL_LICENCE.PATH;

    const { endpoint, url } = updateProviderContactInformationEndpoint;

    const body = {
      phone_number: action.values.phone,
      state: action.values.state,
      city: action.values.city,
      street_address: action.values.streetAddress,
      zip_code: action.values.zip,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updateProviderContactInformationOperation;
