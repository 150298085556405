import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { deleteProviderAvatar } from '../actions';
import { DELETE_PROVIDER_AVATAR } from '../constants';
import { deleteProviderAvatarEndpoint } from '../endpoints';

const deleteProviderAvatarOperation = createLogic<typeof deleteProviderAvatar>({
  type: DELETE_PROVIDER_AVATAR,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = deleteProviderAvatarEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.delete(url);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default deleteProviderAvatarOperation;
