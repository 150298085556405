import { combineReducers } from 'redux';

import * as constants from './constants';
import type { AssessmentActions } from './types';

export const assessmentIds = (state = [], action: AssessmentActions) => {
  switch (action.type) {
    case constants.SET_ASSESSMENT_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const assessmentsListTotal = (state: number | null = null, action: AssessmentActions) => {
  switch (action.type) {
    case constants.SET_ASSESSMENTS_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

export const assessmentsPageNumber = (state = 1, action: AssessmentActions) => {
  switch (action.type) {
    case constants.SET_CURRENT_ASSESSMENTS_PAGE:
      return action.page;
    default:
      return state;
  }
};

export const diagnosesIds = (state = [], action: AssessmentActions) => {
  switch (action.type) {
    case constants.SET_DIAGNOSES_IDS:
      return action.ids;
    default:
      return state;
  }
};

const assessmentsReducer = combineReducers({
  assessmentIds,
  assessmentsListTotal,
  assessmentsPageNumber,
  diagnosesIds,
});

export default assessmentsReducer;
