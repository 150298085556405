import snakeCase from 'lodash/snakeCase';
import { cond, equals, evolve, fromPairs, identity, isNil, map, pipe, T, toPairs, type } from 'ramda';

function unfold(fn: (value: any) => any): (value: any) => any {
  return cond([
    [isNil, identity],
    [Array.isArray, map((value) => unfold(fn)(value))],
    [pipe(type, equals('Object')), fn],
    [T, identity],
  ]);
}

function mapKeys(fn: (key: string) => string): (value: any) => Record<string, any> {
  return pipe(toPairs, map(evolve({ 0: fn, 1: unfold((value) => mapKeys(fn)(value)) })), fromPairs);
}

const mapKeysToSnakeCase = unfold(mapKeys(snakeCase));

export default mapKeysToSnakeCase;
