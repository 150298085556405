import type { ProvidersSearchSortTypes } from 'constants/bookAppointment';
import type { DoctorsListSearchOption } from 'types/entities/doctorsSearch';
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import actionCreator from 'utils/state/actionCreator';
import type { QuestionnaireFormTypes } from 'views/shared/QuestionnaireForm/types';

import * as constants from './constants';

export const fetchProvidersSearchOptions = actionCreator((search?: string) => ({
  type: constants.FETCH_PROVIDERS_SEARCH_OPTIONS,
  search,
}));

export const fetchInitialProvidersSearchList = actionCreator(constants.FETCH_INITIAL_PROVIDERS_SEARCH_LIST);

export const fetchProvidersSearchList = makeFormSubmitAction<{
  unconfirmed: {
    date: Date | undefined;
    speciality: string[] | undefined;
    sex: string[] | undefined;
    price: number[] | undefined;
  };
  confirmed: {
    date: Date | undefined;
    speciality: string[] | undefined;
    sex: string[] | undefined;
    price: number[] | undefined;
  };
  search: string | undefined;
  state: string | undefined;
  sort: ProvidersSearchSortTypes | undefined;
  page: number | undefined;
}>()(constants.FETCH_PROVIDERS_SEARCH_LIST);

interface NewAppointment {
  startTime: string | undefined;
  userProfileId: string | undefined;
}

export const createNewAppointmentForm = makeFormSubmitAction<NewAppointment>()(constants.CREATE_NEW_APPOINTMENT);

interface GeneralInfo {
  appointmentId: string;
  appointmentTime: string;
  userProfileId: string;
  specialityId: string;
  symptomId: string | undefined;
  phoneNumber: string;
  duration: string | undefined;
  consultationPrice: number;
  note: string | undefined;
  onSuccess: () => void;
}

export const submitAppointmentGeneralInfo = makeFormSubmitAction<GeneralInfo>()(constants.SUBMIT_GENERAL_INFO);

export interface QuestionnaireActionTypes extends QuestionnaireFormTypes {
  userProfileId: string;
}

export const submitAppointmentQuestionnaire = makeFormSubmitAction<QuestionnaireActionTypes>()(
  constants.SUBMIT_QUESTIONNAIRE,
);

export const createNewAppointment = actionCreator(({ startTime, userProfileId }: NewAppointment) => ({
  type: constants.CREATE_NEW_APPOINTMENT,
  values: {
    startTime,
    userProfileId,
  },
}));

export const setProvidersSearchListIds = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDERS_SEARCH_LIST_IDS,
  ids,
}));

export const setProvidersSearchListTotal = actionCreator((total: number) => ({
  type: constants.SET_PROVIDERS_SEARCH_LIST_TOTAL,
  total,
}));

export const setProvidersSearchOptionsIds = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDERS_SEARCH_OPTIONS_IDS,
  ids,
}));

export const setProvidersSearchActiveOption = actionCreator((option: DoctorsListSearchOption) => ({
  type: constants.SET_PROVIDERS_SEARCH_ACTIVE_OPTION,
  option,
}));

export const fetchPatientProviderProfileById = actionCreator((id: string) => ({
  type: constants.FETCH_PATIENT_PROVIDER_PROFILE_BY_ID,
  id,
}));

interface IFetchAvailableTimeSlots {
  id: string;
  dateFrom: Date;
  dateTo: Date;
}

export const fetchProviderAvailableTimeSlotsById = actionCreator(
  ({ id, dateFrom, dateTo }: IFetchAvailableTimeSlots) => ({
    type: constants.FETCH_PROVIDER_AVAILABLE_TIME_SLOTS_BY_ID,
    id,
    dateFrom,
    dateTo,
  }),
);

export const setProviderAvailableTimeSlots = actionCreator((ids: string[]) => ({
  type: constants.SET_PROVIDER_AVAILABLE_TIME_SLOTS,
  ids,
}));

export const setProviderAllowedStateMeta = actionCreator((allowedState: boolean) => ({
  type: constants.SET_PROVIDER_ALLOWED_STATE_META,
  allowedState,
}));

export const fetchPatientBookAppointmentById = actionCreator((id: string) => ({
  type: constants.FETCH_PATIENT_BOOK_APPOINTMENT_BY_ID,
  id,
}));

export const payAppointment = actionCreator(({ id, userProfileId }: { id: string; userProfileId: string }) => ({
  type: constants.PAY_APPOINTMENT,
  id,
  userProfileId,
}));

export const deletePatientAppointment = actionCreator(({ id, redirectTo }: { id: string; redirectTo?: string }) => ({
  type: constants.PATIENT_DELETE_APPOINTMENT,
  id,
  redirectTo,
}));
