import { IS_SENTRY_DISABLED, SENTRY_DSN } from 'constants/app';

const getSentryOptions = () => {
  const sentryOptions = {
    dsn: SENTRY_DSN,
    autoSessionTracking: false,
    tracesSampleRate: 0.5,
  };

  /* istanbul ignore next */
  if (IS_SENTRY_DISABLED) {
    // Don't actually send the errors to Sentry in development env
    return { ...sentryOptions, beforeSend: () => null };
  }

  return sentryOptions;
};

export default getSentryOptions;
