import { combineReducers, type AnyAction } from 'redux';

import {
  SET_CURRENT_PATIENTS_PAGE,
  SET_PATIENTS_LIST_TOTAL,
  SET_PATIENT_SEARCH,
  SET_PATIENT_SORT_ORDER,
  SET_PROVIDER_PATIENT_PROFILE_IDS,
  SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_IDS,
  SET_PROVIDER_PATIENT_PROFILE_CURRENT_APPOINTMENTS_PAGE,
  SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_LIST_TOTAL,
  SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SEARCH,
  SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SORT_ORDER,
  SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_IDS,
  SET_PROVIDER_PATIENT_PROFILE_CURRENT_ASSESSMENTS_PAGE,
  SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_LIST_TOTAL,
  SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SEARCH,
  SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SORT_ORDER,
} from './constants';
import type { PatientState, AppointmentsState, AssessmentsState } from './types';
import {
  SortingDirectionTypes,
  SortingKeyTypes,
  AppointmentsSortingKeyTypes,
  AssessmentsSortingKeyTypes,
} from './types';

const patientProfileIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_IDS:
      return action.ids;
    default:
      return state;
  }
};

const sort = (
  state: PatientState['sort'] = {
    sortKey: SortingKeyTypes.full_name,
    direction: SortingDirectionTypes.asc,
  },
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_PATIENT_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? {
            ...state,
            direction:
              state.direction === SortingDirectionTypes.asc ? SortingDirectionTypes.desc : SortingDirectionTypes.asc,
          }
        : { sortKey: action.sortKey, direction: SortingDirectionTypes.asc };
    default:
      return state;
  }
};

const search = (state = '', action: AnyAction) => {
  switch (action.type) {
    case SET_PATIENT_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const patientsPageNumber = (state = 1, action: AnyAction) => {
  switch (action.type) {
    case SET_CURRENT_PATIENTS_PAGE:
      return action.page;
    default:
      return state;
  }
};

const patientsListTotal = (state: number | null = null, action: AnyAction) => {
  switch (action.type) {
    case SET_PATIENTS_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

const patientProfileAppointmentsIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_IDS:
      return action.ids;
    default:
      return state;
  }
};

const patientProfileAppointmentsPageNumber = (state = 1, action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_CURRENT_APPOINTMENTS_PAGE:
      return action.page;
    default:
      return state;
  }
};

const patientProfileAppointmentsListTotal = (state: number | null = null, action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

const patientProfileAppointmentsSearch = (state = '', action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const patientProfileAppointmentsSort = (
  state: AppointmentsState['patientProfileAppointmentsSort'] = {
    sortKey: AppointmentsSortingKeyTypes.appointment_start_time,
    direction: SortingDirectionTypes.desc,
  } as any,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? {
            ...state,
            direction:
              state.direction === SortingDirectionTypes.desc ? SortingDirectionTypes.asc : SortingDirectionTypes.desc,
          }
        : { sortKey: action.sortKey, direction: SortingDirectionTypes.desc };
    default:
      return state;
  }
};

const patientProfileAssessmentsIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_IDS:
      return action.ids;
    default:
      return state;
  }
};

const patientProfileAssessmentsPageNumber = (state = 1, action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_CURRENT_ASSESSMENTS_PAGE:
      return action.page;
    default:
      return state;
  }
};

const patientProfileAssessmentsListTotal = (state: number | null = null, action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

const patientProfileAssessmentsSearch = (state = '', action: AnyAction) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SEARCH:
      return action.search;
    default:
      return state;
  }
};

const patientProfileAssessmentsSort = (
  state: AssessmentsState['patientProfileAssessmentsSort'] = {
    sortKey: AssessmentsSortingKeyTypes.appointment_start_time,
    direction: SortingDirectionTypes.desc,
  } as any,
  action: AnyAction,
) => {
  switch (action.type) {
    case SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SORT_ORDER:
      return state.sortKey === action.sortKey
        ? {
            ...state,
            direction:
              state.direction === SortingDirectionTypes.desc ? SortingDirectionTypes.asc : SortingDirectionTypes.desc,
          }
        : { sortKey: action.sortKey, direction: SortingDirectionTypes.desc };
    default:
      return state;
  }
};

export default combineReducers({
  patientProfileIds,
  patientsPageNumber,
  patientsListTotal,
  sort,
  search,
  patientProfileAppointmentsIds,
  patientProfileAppointmentsPageNumber,
  patientProfileAppointmentsListTotal,
  patientProfileAppointmentsSearch,
  patientProfileAppointmentsSort,
  patientProfileAssessmentsIds,
  patientProfileAssessmentsPageNumber,
  patientProfileAssessmentsListTotal,
  patientProfileAssessmentsSearch,
  patientProfileAssessmentsSort,
});
