import fetchPatientOnboarding from './fetchPatientOnboarding';
import fetchProviderOnboarding from './fetchProviderOnboarding';
import updatePatientContactInformation from './updatePatientContactInformation';
import updatePatientHealthProfile from './updatePatientHealthProfile';
import updatePatientPersonalInformation from './updatePatientPersonalInformation';
import updateProviderCancellationScenario from './updateProviderCancellationScenario';
import updateProviderContactInformation from './updateProviderContactInformation';
import updateProviderMedicalLicense from './updateProviderMedicalLicense';
import updateProviderPersonalInformation from './updateProviderPersonalInformation';
import updateProviderSignature from './updateProviderSignature';
import updateProviderSpecialityAndWorkingHours from './updateProviderSpecialityAndWorkingHours';

const operations = [
  fetchPatientOnboarding,
  fetchProviderOnboarding,
  updatePatientContactInformation,
  updatePatientHealthProfile,
  updatePatientPersonalInformation,
  updateProviderCancellationScenario,
  updateProviderContactInformation,
  updateProviderMedicalLicense,
  updateProviderPersonalInformation,
  updateProviderSpecialityAndWorkingHours,
  updateProviderSignature,
];

export default operations;
