import actionCreator from 'utils/state/actionCreator';

import * as constants from './constants';

export const fetchCancellationReasonOptions = actionCreator(() => ({
  type: constants.FETCH_CANCELLATION_REASON_OPTIONS,
}));

export const setCancellationReasonOptionIds = actionCreator((ids: string[]) => ({
  type: constants.SET_CANCELLATION_REASON_OPTION_IDS,
  ids,
}));
