import { defaultTo, path } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import type { SocialLinksProps } from 'state/app/types';
import type { RootState } from 'state/store/types';
import type { UserTitle } from 'types/entities/session';
import type { NormalizedResponseData } from 'types/json-api/normalized';
import { makeOptionsWithValueId, makeOptionsWithValueIdNameTitle } from 'utils/form/makeOptions';

const dataSelector = path(['data']);
const boardLicensesIdsSelector = path(['app', 'boardLicensesIds']);
const availableMedicalTitlesIdsSelector = path(['app', 'availableMedicalTitlesIds']);

export const isOnlineSelector = path(['app', 'isOnline']);
export const isServerReadySelector = path(['app', 'isServerReady']);
export const providerSpecialtiesIdsSelector = path(['app', 'providerSpecialtiesIds']);
export const availableTimezonesIdsSelector = path(['app', 'availableTimezonesIds']);
export const symptomsIdsSelector = path(['app', 'symptomsIds']);
export const availableStatesIdsSelector = path(['app', 'availableStatesIds']);
export const routerPagesSelector = path(['app', 'routerPages']);

export const providerSpecialtiesSelector = createSelector(providerSpecialtiesIdsSelector, dataSelector, (id, data) =>
  build(data as NormalizedResponseData, 'speciality', id as unknown as string[]),
);

export const availableTimezonesSelector = createSelector(availableTimezonesIdsSelector, dataSelector, (id, data) =>
  build(data as NormalizedResponseData, 'timezone', id as unknown as string[]),
);

export const timezoneByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id, data) => build(data as NormalizedResponseData, 'timezone', id),
);

export const availableTimezonesOptionsSelector = createSelector(availableTimezonesSelector, (timezones) =>
  makeOptionsWithValueId(timezones),
);

export const availableStatesSelector = createSelector(availableStatesIdsSelector, dataSelector, (id, data) =>
  build(data as NormalizedResponseData, 'state', id as unknown as string[]),
);

export const boardLicensesSelector = createSelector(boardLicensesIdsSelector, dataSelector, (id, data) =>
  build(data as NormalizedResponseData, 'boardCertification', id as unknown as string[]),
);

export const socialLinksListSelector = path<SocialLinksProps>(['app', 'socialLinksList']);

export const symptomsSelector = createSelector(symptomsIdsSelector, dataSelector, (ids, data) =>
  build(data as NormalizedResponseData, 'symptom', ids as unknown as string[]),
);

export const symptomOptionsSelector = createSelector(symptomsSelector, (symptoms) => makeOptionsWithValueId(symptoms));

export const providerMedicalTitlesSelector = createSelector(
  availableMedicalTitlesIdsSelector,
  dataSelector,
  (ids, data) => build(data as NormalizedResponseData, 'userTitle', ids as unknown as string[]) as UserTitle[],
);

export const providerMedicalTitlesOptionsSelector = createSelector(providerMedicalTitlesSelector, (medicalTitles) =>
  makeOptionsWithValueIdNameTitle(medicalTitles),
);

export const providerMedicalTitleByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  providerMedicalTitlesSelector,
  (id, titles) => {
    const safetyTitles = defaultTo([], titles);

    for (let i = 0; i < safetyTitles.length; i += 1) {
      const title = safetyTitles[i];

      if (title.id === id) {
        return title;
      }
    }

    return null;
  },
);
