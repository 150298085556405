import { PROVIDER_PROFILE_INCLUSIONS } from 'constants/profile';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { removePayoutMethod } from '../actions';
import { fetchProviderProfile } from '../actions';
import { REMOVE_PAYOUT_METHOD } from '../constants';
import { removePayoutMethodEndpoint } from '../endpoints';

const removePayoutMethodOperation = createLogic<typeof removePayoutMethod>({
  type: REMOVE_PAYOUT_METHOD,
  latest: true,

  async process(_, dispatch, done) {
    const { endpoint, url } = removePayoutMethodEndpoint;

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.delete(url);

      const inclusions = [PROVIDER_PROFILE_INCLUSIONS.PAYOUT_METHOD];
      dispatch(fetchProviderProfile({ include: inclusions.join(',') }));
      dispatch(hideModal());

      dispatch(dataApiSuccess({ endpoint }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default removePayoutMethodOperation;
