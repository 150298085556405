import normalize from 'json-api-normalizer';
import { includes, is, replace } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { submitEditAppointmentQuestionnaire } from '../actions';
import { SUBMIT_EDIT_QUESTIONNAIRE } from '../constants';
import { submitEditAppointmentQuestionnaireEndpoint } from '../endpoints';

interface IBody {
  include: string;
  blood_pressure: string | undefined;
  heart_rate: number | undefined;
  discuss_about: string | undefined;
  symptoms: string | undefined;
  respiratory_rate: number | undefined;
  temperature: number | undefined;
  visit_goal: string | undefined;
  medications: boolean;
  medication_description: string | undefined;
  allergies: boolean;
  allergies_description: string | undefined;
  surgeries: boolean;
  surgeries_description: string | undefined;
  hospitalisations: boolean;
  hospitalisations_description: string | undefined;
  other_information: string | undefined;
  update_health_profile: boolean;
}

const submitEditAppointmentQuestionnaireOperation = createLogic<typeof submitEditAppointmentQuestionnaire>({
  type: SUBMIT_EDIT_QUESTIONNAIRE,
  latest: true,

  async process({ action }, dispatch, done) {
    const {
      questionnaireId,
      bloodPressure,
      discussAbout,
      heartRate,
      respiratoryRate,
      symptoms,
      temperature,
      visitGoal,
      medications,
      allergies,
      surgeries,
      other,
      hospitalizations,
      updateHealthProfile,
      onSuccess,
    } = action.values;
    const { form } = action;

    const { endpoint, url } = submitEditAppointmentQuestionnaireEndpoint(questionnaireId);

    try {
      const body: IBody = {
        include: 'questionnaire',
        blood_pressure: is(String, bloodPressure) ? replace('.', '/', bloodPressure) : undefined,
        discuss_about: discussAbout,
        heart_rate: heartRate,
        respiratory_rate: respiratoryRate,
        symptoms,
        temperature,
        visit_goal: visitGoal,
        medications: medications.checked || false,
        medication_description: medications.description,
        allergies: allergies.checked || false,
        allergies_description: allergies.description,
        surgeries: surgeries.checked || false,
        surgeries_description: surgeries.description,
        hospitalisations: hospitalizations.checked || false,
        hospitalisations_description: hospitalizations.description,
        other_information: other,
        update_health_profile: includes('checked', updateHealthProfile),
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      onSuccess();
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form,
        withStatus: true,
      });
    }

    done();
  },
});

export default submitEditAppointmentQuestionnaireOperation;
