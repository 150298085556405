import type { FormikHelpers } from 'formik';
import { isNil } from 'ramda';

import type { MiddlewareErrorType } from 'types/errors';
import assignFormErrors from 'utils/form/assignFormErrors';

const makeHandleForm =
  (form: FormikHelpers<any> | undefined, error: MiddlewareErrorType, formStatusKeys = ['base']) =>
  () => {
    if (!isNil(form)) {
      assignFormErrors(form, error, formStatusKeys);
    }
  };

export default makeHandleForm;
