import {
  providerPatientProfileRoute,
  providerPatientProfileAppointmentsRoute,
  providerPatientProfileAssessmentsRoute,
  providerPatientProfilesRoute,
  providerPatientProfileAssessmentRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchProviderPatientProfilesEndpoint = endpoint(
  constants.FETCH_PROVIDER_PATIENT_PROFILES,
  'GET',
  providerPatientProfilesRoute,
);

export const fetchProviderPatientProfileByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PROVIDER_PATIENT_PROFILE_BY_ID, 'GET', providerPatientProfileRoute(id));

export const fetchProviderPatientProfileAppointmentsByIdEndpoint = (id: string) =>
  endpoint(
    constants.FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID,
    'GET',
    providerPatientProfileAppointmentsRoute(id),
  );

export const fetchProviderPatientProfileAssessmentsByIdEndpoint = (id: string) =>
  endpoint(
    constants.FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID,
    'GET',
    providerPatientProfileAssessmentsRoute(id),
  );

export const fetchProviderPatientProfileAssessmentByIdEndpoint = (patientId: string, assessmentId: string) =>
  endpoint(
    constants.FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID,
    'GET',
    providerPatientProfileAssessmentRoute(patientId, assessmentId),
  );
