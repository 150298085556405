import { createWrapper } from 'next-redux-wrapper';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { createLogicMiddleware } from 'redux-logic';

import * as conceptsReducers from '../concepts';
import conceptsOperations from '../concepts/operationsRoot';

import hydrateReducer from './hydrateReducer';
import type { AppStore } from './types';

const operations = [...conceptsOperations];

const makeStore = () => {
  const logger = createLogger({
    collapsed: true,
    duration: true,
  });

  // FIXME: redux-logic has really strange typings
  const logicMiddleware = createLogicMiddleware(operations as any);

  const canAddLogger = typeof window !== 'undefined' && typeof jest === 'undefined';

  const middlewares = canAddLogger ? applyMiddleware(logicMiddleware, logger) : applyMiddleware(logicMiddleware);
  const enhancer = composeWithDevTools(middlewares);

  const appReducer = hydrateReducer(combineReducers(conceptsReducers));

  const store: AppStore = Object.assign(createStore(appReducer, enhancer), { logicMiddleware });

  return store;
};

export default createWrapper(makeStore /* , { debug: true } */);
