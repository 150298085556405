import type { AxiosError } from 'axios';
import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import { isErrorStatusGone } from 'utils/getErrorStatus';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { payAppointment } from '../actions';
import { PAY_APPOINTMENT } from '../constants';
import { payAppointmentEndpoint } from '../endpoints';

const payAppointmentOperation = createLogic<typeof payAppointment>({
  type: PAY_APPOINTMENT,
  latest: true,

  async process({ action }, dispatch, done) {
    const { id, userProfileId } = action;
    const { endpoint, url } = payAppointmentEndpoint(id);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await httpClient.put(url);

      const response = normalize(data);

      redirect(data.meta.url, { reload: true });

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
        withStatus: true,
      });

      if (isErrorStatusGone(error as AxiosError)) {
        dispatch(showModal({ modalType: 'MODAL_BOOKING_APPOINTMENT_GONE', modalProps: { userProfileId } }));
      } else {
        showErrorNotifications({ error, dispatch });
      }
    }

    done();
  },
});

export default payAppointmentOperation;
