import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { deletePatientAppointment } from '../actions';
import { PATIENT_DELETE_APPOINTMENT } from '../constants';
import { deletePatientAppointmentEndpoint } from '../endpoints';

const deletePatientAppointmentOperation = createLogic<typeof deletePatientAppointment>({
  type: PATIENT_DELETE_APPOINTMENT,
  latest: true,

  async process({ action }, dispatch, done) {
    const { id, redirectTo } = action;
    const { endpoint, url } = deletePatientAppointmentEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.delete(url);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());

      if (redirectTo) {
        redirect(redirectTo);
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default deletePatientAppointmentOperation;
