import { StatusCodes } from 'http-status-codes';
import dynamic from 'next/dynamic';
import { cond, equals, T, always } from 'ramda';

import useContainer from './hook';

const DynamicNotFound = dynamic(always(import('views/errors/NotFound')));
const DynamicInternalServerError = dynamic(always(import('views/errors/InternalServerError')));
const DynamicForbidden = dynamic(always(import('views/errors/Forbidden')));

interface ErrorHandlerProps {
  children: React.ReactNode;
}

function ErrorHandler({ children }: ErrorHandlerProps) {
  const { errorStatus } = useContainer();

  const component = cond([
    [equals(StatusCodes.NOT_FOUND), always(<DynamicNotFound />)],
    [equals(StatusCodes.INTERNAL_SERVER_ERROR), always(<DynamicInternalServerError />)],
    [equals(StatusCodes.FORBIDDEN), always(<DynamicForbidden />)],

    [T, always(children as React.ReactElement<any>)],
  ]);

  return component(errorStatus ?? (0 as any));
}

export default ErrorHandler;
