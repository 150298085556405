import type { AnyAction } from 'redux';
import type { Logic } from 'redux-logic';
import { createLogic as origCreateLogic } from 'redux-logic';
import type { CreateLogic } from 'redux-logic/definitions/logic';

import type { LogicDependencies, RootState } from 'state/store/types';

import type { Action, ActionCreator } from './types';

function createLogic<AC extends ActionCreator<any[], Action> = ActionCreator<any[], AnyAction>>(
  config: CreateLogic.Config<RootState, AC['__action__'], LogicDependencies, undefined, AC['__type__']>,
): Logic<RootState, AC['__payload__'], AC['__meta__'], LogicDependencies, undefined, AC['__type__']> {
  // FIXME: redux-logic has really strange typings
  return origCreateLogic(config as any);
}

export default createLogic;
