import deleteNotification from './deleteNotification';
import fetchNotifications from './fetchNotifications';
import fetchNotificationSettings from './fetchNotificationSettings';
import observeSidebarVisibility from './observeSidebarVisibility';
import readAllNotifications from './readAllNotifications';
import readNotification from './readNotification';
import receiveNotification from './receiveNotification';
import unreadNotification from './unreadNotification';
import updateNotificationSettings from './updateNotificationSettings';

const operations = [
  fetchNotificationSettings,
  updateNotificationSettings,
  readAllNotifications,
  readNotification,
  unreadNotification,
  observeSidebarVisibility,
  fetchNotifications,
  receiveNotification,
  deleteNotification,
];

export default operations;
