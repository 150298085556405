import { patientAppointmentVideoCallRoute, providerAppointmentVideoCallRoute } from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const generatePatientVideoConferenceEndpoint = (id: string) =>
  endpoint(constants.GENERATE_PATIENT_VIDEO_CONFERENCE_ACCESS, 'GET', patientAppointmentVideoCallRoute(id));

export const generateProviderVideoConferenceEndpoint = (id: string) =>
  endpoint(constants.GENERATE_PROVIDER_VIDEO_CONFERENCE_ACCESS, 'GET', providerAppointmentVideoCallRoute(id));
