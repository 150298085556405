import { path } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import { dataSelector } from 'state/data/selectors';
import type { RootState } from 'state/store/types';
import type { NormalizedResponseData } from 'types/json-api/normalized';

import { SortingDirectionTypes } from './types';

// Patients
export const providerPatientProfileIdsSelector = path<string[] | null>(['patient', 'patientProfileIds']);

export const providerPatientProfilesSelector = createSelector(
  providerPatientProfileIdsSelector,
  dataSelector,
  (ids, data) => build(data as NormalizedResponseData, 'patientProfile', ids as unknown as string[]),
);

export const sortSelector = (state: RootState) => state.patient.sort;

export const sortParamsSelector = createSelector(sortSelector, ({ sortKey, direction }) =>
  direction === SortingDirectionTypes.asc ? sortKey : `-${sortKey}`,
);

export const searchSelector = (state: RootState) => state.patient.search;
export const patientsListTotalSelector = (state: RootState) => state.patient.patientsListTotal;
export const patientsPageNumberSelector = path<number>(['patient', 'patientsPageNumber']);

// Patient profile
export const providerPatientProfileByIdSelector = createSelector(
  (_: RootState, id: string) => id,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build(data, 'patientProfile', id),
);
// Patient profile - appointments
export const providerPatientProfileAppointmentIdsSelector = path<string[] | null>([
  'patient',
  'patientProfileAppointmentsIds',
]);
export const providerPatientProfileAppointmentsSelector = createSelector(
  providerPatientProfileAppointmentIdsSelector,
  dataSelector,
  (ids, data) => build(data as NormalizedResponseData, 'appointment', ids as unknown as string[]),
);
export const providerPatientProfileAppointmentsPageNumberSelector = path<number>([
  'patient',
  'patientProfileAppointmentsPageNumber',
]);
export const providerPatientProfileAppointmentsListTotalSelector = path<number>([
  'patient',
  'patientProfileAppointmentsListTotal',
]);
export const providerPatientProfileAppointmentsSearchSelector = path<string>([
  'patient',
  'patientProfileAppointmentsSearch',
]);
export const providerPatientProfileAppointmentsSortSelector = path<string>([
  'patient',
  'patientProfileAppointmentsSort',
]);
export const providerPatientProfileAppointmentsSortParamsSelector = createSelector(
  providerPatientProfileAppointmentsSortSelector,
  ({ sortKey, direction }: any) => (direction === SortingDirectionTypes.asc ? sortKey : `-${sortKey}`),
);
// Patient profile - assessments
export const providerPatientProfileAssessmentIdsSelector = path<string[] | null>([
  'patient',
  'patientProfileAssessmentsIds',
]);
export const providerPatientProfileAssessmentsSelector = createSelector(
  providerPatientProfileAssessmentIdsSelector,
  dataSelector,
  (ids, data) => build(data as NormalizedResponseData, 'assessment', ids as unknown as string[]),
);
export const providerPatientProfileAssessmentsPageNumberSelector = path<number>([
  'patient',
  'patientProfileAssessmentsPageNumber',
]);
export const providerPatientProfileAssessmentsListTotalSelector = path<number>([
  'patient',
  'patientProfileAssessmentsListTotal',
]);
export const providerPatientProfileAssessmentsSearchSelector = path<string>([
  'patient',
  'patientProfileAssessmentsSearch',
]);
export const providerPatientProfileAssessmentsSortSelector = path<string>(['patient', 'patientProfileAssessmentsSort']);
export const providerPatientProfileAssessmentsSortParamsSelector = createSelector(
  providerPatientProfileAssessmentsSortSelector,
  ({ sortKey, direction }: any) => (direction === SortingDirectionTypes.asc ? sortKey : `-${sortKey}`),
);
