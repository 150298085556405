import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'profile';

// Common
export const ACTIVATE_ACCOUNT = createActionType(CONCEPT_NAME, 'ACTIVATE_ACCOUNT');

// Provider
export const UPDATE_PROVIDER_PERSONAL_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_PERSONAL_INFORMATION',
);

export const UPDATE_PROVIDER_CONTACT_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_CONTACT_INFORMATION',
);

export const UPDATE_PROVIDER_CANCELLATION_SCENARIO = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_CANCELLATION_SCENARIO',
);
export const EDIT_PROVIDER_MEDICAL_LICENSE = createActionType(CONCEPT_NAME, 'EDIT_PROVIDER_MEDICAL_LICENSE');
export const UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS',
);
export const UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY = createActionType(
  CONCEPT_NAME,
  'UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY',
);

export const FETCH_PROVIDER_PROFILE = createActionType(CONCEPT_NAME, 'FETCH_PROVIDER_PROFILE');
export const INITIAL_FETCH_PROVIDER_PROFILE = createActionType(CONCEPT_NAME, 'INITIAL_FETCH_PROVIDER_PROFILE');
export const UPDATE_PROVIDER_EMAIL = createActionType(CONCEPT_NAME, 'UPDATE_PROVIDER_EMAIL');
export const UPDATE_PROVIDER_EMAIL_BY_TOKEN = createActionType(CONCEPT_NAME, 'UPDATE_PROVIDER_EMAIL_BY_TOKEN');
export const DELETE_PROVIDER_AVATAR = createActionType(CONCEPT_NAME, 'DELETE_PROVIDER_AVATAR');
export const UPDATE_PROVIDER_AVATAR = createActionType(CONCEPT_NAME, 'UPDATE_PROVIDER_AVATAR');
export const UPDATE_PASSWORD = createActionType(CONCEPT_NAME, 'UPDATE_PASSWORD');
export const DEACTIVATE_PROVIDER_ACCOUNT = createActionType(CONCEPT_NAME, 'DEACTIVATE_PROVIDER_ACCOUNT');
export const ACTIVATE_PROVIDER_ACCOUNT = createActionType(CONCEPT_NAME, 'ACTIVATE_PROVIDER_ACCOUNT');

// Patient
export const UPDATE_PATIENT_PERSONAL_INFORMATION = createActionType(
  CONCEPT_NAME,
  'UPDATE_PATIENT_PERSONAL_INFORMATION',
);

export const UPDATE_PATIENT_CONTACT_INFORMATION = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_CONTACT_INFORMATION');
export const FETCH_PATIENT_PROFILE = createActionType(CONCEPT_NAME, 'FETCH_PATIENT_PROFILE');
export const FETCH_CHECKLIST = createActionType(CONCEPT_NAME, 'FETCH_CHECKLIST');
export const INITIAL_FETCH_PATIENT_PROFILE = createActionType(CONCEPT_NAME, 'INITIAL_FETCH_PATIENT_PROFILE');
export const UPDATE_PATIENT_HEALTH_PROFILE = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_HEALTH_PROFILE');
export const UPDATE_PATIENT_EMAIL = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_EMAIL');
export const UPDATE_PATIENT_EMAIL_BY_TOKEN = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_EMAIL_BY_TOKEN');
export const DELETE_PATIENT_AVATAR = createActionType(CONCEPT_NAME, 'DELETE_PATIENT_AVATAR');
export const UPDATE_PATIENT_AVATAR = createActionType(CONCEPT_NAME, 'UPDATE_PATIENT_AVATAR');
export const DEACTIVATE_PATIENT_ACCOUNT = createActionType(CONCEPT_NAME, 'DEACTIVATE_PATIENT_ACCOUNT');
export const ACTIVATE_PATIENT_ACCOUNT = createActionType(CONCEPT_NAME, 'ACTIVATE_PATIENT_ACCOUNT');
export const CREATE_PAYOUT_METHOD = createActionType(CONCEPT_NAME, 'CREATE_PAYOUT_METHOD');
export const FETCH_PAYOUT_METHOD = createActionType(CONCEPT_NAME, 'FETCH_PAYOUT_METHOD');
export const REMOVE_PAYOUT_METHOD = createActionType(CONCEPT_NAME, 'REMOVE_PAYOUT_METHOD');
export const VERIFY_AND_LOGIN = createActionType(CONCEPT_NAME, 'VERIFY_AND_LOGIN');
