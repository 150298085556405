import format from 'date-fns/format';
import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import isPresent from 'utils/isPresent';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderPersonalInformation } from '../actions';
import { UPDATE_PROVIDER_PERSONAL_INFORMATION } from '../constants';
import { updateProviderPersonalInformationEndpoint } from '../endpoints';

const updateProviderPersonalInformationOperation = createLogic<typeof updateProviderPersonalInformation>({
  type: UPDATE_PROVIDER_PERSONAL_INFORMATION,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PROVIDER.CONTACT_INFORMATION.PATH;

    const { endpoint, url } = updateProviderPersonalInformationEndpoint;

    const formData = new FormData();
    formData.append('first_name', action.values.firstName as string);
    formData.append('last_name', action.values.lastName as string);
    formData.append('timezone', action.values.timeZone as string);
    formData.append('sex', action.values.sex as string);
    formData.append('medical_education', action.values.collegeUniversitySchool as string);

    formData.append('gender_identity', action.values.genderIdentity || '');
    formData.append('about_me', action.values.about || '');
    formData.append('undergraduate_school_and_major', action.values.undergraduateSchool || '');
    formData.append('graduate_school', action.values.graduateSchool || '');

    if (action.values.birthDate instanceof Date) {
      formData.append('date_of_birth', format(action.values.birthDate, 'yyyy-MM-dd'));
    }

    if (isPresent(action.values.avatarFile)) {
      formData.append('avatar', action.values.avatarFile as File);
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updateProviderPersonalInformationOperation;
