import type { AxiosError } from 'axios';

import { UserRole } from 'constants/app';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import { createRouteFromPathname } from 'utils/createRouteHelper';
import assignFormErrors from 'utils/form/assignFormErrors';
import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus';
import requestErrorHandler from 'utils/requestErrorHandler';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { updateProviderEmail } from '../actions';
import { UPDATE_PROVIDER_EMAIL } from '../constants';
import { updateProviderEmailEndpoint } from '../endpoints';

const updateProviderEmailOperation = createLogic<typeof updateProviderEmail>({
  type: UPDATE_PROVIDER_EMAIL,
  latest: true,

  async process({ action }, dispatch, done) {
    const { values, form } = action;
    const { endpoint, url } = updateProviderEmailEndpoint;

    try {
      form.setStatus(undefined);
      dispatch(dataApiRequest({ endpoint }));

      const redirectToRoute = createRouteFromPathname({
        pathname: ROUTES.EMAIL_UPDATE_VERIFICATION.PATH,
        pathChunkName: 'role',
        pathChunkReplacer: UserRole.provider,
      });

      const body = {
        new_email: values.emailUpdated,
        password: values.password,
        redirect_to: redirectToRoute,
      };

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));

      form.setFieldValue('isEmailSent', true);
      dispatch(showMessage({ messageText: { id: 'account.updateEmail.success' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
      });
      if (isErrorStatusUnprocessableEntity(error as AxiosError)) {
        assignFormErrors(form, error as AxiosError, ['base', 'newEmail']);
      } else {
        showErrorNotifications({ error, dispatch });
      }
    } finally {
      // for change status in modal form
      form.setSubmitting(false);
    }

    done();
  },
});

export default updateProviderEmailOperation;
