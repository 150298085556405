import type { StatusCodes } from 'http-status-codes';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import * as constants from './constants';
import type { SessionActions, SessionState } from './types';

export const currentUser = (state: SessionState['currentUser'] = null, action: SessionActions) => {
  switch (action.type) {
    case constants.USER_LOGIN_SUCCESS:
      return action.currentUser;
    case constants.USER_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const emailVerificationStatus = (state: StatusCodes | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_EMAIL_VERIFICATION_STATUS:
      return action.status;
    case constants.USER_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const patientProfileId = (state: string | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_PATIENT_PROFILE_ID:
      return action.id;
    case constants.USER_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const providerProfileId = (state: string | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_PROVIDER_PROFILE_ID:
      return action.id;
    case constants.USER_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

export const passwordRecoveryStatus = (state: StatusCodes | null = null, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_PASSWORD_RECOVERY_STATUS:
      return action.status;
    case constants.USER_LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
};

const sessionReducer = combineReducers<SessionState, SessionActions>({
  currentUser,
  emailVerificationStatus,
  passwordRecoveryStatus,
  patientProfileId,
  providerProfileId,
});

export default sessionReducer;
