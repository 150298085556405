import parseISO from 'date-fns/parseISO';
import { equals, pipe, not, compose, prop, head, sortBy } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import type { RootState } from 'state/store/types';
import type { NormalizedResponseData } from 'types/json-api/normalized';

const dataSelector = (state: RootState) => state.data;
export const notificationSettingsIdSelector = (state: RootState) => state.notification.notificationSettingsId;
export const notificationSidebarVisibleSelector = (state: RootState) => state.notification.notificationSidebarVisible;
export const notificationsIdsSelector = (state: RootState) => state.notification.notificationsIds;
export const notificationsHasMoreSelector = (state: RootState) => state.notification.notificationsHasMore;
export const unreadNotificationsCountSelector = (state: RootState) => state.notification.unreadNotificationsCount;

export const notificationSettingsSelector = createSelector(
  notificationSettingsIdSelector,
  dataSelector,
  (id: string, data: NormalizedResponseData) => build(data, 'notificationSetting', id),
);

export const notificationsSelector = createSelector(
  notificationsIdsSelector,
  dataSelector,
  (ids: string[], data: NormalizedResponseData) => build(data, 'notification', ids),
);

export const notificationSelector = createSelector(
  (_: any, notificationId: string) => notificationId,
  dataSelector,
  (notificationId: string, data: NormalizedResponseData) => build(data, 'notification', notificationId),
);

export const isUnreadNotificationsSelector = createSelector(unreadNotificationsCountSelector, pipe(equals(0), not));

export const lastNotificationIdSelector = createSelector(
  notificationsSelector,
  compose(prop('id'), head, sortBy(compose(parseISO, prop('createdAt') as any))),
);
