import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Diagnose } from 'types/entities/assessments';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchDiagnoses } from '../actions';
import { setDiagnosesIds } from '../actions';
import * as constants from '../constants';
import { fetchDiagnosesEndpoint } from '../endpoints';

const fetchDiagnosesOperation = createLogic<typeof fetchDiagnoses>({
  type: constants.FETCH_DIAGNOSES,
  debounce: 500,
  latest: true,

  async process({ action: { search } }, dispatch, done) {
    const { endpoint, url } = fetchDiagnosesEndpoint;

    try {
      const params = {
        search,
        page: {
          number: 1,
          size: 5,
        },
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setDiagnosesIds(data.data.map(({ id }: Diagnose) => id)));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchDiagnosesOperation;
