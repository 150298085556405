import { actionCreator } from 'utils/state';
import type { ModalRootState } from 'views/ModalRoot/types';

import * as constants from './constants';

export const hideModal = actionCreator(constants.HIDE_MODAL);
export const showModal = actionCreator(({ modalType, modalProps }: ModalRootState) => ({
  type: constants.SHOW_MODAL,
  modalType,
  modalProps,
}));
