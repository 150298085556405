import format from 'date-fns/format';
import { equals } from 'ramda';

import type { StateLicense } from 'types/entities/session';

const constructStateLicenses = <T extends StateLicense[], V extends StateLicense[]>(
  existedStateLicenses: T,
  newStateLicenses: V,
) => {
  const result = [];
  const existedStateLicensesClone = [...existedStateLicenses];
  const newStateLicensesClone = [...newStateLicenses];

  // Check existed licenses
  if (Array.isArray(existedStateLicensesClone)) {
    for (let i = 0; i < existedStateLicensesClone.length; i += 1) {
      const existingStateLicense = existedStateLicensesClone[i] as StateLicense;

      const findIndex = newStateLicensesClone.findIndex(
        (item) =>
          equals(item.id, existingStateLicense.id) &&
          equals(item.state, existingStateLicense.state) &&
          equals(item.number, existingStateLicense.number) &&
          equals(format(item.expirationDate as Date, 'yyyy-MM-dd'), existingStateLicense.expirationDate),
      );

      // Delete from body if already exists (The hour already existed)
      if (findIndex !== -1) {
        newStateLicensesClone.splice(findIndex, 1);
      }

      // Add destroy attribute (The hour was deleted by user on the UI)
      if (findIndex === -1) {
        result.push({
          id: existingStateLicense.id,
          _destroy: true,
        });
      }
    }

    // Add state licenses
    if (Array.isArray(newStateLicensesClone)) {
      for (let j = 0; j < newStateLicensesClone.length; j += 1) {
        const license = newStateLicensesClone[j] as StateLicense;

        result.push({
          expiration_date: format(license.expirationDate as Date, 'dd-MM-yyyy'),
          number: license.number,
          state: license.state,
        });
      }
    }
  }

  return result;
};

export default constructStateLicenses;
