import normalize from 'json-api-normalizer';

import { PATIENT_PROFILE_INCLUSIONS } from 'constants/profile';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePatientContactInformation } from '../actions';
import { UPDATE_PATIENT_CONTACT_INFORMATION } from '../constants';
import { updatePatientContactInformationEndpoint } from '../endpoints';

const updatePatientContactInformationOperation = createLogic<typeof updatePatientContactInformation>({
  type: UPDATE_PATIENT_CONTACT_INFORMATION,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.DASHBOARD.PATIENT.CONTACT_INFORMATION.PATH;

    const { endpoint, url } = updatePatientContactInformationEndpoint;

    const body = {
      include: PATIENT_PROFILE_INCLUSIONS.CONTACT_INFORMATION,
      phone_number: action.values.phone,
      state: action.values.state,
      street_address: action.values.streetAddress,
      zip_code: action.values.zip,
      city: action.values.city,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);

      dispatch(showMessage({ messageText: { id: 'account.contactInformationUpdatedSuccessfully' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updatePatientContactInformationOperation;
