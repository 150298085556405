import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import { pipe, map, forEach, replace, split, assocPath, mergeDeepWith, isNil } from 'ramda';

interface FormatErrorsProps {
  source: {
    pointer: string;
  };
  detail: string;
}

const formatErrorObject = ({ source: { pointer }, detail }: FormatErrorsProps) => {
  const attributes = pipe(replace('/', ''), split('/'), map(camelCase))(pointer);

  return assocPath(attributes, capitalize(detail), {});
};

const formatJsonApiErrors = (errors: FormatErrorsProps[] | null) => {
  if (isNil(errors)) {
    return { base: 'Unknown error' };
  }

  let result = {};

  forEach((error) => {
    const formattedError = formatErrorObject(error);
    result = mergeDeepWith((left, right) => `${left}. ${right}`, result, formattedError);
  }, errors);

  return result;
};

export default formatJsonApiErrors;
