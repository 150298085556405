import { WEEK_DAY } from './app';
import ROUTES from './routes';

export const PATIENT_ONBOARDING_STEPS = [
  { id: 'onboarding.fillPersonalInformation' },
  { id: 'onboarding.fillContactInformation' },
  { id: 'onboarding.fillHealthProfile' },
];

export const PROVIDER_ONBOARDING_STEPS = [
  { id: 'onboarding.fillPersonalInformation' },
  { id: 'onboarding.fillContactInformation' },
  { id: 'onboarding.fillMedicalLicence' },
  { id: 'onboarding.specialityWorkingHours' },
  { id: 'onboarding.additionalInformation' },
];

export const TIMEZONES = [
  {
    value: 'hst',
    name: { id: 'shared.timezone.hawaii' },
  },
  {
    value: 'akmd',
    name: { id: 'shared.timezone.alaska' },
  },
  {
    value: 'pdt',
    name: { id: 'shared.timezone.pacific' },
  },
  {
    value: 'mdt',
    name: { id: 'shared.timezone.mountain' },
  },
  {
    value: 'cdt',
    name: { id: 'shared.timezone.central' },
  },
  {
    value: 'edt',
    name: { id: 'shared.timezone.eastern' },
  },
];

export const MARITAL_STATUSES = [
  {
    value: 'marry',
    name: { id: 'shared.marry' },
  },
  {
    value: 'divorce',
    name: { id: 'shared.divorce' },
  },
  {
    value: 'other_none',
    name: { id: 'shared.nonOther' },
  },
];

export const CENTS = 100;

export const PRICE_PER_HOUR_BOUNDARIES = {
  MIN: 1,
  MIN_CENTS: 1 * CENTS,
  MAX: 100,
  MAX_CENTS: 100 * CENTS,
};

export const STATE_LICENSE_BOUNDARIES = {
  MIN: 3,
  MAX: 25,
};

export const WORKING_HOURS_DAYS = [
  {
    dayWeek: WEEK_DAY.SUNDAY,
    name: { id: 'shared.sunday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.MONDAY,
    name: { id: 'shared.monday' },
    checkBoxClassNames: 'flex-center-y h-40 order-1',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.TUESDAY,
    name: { id: 'shared.tuesday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.WEDNESDAY,
    name: { id: 'shared.wednesday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.THURSDAY,
    name: { id: 'shared.thursday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.FRIDAY,
    name: { id: 'shared.friday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
  {
    dayWeek: WEEK_DAY.SATURDAY,
    name: { id: 'shared.saturday' },
    checkBoxClassNames: 'flex-center-y h-40',
    wrapperClassNames: 'd-flex flex-wrap',
  },
];

export const PATIENT_ONBOARDING_INCLUSIONS = {
  PERSONAL_INFORMATION: 'personal_information',
  CONTACT_INFORMATION: 'contact_information',
  HEALTH_PROFILE: 'health_profile',
};

export const PROVIDER_ONBOARDING_INCLUSIONS = {
  PERSONAL_INFORMATION: 'personal_information',
  CONTACT_INFORMATION: 'contact_information',
  WORKING_HOURS: 'working_hours',
  SPECIALTIES: 'user_profiles_specialities',
  CANCELLATION_POLICY: 'cancellation_policy',
  BOARD_LICENSES: 'board_license_certifications',
  BOARD_CERTIFICATION: 'board_certification',
  STATE_LICENSES: 'state_licenses',
  USER_TITLE: 'user_title',
  SIGNATURE: 'signature',
};
export enum PatientOnboardingSteps {
  personalInformation = 'personal_information',
  contactInformation = 'contact_information',
  healthProfile = 'health_profile',
  completed = 'completed',
}

export enum ProviderOnboardingSteps {
  personalInformation = 'personal_information',
  contactInformation = 'contact_information',
  medicalLicense = 'medical_license',
  workingHours = 'working_hours',
  cancellationPolicy = 'cancellation_policy',
  signature = 'signature',
  completed = 'completed',
}

export const PATIENT_ONBOARDING_STEPS_META = [
  {
    KEY: PatientOnboardingSteps.personalInformation,
    ORDER: 1,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PATIENT.PERSONAL_INFORMATION.PATH,
  },
  {
    KEY: PatientOnboardingSteps.contactInformation,
    ORDER: 2,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PATIENT.CONTACT_INFORMATION.PATH,
  },
  {
    KEY: PatientOnboardingSteps.healthProfile,
    ORDER: 3,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PATIENT.HEALTH_PROFILE.PATH,
  },
  {
    KEY: PatientOnboardingSteps.completed,
    ORDER: 4,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PATIENT.DONE.PATH,
  },
];

export const PROVIDER_ONBOARDING_STEPS_META = [
  {
    KEY: ProviderOnboardingSteps.personalInformation,
    ORDER: 1,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.PERSONAL_INFORMATION.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.contactInformation,
    ORDER: 2,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.CONTACT_INFORMATION.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.medicalLicense,
    ORDER: 3,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.MEDICAL_LICENCE.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.workingHours,
    ORDER: 4,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.SPECIALITY_AND_HOURS.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.cancellationPolicy,
    ORDER: 5,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.CANCELLATION_SCENARIO.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.signature,
    ORDER: 6,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.SIGNATURE.PATH,
  },
  {
    KEY: ProviderOnboardingSteps.completed,
    ORDER: 7,
    REDIRECT_ROUTE: ROUTES.ONBOARDING.PROVIDER.DONE.PATH,
  },
];

export const ONBOARDING_STEPS_PATIENTS = {
  [PatientOnboardingSteps.contactInformation]: ROUTES.ONBOARDING.PATIENT.CONTACT_INFORMATION.PATH,
  [PatientOnboardingSteps.personalInformation]: ROUTES.ONBOARDING.PATIENT.PERSONAL_INFORMATION.PATH,
  [PatientOnboardingSteps.healthProfile]: ROUTES.ONBOARDING.PATIENT.HEALTH_PROFILE.PATH,
  [PatientOnboardingSteps.completed]: ROUTES.ONBOARDING.PATIENT.DONE.PATH,
};

export const ONBOARDING_STEPS_PROVIDER = {
  [ProviderOnboardingSteps.contactInformation]: ROUTES.ONBOARDING.PROVIDER.CONTACT_INFORMATION.PATH,
  [ProviderOnboardingSteps.personalInformation]: ROUTES.ONBOARDING.PROVIDER.PERSONAL_INFORMATION.PATH,
  [ProviderOnboardingSteps.medicalLicense]: ROUTES.ONBOARDING.PROVIDER.MEDICAL_LICENCE.PATH,
  [ProviderOnboardingSteps.workingHours]: ROUTES.ONBOARDING.PROVIDER.SPECIALITY_AND_HOURS.PATH,
  [ProviderOnboardingSteps.cancellationPolicy]: ROUTES.ONBOARDING.PROVIDER.CANCELLATION_SCENARIO.PATH,
  [ProviderOnboardingSteps.signature]: ROUTES.ONBOARDING.PROVIDER.SIGNATURE.PATH,
  [ProviderOnboardingSteps.completed]: ROUTES.ONBOARDING.PROVIDER.DONE.PATH,
};

export const ZIP_CODE_POSSIBLE_SYMBOLS = /[0-9]/;
export const CITY_POSSIBLE_SYMBOLS = /[a-zA-Z\s-]/;
