import { useCallback } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { useIntl } from 'react-intl';

import { isIntlMessage } from 'utils/isIntlMessage';

type TextToTranslateType = (MessageDescriptor & { values?: any }) | string | undefined;

const useLocale = () => {
  const intl = useIntl();

  const getTranslation = useCallback(
    (text: TextToTranslateType) => {
      return isIntlMessage(text) ? intl.formatMessage(text, text.values) : text;
    },
    [intl],
  );

  return {
    getTranslation,
  };
};

export default useLocale;
