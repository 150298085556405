import formatISO from 'date-fns/formatISO';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';
import updateDataHelper from 'utils/updateDataHelper';

import type { readAllNotifications } from '../actions';
import { setUnreadNotificationsCount } from '../actions';
import { READ_ALL_NOTIFICATIONS } from '../constants';
import { readAllNotificationsEndpoint } from '../endpoints';
import { notificationsIdsSelector } from '../selectors';

const readAllNotificationsOperation = createLogic<typeof readAllNotifications>({
  type: READ_ALL_NOTIFICATIONS,
  latest: true,

  async process({ getState }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = readAllNotificationsEndpoint;
    const notificationIds = notificationsIdsSelector(state);

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.put(url);

      const response = updateDataHelper(state.data, 'notification', notificationIds, {
        attributes: { readAt: formatISO(new Date()) },
      });

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setUnreadNotificationsCount(0));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default readAllNotificationsOperation;
