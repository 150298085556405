import createNewAppointment from './createNewAppointment';
import deletePatientAppointment from './deletePatientAppointment';
import fetchPatientBookAppointmentById from './fetchPatientBookAppointmentById';
import fetchPatientProviderProfileById from './fetchPatientProviderProfileById';
import fetchProviderAvailableTimeSlotsById from './fetchProviderAvailableTimeSlotsById';
import fetchProvidersSearchList from './fetchProvidersSearchList';
import fetchProvidersSearchOptions from './fetchProvidersSearchOptions';
import payAppointment from './payAppointment';
import submitAppointmentGeneralInfo from './submitAppointmentGeneralInfo';
import submitAppointmentQuestionnaire from './submitAppointmentQuestionnaire';

const operations = [
  fetchProvidersSearchList,
  fetchProvidersSearchOptions,
  submitAppointmentGeneralInfo,
  fetchPatientBookAppointmentById,
  createNewAppointment,
  fetchProviderAvailableTimeSlotsById,
  fetchPatientProviderProfileById,
  submitAppointmentQuestionnaire,
  payAppointment,
  deletePatientAppointment,
];

export default operations;
