import { EmailVerificationType, UserRole } from 'constants/app';
import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/nextApiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import addToCookies from 'utils/addToCookies';
import { createRouteFromPathname } from 'utils/createRouteHelper';
import redirect, { buildQuery } from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePatientEmailByToken } from '../actions';
import { UPDATE_PATIENT_EMAIL_BY_TOKEN } from '../constants';
import { verifyAndLoginEndpoint } from '../endpoints';

const updatePatientEmailByTokenOperation = createLogic<typeof updatePatientEmailByToken>({
  type: UPDATE_PATIENT_EMAIL_BY_TOKEN,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = verifyAndLoginEndpoint;

    const body = {
      token: action.emailToken,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { headers } = await httpClient.put(`${url}?${buildQuery({ role: UserRole.patient })}`, body);

      addToCookies(headers, action.ctx.res);
      dispatch(dataApiSuccess({ endpoint }));

      redirect(ROUTES.DASHBOARD.PATIENT.ACCOUNT_SETTINGS.PATH, {
        response: action.ctx.res,
        query: { email_verified: true },
      });
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });

      const route = createRouteFromPathname({
        pathname: ROUTES.EMAIL_VERIFICATION_EXPIRED.PATH,
        pathChunkName: 'role',
        pathChunkReplacer: UserRole.patient,
      });

      redirect(route, { response: action.ctx.res, query: { type: EmailVerificationType.emailUpdate } });
    }

    done();
  },
});

export default updatePatientEmailByTokenOperation;
