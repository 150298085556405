import normalize from 'json-api-normalizer';

import { AppointmentTabs } from 'constants/appointment';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { fetchPatientAppointments, type cancelPatientAppointment } from '../actions';
import { PATIENT_CANCEL_APPOINTMENT } from '../constants';
import { cancelPatientAppointmentEndpoint } from '../endpoints';

const cancelPatientAppointmentOperation = createLogic<typeof cancelPatientAppointment>({
  type: PATIENT_CANCEL_APPOINTMENT,
  latest: true,

  async process({ action }, dispatch, done) {
    const { id, cancellationReasonOptionId, appointmentCancellationReasonDescription } = action.values;
    const { endpoint, url } = cancelPatientAppointmentEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const params = {
        appointment_cancellation_reason: {
          cancellation_reason_option_id: cancellationReasonOptionId,
          description: appointmentCancellationReasonDescription,
        },
      };

      const { data } = await httpClient.post(url, params);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(hideModal());
      dispatch(fetchPatientAppointments({ status: AppointmentTabs.upcoming }));

      dispatch(showMessage({ messageText: { id: 'appointment.canceled.success.message' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
        form: action.form,
      });
    }

    done();
  },
});

export default cancelPatientAppointmentOperation;
