import type { UserRole } from 'constants/app';
import {
  accountPayoutRoute,
  patientAccountActivateRoute,
  patientAccountDeactivateRoute,
  patientAccountEmailRoute,
  patientProfileAvatarRoute,
  patientProfileContactInformationRoute,
  patientProfileHealthProfileRoute,
  patientProfilePersonalInformationRoute,
  patientProfileRoute,
  providerAccountActivateRoute,
  providerAccountDeactivateRoute,
  providerAccountEmailRoute,
  providerProfileAvatarRoute,
  providerProfileCancellationPolicyRoute,
  providerProfileChecklistRoute,
  providerProfileContactInformationRoute,
  providerProfileMedicalLicenseRoute,
  providerProfilePersonalInformationRoute,
  providerProfileRoute,
  providerProfileWorkingHourRoute,
  updatePasswordRoute,
  varifyAndLoginRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const updateProviderPersonalInformationEndpoint = endpoint(
  constants.UPDATE_PROVIDER_PERSONAL_INFORMATION,
  'PUT',
  providerProfilePersonalInformationRoute,
);

export const updatePatientPersonalInformationEndpoint = endpoint(
  constants.UPDATE_PATIENT_PERSONAL_INFORMATION,
  'PUT',
  patientProfilePersonalInformationRoute,
);

export const updateProviderContactInformationEndpoint = endpoint(
  constants.UPDATE_PROVIDER_CONTACT_INFORMATION,
  'PUT',
  providerProfileContactInformationRoute,
);

export const updatePatientContactInformationEndpoint = endpoint(
  constants.UPDATE_PATIENT_CONTACT_INFORMATION,
  'PUT',
  patientProfileContactInformationRoute,
);

export const updateProviderCancellationScenarioEndpoint = endpoint(
  constants.UPDATE_PROVIDER_CANCELLATION_SCENARIO,
  'PUT',
  providerProfileCancellationPolicyRoute,
);

export const deleteProviderAvatarEndpoint = endpoint(
  constants.UPDATE_PROVIDER_CANCELLATION_SCENARIO,
  'DELETE',
  providerProfileAvatarRoute,
);

export const updateProviderAvatarEndpoint = endpoint(
  constants.UPDATE_PROVIDER_AVATAR,
  'PUT',
  providerProfilePersonalInformationRoute,
);

export const deletePatientAvatarEndpoint = endpoint(
  constants.DELETE_PATIENT_AVATAR,
  'DELETE',
  patientProfileAvatarRoute,
);

export const updatePatientAvatarEndpoint = endpoint(
  constants.UPDATE_PATIENT_AVATAR,
  'PUT',
  patientProfilePersonalInformationRoute,
);

export const updateProviderEmailEndpoint = endpoint(constants.UPDATE_PROVIDER_EMAIL, 'POST', providerAccountEmailRoute);

export const updatePatientEmailEndpoint = endpoint(constants.UPDATE_PATIENT_EMAIL, 'POST', patientAccountEmailRoute);

export const fetchProviderProfileEndpoint = (type = constants.FETCH_PROVIDER_PROFILE) =>
  endpoint(type, 'GET', providerProfileRoute);
export const fetchPatientProfileEndpoint = (type = constants.FETCH_PATIENT_PROFILE) =>
  endpoint(type, 'GET', patientProfileRoute);

export const updatePatientHealthProfileEndpoint = endpoint(
  constants.UPDATE_PATIENT_HEALTH_PROFILE,
  'PUT',
  patientProfileHealthProfileRoute,
);

export const updatePasswordEndpoint = (role: UserRole) =>
  endpoint(constants.UPDATE_PASSWORD, 'POST', updatePasswordRoute(role));

export const updatePatientEmailByTokenEndpoint = endpoint(
  constants.UPDATE_PATIENT_EMAIL_BY_TOKEN,
  'PUT',
  patientAccountEmailRoute,
);

export const verifyAndLoginEndpoint = endpoint(constants.VERIFY_AND_LOGIN, 'PUT', varifyAndLoginRoute);

export const updateProviderEmailByTokenEndpoint = endpoint(
  constants.UPDATE_PROVIDER_EMAIL_BY_TOKEN,
  'PUT',
  providerAccountEmailRoute,
);

export const editProviderMedicalLicenseEndpoint = endpoint(
  constants.EDIT_PROVIDER_MEDICAL_LICENSE,
  'PUT',
  providerProfileMedicalLicenseRoute,
);

export const updateProviderSpecialityAndWorkingHoursEndpoint = endpoint(
  constants.UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS,
  'PUT',
  providerProfileWorkingHourRoute,
);

export const deactivateProviderAccountEndpoint = endpoint(
  constants.DEACTIVATE_PROVIDER_ACCOUNT,
  'PUT',
  providerAccountDeactivateRoute,
);
export const activateProviderAccountEndpoint = endpoint(
  constants.ACTIVATE_PROVIDER_ACCOUNT,
  'PUT',
  providerAccountActivateRoute,
);

export const deactivatePatientAccountEndpoint = endpoint(
  constants.DEACTIVATE_PATIENT_ACCOUNT,
  'PUT',
  patientAccountDeactivateRoute,
);
export const activatePatientAccountEndpoint = endpoint(
  constants.ACTIVATE_PATIENT_ACCOUNT,
  'PUT',
  patientAccountActivateRoute,
);

export const createPayoutMethodEndpoint = endpoint(constants.CREATE_PAYOUT_METHOD, 'POST', accountPayoutRoute);
export const fetchPayoutMethodEndpoint = endpoint(constants.FETCH_PAYOUT_METHOD, 'GET', accountPayoutRoute);
export const removePayoutMethodEndpoint = endpoint(constants.REMOVE_PAYOUT_METHOD, 'DELETE', accountPayoutRoute);

export const fetchChecklistEndpoint = endpoint(constants.FETCH_CHECKLIST, 'GET', providerProfileChecklistRoute);
