import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchChatAttachments } from '../actions';
import { setAttachmentIds } from '../actions';
import * as constants from '../constants';
import { fetchChatAttachmentsEndpoint } from '../endpoints';

const fetchChatAttachmentsOperation = createLogic<typeof fetchChatAttachments>({
  type: constants.FETCH_CHAT_ATTACHMENTS,
  latest: true,

  async process({ action: { chatId } }, dispatch, done) {
    const { endpoint, url } = fetchChatAttachmentsEndpoint(chatId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url);

      const response = normalize(data, { endpoint });

      dispatch(setAttachmentIds(data.data.map(({ id }: any) => id)));
      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchChatAttachmentsOperation;
