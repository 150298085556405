import { useDispatch, useSelector } from 'react-redux';

import { hideMessage as hideMessageAction } from 'state/flash-messages/actions';
import { flashMessagesSelector } from 'state/flash-messages/selectors';

const useContainer = () => {
  const dispatch = useDispatch();

  const messages = useSelector(flashMessagesSelector);

  /**
   * Hide message
   */
  const hideMessage = (params: { id: string }) => {
    dispatch(hideMessageAction(params));
  };

  return {
    hideMessage,
    messages,
  };
};

export default useContainer;
