import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import * as constants from './constants';

const modalReducer = combineReducers({
  modalProps: (state = null, action: AnyAction) => {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return constants.initialState.modalProps;
      case constants.SHOW_MODAL:
        return action.modalProps || {};
      default:
        return state;
    }
  },
  modalType: (state = null, action: AnyAction) => {
    switch (action.type) {
      case constants.HIDE_MODAL:
        return constants.initialState.modalType;
      case constants.SHOW_MODAL:
        return action.modalType;
      default:
        return state;
    }
  },
});

export default modalReducer;
