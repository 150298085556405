import { prop } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import { decUnreadNotificationsCount, deleteNotificationFromPanel } from '../actions';
import type { deleteNotification } from '../actions';
import { DELETE_NOTIFICATION } from '../constants';
import { deleteNotificationEndpoint } from '../endpoints';
import { notificationSelector, unreadNotificationsCountSelector } from '../selectors';

const deleteNotificationOperation = createLogic<typeof deleteNotification>({
  type: DELETE_NOTIFICATION,
  latest: true,

  async process({ getState, action }, dispatch, done) {
    const state = getState();
    const { endpoint, url } = deleteNotificationEndpoint(action.id);
    const readAt = prop('readAt', notificationSelector(state, action.id));
    const unreadCount = unreadNotificationsCountSelector(state);

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.delete(url);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(deleteNotificationFromPanel(action.id));
      if (readAt === null) {
        dispatch(decUnreadNotificationsCount(unreadCount));
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default deleteNotificationOperation;
