import {
  notificationSettingsRoute,
  notificationMarkAllAsReadRoute,
  notificationReadRoute,
  notificationUnreadRoute,
  notificationsRoute,
  notificationRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchNotificationSettingsEndpoint = endpoint(
  constants.FETCH_NOTIFICATION_SETTINGS,
  'GET',
  notificationSettingsRoute,
);

export const updateNotificationSettingsEndpoint = endpoint(
  constants.UPDATE_NOTIFICATION_SETTINGS,
  'PUT',
  notificationSettingsRoute,
);

export const fetchNotificationsEndpoint = endpoint(constants.FETCH_NOTIFICATIONS, 'GET', notificationsRoute);

export const readAllNotificationsEndpoint = endpoint(
  constants.READ_ALL_NOTIFICATIONS,
  'PUT',
  notificationMarkAllAsReadRoute,
);

export const readNotificationEndpoint = (id: string) =>
  endpoint(constants.READ_NOTIFICATION, 'PUT', notificationReadRoute(id));

export const unreadNotificationEndpoint = (id: string) =>
  endpoint(constants.UNREAD_NOTIFICATION, 'PUT', notificationUnreadRoute(id));

export const deleteNotificationEndpoint = (id: string) =>
  endpoint(constants.DELETE_NOTIFICATION, 'DELETE', notificationRoute(id));
