import Axios from 'axios';
import fileDownload from 'js-file-download';

import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import type { JsonApiError } from 'types/json-api/raw';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { downloadFile } from '../actions';
import { DOWNLOAD_FILE } from '../constants';
import { downloadFileEndpoint } from '../endpoints';

const downloadFileOperation = createLogic<typeof downloadFile>({
  type: DOWNLOAD_FILE,
  latest: true,

  async process({ action: { fileUrl, fileName } }, dispatch, done) {
    const { endpoint } = downloadFileEndpoint(fileUrl, fileName);

    dispatch(dataApiRequest({ endpoint }));
    try {
      const { data } = await Axios.get(fileUrl, {
        responseType: 'blob',
      });
      dispatch(dataApiSuccess({ endpoint }));

      await fileDownload(data, fileName);
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(dataApiFailure({ endpoint, status }));

      showErrorNotifications({ error, dispatch });
    }

    done();
  },
});

export default downloadFileOperation;
