import createPayoutMethod from './createPayoutMethod';
import deactivatePatientAccountOperation from './deactivatePatientAccount';
import deactivateProviderAccountOperation from './deactivateProviderAccount';
import deletePatientAvatar from './deletePatientAvatar';
import deleteProviderAvatar from './deleteProviderAvatar';
import fetchChecklistOperation from './fetchChecklist';
import fetchPatientProfile from './fetchPatientProfile';
import fetchPayoutMethod from './fetchPayoutMethod';
import fetchProviderProfile from './fetchProviderProfile';
import removePayoutMethod from './removePayoutMethod';
import updatePassword from './updatePassword';
import updatePatientAvatar from './updatePatientAvatar';
import updatePatientContactInformation from './updatePatientContactInformation';
import updatePatientEmail from './updatePatientEmail';
import updatePatientEmailByToken from './updatePatientEmailByToken';
import updatePatientHealthProfile from './updatePatientHealthProfile';
import updatePatientPersonalInformation from './updatePatientPersonalInformation';
import updateProviderAvatar from './updateProviderAvatar';
import updateProviderCancellationScenario from './updateProviderCancellationScenario';
import updateProviderContactInformation from './updateProviderContactInformation';
import updateProviderEmail from './updateProviderEmail';
import updateProviderEmailByToken from './updateProviderEmailByToken';
import updateProviderPersonalInformation from './updateProviderPersonalInformation';
import updateProviderSpecialityAndWorkingHours from './updateProviderSpecialityAndWorkingHours';

const operations = [
  fetchPayoutMethod,
  createPayoutMethod,
  removePayoutMethod,
  updateProviderPersonalInformation,
  updatePatientPersonalInformation,
  updatePatientEmail,
  updateProviderEmail,
  fetchProviderProfile,
  fetchPatientProfile,
  updateProviderContactInformation,
  updatePatientContactInformation,
  updateProviderCancellationScenario,
  deleteProviderAvatar,
  updateProviderAvatar,
  deletePatientAvatar,
  updatePatientAvatar,
  updatePatientHealthProfile,
  updatePassword,
  updatePatientEmailByToken,
  updateProviderEmailByToken,
  updateProviderSpecialityAndWorkingHours,
  deactivatePatientAccountOperation,
  deactivateProviderAccountOperation,
  fetchChecklistOperation,
];

export default operations;
