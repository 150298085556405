import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderPatientProfileById } from '../actions';
import { FETCH_PROVIDER_PATIENT_PROFILE_BY_ID } from '../constants';
import { fetchProviderPatientProfileByIdEndpoint } from '../endpoints';

const fetchProviderPatientProfileByIdOperation = createLogic<typeof fetchProviderPatientProfileById>({
  type: FETCH_PROVIDER_PATIENT_PROFILE_BY_ID,
  latest: true,

  async process({ action: { patientId } }, dispatch, done) {
    const { endpoint, url } = fetchProviderPatientProfileByIdEndpoint(patientId);

    try {
      const params = {
        include: 'contact_information,health_profile',
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderPatientProfileByIdOperation;
