import { assoc } from 'ramda';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import { USER_LOGOUT } from 'state/concepts/session/constants';

import * as constants from './constants';

export const calendarBoundariesInitialState = {
  min: null,
  current: null,
  max: null,
};
export const calendarBoundaries = (state = calendarBoundariesInitialState, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_SELECTED_DATE:
      return assoc('current', action.date, state);
    case constants.SET_CALENDAR_BOUNDARIES:
      return {
        min: action.min,
        current: action.current,
        max: action.max,
      };
    case USER_LOGOUT:
      return calendarBoundariesInitialState;
    default:
      return state;
  }
};

export const workingBrakes = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_WORKING_BRAKES:
      return action.workingBrakes;
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

export const workingHours = (state = {}, action: AnyAction) => {
  switch (action.type) {
    case constants.SET_WORKING_HOURS:
      return action.workingHours;
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
};

const calendarReducer = combineReducers({
  calendarBoundaries,
  workingBrakes,
  workingHours,
});

export default calendarReducer;
