import type { UserTitle } from 'types/entities/session';

import hasBoardCertification from './hasBoardCertification';
import hasMedicalDegree from './hasMedicalDegree';

const omitFieldsArray = (title: string, medicalTitles: UserTitle[]) => {
  const hasCertification = hasBoardCertification(title, medicalTitles);
  const hasDegree = hasMedicalDegree(title, medicalTitles);

  if (hasCertification && hasDegree) {
    return [];
  }

  if (hasDegree && !hasCertification) {
    return ['board_license_certifications'];
  }

  if (!hasDegree && hasCertification) {
    return ['medical_degree'];
  }

  return ['board_license_certifications', 'medical_degree'];
};

export default omitFieldsArray;
