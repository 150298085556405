import normalize from 'json-api-normalizer';

import { PATIENT_LIST_OF_ASSESSMENTS_LENGTH } from 'constants/patients';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderPatientProfileAssessmentsById } from '../actions';
import { setProviderPatientProfileAssessmentsIds, setProviderPatientProfileAssessmentsListTotal } from '../actions';
import { FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID } from '../constants';
import { fetchProviderPatientProfileAssessmentsByIdEndpoint } from '../endpoints';
import {
  providerPatientProfileAssessmentsPageNumberSelector,
  providerPatientProfileAssessmentsSearchSelector,
  providerPatientProfileAssessmentsSortParamsSelector,
} from '../selectors';

const fetchProviderPatientProfileAssessmentsByIdOperation = createLogic<
  typeof fetchProviderPatientProfileAssessmentsById
>({
  type: FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID,
  latest: true,

  async process({ getState, action: { patientId } }, dispatch, done) {
    const { endpoint, url } = fetchProviderPatientProfileAssessmentsByIdEndpoint(patientId);
    const state = getState();

    try {
      const params = {
        sort: providerPatientProfileAssessmentsSortParamsSelector(state),
        search: providerPatientProfileAssessmentsSearchSelector(state),
        page: {
          number: providerPatientProfileAssessmentsPageNumberSelector(state),
          size: PATIENT_LIST_OF_ASSESSMENTS_LENGTH,
        },
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setProviderPatientProfileAssessmentsIds(data.data.map(({ id }: any) => id)));
      dispatch(setProviderPatientProfileAssessmentsListTotal(data.meta.total));
    } catch (error) {
      dispatch(setProviderPatientProfileAssessmentsIds([]));
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchProviderPatientProfileAssessmentsByIdOperation;
