import {
  patientOnboardingPersonalInformationRoute,
  patientOnboardingContactInformationRoute,
  patientOnboardingHealthProfileRoute,
  providerOnboardingPersonalInformationRoute,
  providerOnboardingContactInformationRoute,
  providerOnboardingSpecialityAndWorkingHoursRoute,
  providerOnboardingMedicalLicenseRoute,
  providerOnboardingCancellationScenarioRoute,
  patientOnboardingRoute,
  providerOnboardingRoute,
  providerOnboardingSignatureRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

// Patient
export const updatePatientPersonalInformationEndpoint = endpoint(
  constants.UPDATE_PATIENT_PERSONAL_INFORMATION,
  'PUT',
  patientOnboardingPersonalInformationRoute,
);

export const updatePatientContactInformationEndpoint = endpoint(
  constants.UPDATE_PATIENT_CONTACT_INFORMATION,
  'PUT',
  patientOnboardingContactInformationRoute,
);

export const updatePatientHealthProfileEndpoint = endpoint(
  constants.UPDATE_PATIENT_HEALTH_PROFILE,
  'PUT',
  patientOnboardingHealthProfileRoute,
);

// Provider
export const updateProviderPersonalInformationEndpoint = endpoint(
  constants.UPDATE_PROVIDER_PERSONAL_INFORMATION,
  'PUT',
  providerOnboardingPersonalInformationRoute,
);

export const updateProviderContactInformationEndpoint = endpoint(
  constants.UPDATE_PROVIDER_CONTACT_INFORMATION,
  'PUT',
  providerOnboardingContactInformationRoute,
);

export const updateProviderSpecialityAndWorkingHoursEndpoint = endpoint(
  constants.UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS,
  'PUT',
  providerOnboardingSpecialityAndWorkingHoursRoute,
);

export const updateProviderMedicalLicenseEndpoint = endpoint(
  constants.UPDATE_PROVIDER_MEDICAL_LICENSE,
  'PUT',
  providerOnboardingMedicalLicenseRoute,
);

export const updateProviderCancellationScenarioEndpoint = endpoint(
  constants.UPDATE_PROVIDER_CANCELLATION_SCENARIO,
  'PUT',
  providerOnboardingCancellationScenarioRoute,
);

export const fetchPatientOnboardingEndpoint = endpoint(
  constants.FETCH_PATIENT_ONBOARDING,
  'GET',
  patientOnboardingRoute,
);

export const fetchProviderOnboardingEndpoint = endpoint(
  constants.FETCH_PROVIDER_ONBOARDING,
  'GET',
  providerOnboardingRoute,
);

export const updateProviderSignatureEndpoint = endpoint(
  constants.UPDATE_PROVIDER_SIGNATURE,
  'PUT',
  providerOnboardingSignatureRoute,
);
