import 'styles/application.scss';

import isUndefined from 'lodash/isUndefined';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import type { ReactElement, ReactNode } from 'react';
import { useRef, useEffect, useCallback } from 'react';
import type { MessageDescriptor } from 'react-intl';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import useDispatchAction from 'hooks/useDispatchAction';
import { setNetworkConnectionStatus, setCurrentAndPreviousPages, setCurrentPage } from 'state/app/actions';
import { currentUserProfileSelector } from 'state/concepts/session/selectors';
import wrapper from 'state/store';
import { closeConnection, openConnection } from 'state/webSockets/actions';
import { isClosedConnectionSelector, isOpenedConnectionSelector } from 'state/webSockets/selectors';
import ErrorHandler from 'views/errors/ErrorHandler';
import FlashMessagesRoot from 'views/FlashMessagesRoot';
import Meta from 'views/layouts/Meta';
import ModalRoot from 'views/ModalRoot';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
  title: MessageDescriptor;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function Application({ Component, pageProps: { ...pageProps } }: AppPropsWithLayout) {
  const { locale = 'en', defaultLocale } = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);

  const dispatch = useDispatch();

  const router = useRouter();

  const routerChangeState = useRef<{
    prevPage: string | null;
    currentPage: string | null;
  }>({
    prevPage: null,
    currentPage: null,
  });

  /**
   * Online handler.
   */
  const onlineHandler = useCallback(() => {
    dispatch(setNetworkConnectionStatus(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Offline handler.
   */
  const offlineHandler = useCallback(() => {
    dispatch(setNetworkConnectionStatus(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * On route change start
   */
  const routeChangeStart = (url: string) => {
    routerChangeState.current.currentPage = url;
    routerChangeState.current.prevPage = router.asPath;
  };

  /**
   * On route change error
   */
  const routeChangeError = () => {
    routerChangeState.current.currentPage = null;
    routerChangeState.current.prevPage = null;
  };

  /**
   * On route change complete
   */
  const routeChangeComplete = () => {
    dispatch(
      setCurrentAndPreviousPages({
        currentPage: routerChangeState.current.currentPage,
        prevPage: routerChangeState.current.prevPage,
      }),
    );
  };

  useEffect(() => {
    window.addEventListener('offline', offlineHandler);
    window.addEventListener('online', onlineHandler);
    router.events.on('routeChangeStart', routeChangeStart);
    router.events.on('routeChangeError', routeChangeError);
    router.events.on('routeChangeComplete', routeChangeComplete);

    dispatch(setCurrentPage(router.asPath));

    return () => {
      window.removeEventListener('offline', offlineHandler);
      window.removeEventListener('online', onlineHandler);
      router.events.off('routeChangeStart', routeChangeStart);
      router.events.off('routeChangeError', routeChangeError);
      router.events.off('routeChangeComplete', routeChangeComplete);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenConnection = useDispatchAction(openConnection);
  const handleCloseConnection = useDispatchAction(closeConnection);
  const profile = useSelector(currentUserProfileSelector);
  const prevProfile = useRef<any>(profile);
  const isClosed = useSelector(isClosedConnectionSelector);
  const isOpened = useSelector(isOpenedConnectionSelector);

  useEffect(() => {
    if (isClosed && prevProfile?.current?.id !== profile?.id) {
      handleOpenConnection();
      prevProfile.current = profile;
    }

    return () => {
      if (isOpened && isUndefined(profile?.id)) {
        handleCloseConnection();
        prevProfile.current = undefined;
      }
    };
  }, [handleCloseConnection, handleOpenConnection, isClosed, isOpened, profile]);

  return (
    // @ts-ignore
    <IntlProvider
      locale={locale}
      defaultLocale={defaultLocale}
      // @ts-ignore
      messages={pageProps.intlMessages}
      defaultRichTextElements={{
        // @ts-ignore
        breakline: <br />,
        // eslint-disable-next-line react/no-unstable-nested-components
        b: (chunks: any) => <b>{chunks}</b>,
      }}
    >
      <Meta title={Component.title} />
      <ErrorHandler>{getLayout(<Component {...pageProps} />)}</ErrorHandler>
      <ModalRoot />
      <FlashMessagesRoot />
    </IntlProvider>
  );
}

export default wrapper.withRedux(Application);
