import actionCreator from 'utils/state/actionCreator';

import * as constants from './constants';

export const setIsPreview = actionCreator((isPreview: boolean) => ({
  type: constants.SET_IS_PREVIEW,
  isPreview,
}));

export const setCallId = actionCreator((id: string | null) => ({
  type: constants.SET_CALL_ID,
  id,
}));

export const generatePatientVideoConferenceAccess = actionCreator(({ appointmentId }: { appointmentId: string }) => ({
  type: constants.GENERATE_PATIENT_VIDEO_CONFERENCE_ACCESS,
  appointmentId,
}));

export const generateProviderVideoConferenceAccess = actionCreator(({ appointmentId }: { appointmentId: string }) => ({
  type: constants.GENERATE_PROVIDER_VIDEO_CONFERENCE_ACCESS,
  appointmentId,
}));

export const setLocalSettings = actionCreator((settings) => ({
  type: constants.SET_LOCAL_SETTINGS,
  settings,
}));

export const resetLocalSettings = actionCreator(constants.RESET_LOCAL_SETTINGS);
export const setChatVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_CHAT_VISIBLE,
  visible,
}));

export const setQuestionnaireVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_QUESTIONNAIRE_VISIBLE,
  visible,
}));

export const setAssessmentVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_ASSESSMENT_VISIBLE,
  visible,
}));
