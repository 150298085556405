import type { FormikHelpers, FormikValues } from 'formik';

import { actionCreator } from 'utils/state';

const makeFormSubmitAction =
  <V extends FormikValues = FormikValues, P = object>() =>
  <T extends string>(type: T) =>
    actionCreator((values: V, form: FormikHelpers<V>, payload?: P) => ({
      ...payload,
      type,
      values,
      form,
    }));

export default makeFormSubmitAction;
