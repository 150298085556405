import { appOperations } from '../app';
import { flashMessagesOperations } from '../flash-messages';
import { webSocketOperations } from '../webSockets';

import { appointmentOperations } from './appointment';
import { assessmentsOperations } from './assessments';
import { bookAppointmentOperations } from './bookAppointment';
import { calendarOperations } from './calendar';
import { cancellationReasonOptionsOperations } from './cancellationReasonOption';
import { chatOperations } from './chat';
import { notificationOperations } from './notification';
import { onboardingOperations } from './onboarding';
import { patientProfileOperations } from './patient';
import { profileOperations } from './profile';
import { sessionOperations } from './session';
import { videoConferenceOperations } from './videoConference';

const operationsRoot = [
  ...chatOperations,
  ...appOperations,
  ...sessionOperations,
  ...videoConferenceOperations,
  ...flashMessagesOperations,
  ...onboardingOperations,
  ...profileOperations,
  ...patientProfileOperations,
  ...bookAppointmentOperations,
  ...calendarOperations,
  ...notificationOperations,
  ...appointmentOperations,
  ...assessmentsOperations,
  ...webSocketOperations,
  ...cancellationReasonOptionsOperations,
];

export default operationsRoot;
