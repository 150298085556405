import { propOr } from 'ramda';
import build from 'redux-object';
import { createSelector } from 'reselect';

import type { RootState } from 'state/store/types';
import type { PatientProfile, ProviderProfile } from 'types/entities/session';
import type { NormalizedResponseData } from 'types/json-api/normalized';

export const dataSelector = (state: RootState) => state.data;

export const currentUserIdSelector = (state: RootState) => state.session.currentUser?.id;

export const emailVerificationStatusSelector = (state: RootState) => state.session.emailVerificationStatus;

export const passwordRecoveryStatusSelector = (state: RootState) => state.session.passwordRecoveryStatus;

export const patientProfileIdSelector = (state: RootState) => state.session.patientProfileId;

export const providerProfileIdSelector = (state: RootState) => state.session.providerProfileId;

export const patientProfileSelector = createSelector(patientProfileIdSelector, dataSelector, (id, data) => {
  return id
    ? (build(data as NormalizedResponseData, 'patientProfile', id as string) as unknown as PatientProfile)
    : null;
});

export const providerProfileSelector = createSelector(providerProfileIdSelector, dataSelector, (id, data) =>
  id ? (build(data as NormalizedResponseData, 'userProfile', id as string) as unknown as ProviderProfile) : null,
);

export const providerProfileTimezoneSelector = createSelector(providerProfileSelector, (providerProfile) =>
  propOr(null, 'timezone', providerProfile),
);

export const currentUserProfileSelector = createSelector(
  patientProfileSelector,
  providerProfileSelector,
  (patientProfile, userProfile) => patientProfile || userProfile,
);
