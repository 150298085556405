import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { deleteChatAttachment } from '../actions';
import { deleteChatAttachmentSuccess } from '../actions';
import * as constants from '../constants';
import { deleteChatAttachmentEndpoint } from '../endpoints';

const deleteChatAttachmentOperation = createLogic<typeof deleteChatAttachment>({
  type: constants.DELETE_CHAT_ATTACHMENT,
  latest: true,

  async process({ action: { chatId, messageId } }, dispatch, done) {
    const { endpoint, url } = deleteChatAttachmentEndpoint(chatId, messageId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.delete(url);

      dispatch(deleteChatAttachmentSuccess(messageId as string));
      dispatch(dataApiSuccess({ endpoint }));
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default deleteChatAttachmentOperation;
