import { uniq, without } from 'ramda';
import { combineReducers } from 'redux';

import {
  SET_MESSAGES,
  FLUSH_MESSAGES,
  DELETE_CHAT_MESSAGE_SUCCESS,
  SET_UPLOAD_PROGRESS,
  SET_EDITING_CHAT_MESSAGE,
  SET_ATTACHMENT_IDS,
  SET_SELECTED_ATTACHMENT_ID,
  DELETE_CHAT_ATTACHMENT_SUCCESS,
} from './constants';
import type { ChatActions, ChatState } from './types';

const messageIds = (state = [], action: ChatActions) => {
  switch (action.type) {
    case SET_MESSAGES:
      return uniq([...action.messageIds, ...state]);
    case FLUSH_MESSAGES:
      return [];
    case DELETE_CHAT_MESSAGE_SUCCESS:
      return without(action.id as any, state);
    default:
      return state;
  }
};

const uploadProgress = (
  state: ChatState['uploadProgress'] = { fileName: null, progress: null },
  action: ChatActions,
) => {
  switch (action.type) {
    case SET_UPLOAD_PROGRESS:
      return {
        fileName: action.fileName,
        progress: action.progress,
      };
    default:
      return state;
  }
};

const editingMessage = (state: ChatState['editingMessage'] = null, action: ChatActions) => {
  switch (action.type) {
    case SET_EDITING_CHAT_MESSAGE:
      return action.message;
    default:
      return state as null;
  }
};

const attachmentsIds = (state: ChatState['attachmentsIds'] = [], action: ChatActions) => {
  switch (action.type) {
    case SET_ATTACHMENT_IDS:
      return uniq([...action.ids, ...state]);
    case DELETE_CHAT_ATTACHMENT_SUCCESS:
      return without(action.id as any, state);
    default:
      return state;
  }
};

const selectedAttachmentId = (state: ChatState['selectedAttachmentId'] = null, action: ChatActions) => {
  switch (action.type) {
    case SET_SELECTED_ATTACHMENT_ID:
      return action.id;
    default:
      return state as null;
  }
};

export default combineReducers<ChatState, ChatActions>({
  messageIds,
  uploadProgress,
  editingMessage,
  attachmentsIds,
  selectedAttachmentId,
});
