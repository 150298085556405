import type { patientAppointmentFilterStatuses } from 'constants/appointment';
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import actionCreator from 'utils/state/actionCreator';
import type { QuestionnaireEditFormTypes } from 'views/shared/QuestionnaireForm/types';

import * as constants from './constants';
import type { CancelAppointmentTypes } from './types';

export const fetchPatientAppointments = actionCreator(
  ({ sort = 'start_time', status }: { sort?: string; status: keyof typeof patientAppointmentFilterStatuses }) => ({
    type: constants.FETCH_PATIENT_APPOINTMENTS,
    status,
    sort,
  }),
);

export const fetchProviderInitialAppointments = actionCreator(
  ({
    startTime,
    endTime,
    timezone,
    status,
  }: {
    startTime: Date;
    endTime: Date;
    timezone: string;
    status?: string[] | null;
  }) => ({
    type: constants.FETCH_PROVIDER_INITIAL_APPOINTMENTS,
    status,
    startTime,
    endTime,
    timezone,
  }),
);

export type ProviderAppointmentsValues = {
  unconfirmed: {
    status: string[];
  };
  confirmed: {
    status: string[];
  };
  startTime: Date;
  endTime: Date;
  timezone: string;
};

export const fetchProviderAppointments = makeFormSubmitAction<ProviderAppointmentsValues>()(
  constants.FETCH_PROVIDER_APPOINTMENTS,
);

export const setAppointmentIds = actionCreator((ids: string[]) => ({
  type: constants.SET_APPOINTMENT_IDS,
  ids,
}));
export const setAssessmentVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_ASSESSMENT_VISIBLE,
  visible,
}));

export const setDrawerVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_DRAWER_VISIBLE,
  visible,
}));

export const setQuestionnaireVisible = actionCreator(({ visible }: { visible: boolean }) => ({
  type: constants.SET_QUESTIONNAIRE_VISIBLE,
  visible,
}));

export const setAppointmentsListTotal = actionCreator((total: number) => ({
  type: constants.SET_APPOINTMENTS_LIST_TOTAL,
  total,
}));

export const setCurrentAppointmentsPage = actionCreator((page: number) => ({
  type: constants.SET_CURRENT_APPOINTMENTS_PAGE,
  page,
}));

export const cancelPatientAppointment = makeFormSubmitAction<CancelAppointmentTypes>()(
  constants.PATIENT_CANCEL_APPOINTMENT,
);

export const cancelProviderAppointment = makeFormSubmitAction<CancelAppointmentTypes>()(
  constants.PROVIDER_CANCEL_APPOINTMENT,
);

export const fetchProviderAppointmentByIdWithChat = actionCreator((id: string) => ({
  type: constants.FETCH_PROVIDER_APPOINTMENT_BY_ID_WITH_CHAT,
  id,
}));

export interface RescheduleAppointmentValues {
  startTime: string | undefined;
  appointmentId: string;
}

export const reschedulePatientAppointment = makeFormSubmitAction<RescheduleAppointmentValues>()(
  constants.RESCHEDULE_PATIENT_APPOINTMENT,
);

export const submitEditAppointmentQuestionnaire = makeFormSubmitAction<QuestionnaireEditFormTypes>()(
  constants.SUBMIT_EDIT_QUESTIONNAIRE,
);

export const fetchProviderAppointmentsAssessmentById = actionCreator((id: string) => ({
  type: constants.FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID,
  id,
}));

export const fetchPatientAppointmentByIdWithChat = actionCreator((id: string) => ({
  type: constants.FETCH_PATIENT_APPOINTMENT_BY_ID_WITH_CHAT,
  id,
}));
