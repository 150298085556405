import { defaultTo, map, pipe } from 'ramda';

export const makeOptionsWithValueId = pipe(
  defaultTo([]),
  map((item: any) => ({ ...item, value: item.id })),
);

export const makeOptionsWithValueAndName = pipe(
  defaultTo([]),
  map((item: any) => ({ ...item, value: item.key, name: item.label })),
);

export const makeOptionsWithValueIdNameTitle = pipe(
  defaultTo([]),
  map((item: any) => ({ ...item, value: item.id, name: item.title })),
);

export const makeOptionsWithValueCode = pipe(
  defaultTo([]),
  map((item: any) => ({ ...item, value: item.code })),
);
