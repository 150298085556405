import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { deleteChatMessage } from '../actions';
import { deleteChatAttachmentSuccess, deleteChatMessageSuccess } from '../actions';
import * as constants from '../constants';
import { deleteChatMessageEndpoint } from '../endpoints';
import { messageByIdSelector } from '../selectors';

const deleteChatMessageOperation = createLogic<typeof deleteChatMessage>({
  type: constants.DELETE_CHAT_MESSAGE,
  throttle: 500,
  latest: true,

  async process({ action: { chatId, messageId }, getState }, dispatch, done) {
    const { endpoint, url } = deleteChatMessageEndpoint(chatId, messageId);
    const message = messageByIdSelector(getState(), messageId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.delete(url);

      dispatch(deleteChatMessageSuccess(messageId as string));
      dispatch(deleteChatAttachmentSuccess(message?.attachment?.id as string));
      dispatch(dataApiSuccess({ endpoint }));
      dispatch(showMessage({ messageText: { id: 'appointment.chat.removed.success' } }));
      dispatch(hideModal());
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default deleteChatMessageOperation;
