import type { FlashMessageProps } from '../types';

import useContainer from './hook';

function FlashMessage(props: FlashMessageProps) {
  useContainer(props);

  return null;
}

export default FlashMessage;
