import createMessageOperation from './createChatMessage';
import deleteChatAttachmentOperation from './deleteChatAttachment';
import deleteChatMessageOperation from './deleteChatMessage';
import fetchChatAttachmentsOperation from './fetchChatAttachments';
import fetchChatMessagesOperation from './fetchChatMessages';
import markMessagesAsReadOperation from './markMessagesAsRead';
import updateChatMessageOperation from './updateChatMessage';

const operations = [
  deleteChatMessageOperation,
  fetchChatMessagesOperation,
  createMessageOperation,
  updateChatMessageOperation,
  fetchChatAttachmentsOperation,
  deleteChatAttachmentOperation,
  markMessagesAsReadOperation,
];

export default operations;
