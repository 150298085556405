import normalize from 'json-api-normalizer';
import { pluck } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProvidersSearchOptions } from '../actions';
import { setProvidersSearchOptionsIds } from '../actions';
import { FETCH_PROVIDERS_SEARCH_OPTIONS } from '../constants';
import { fetchProvidersSearchOptionsEndpoint } from '../endpoints';

const fetchProvidersSearchOptionsOperation = createLogic<typeof fetchProvidersSearchOptions>({
  type: FETCH_PROVIDERS_SEARCH_OPTIONS,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchProvidersSearchOptionsEndpoint;

    let params = null;

    if (action.search) {
      params = {
        search: action.search,
      };
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      dispatch(setProvidersSearchOptionsIds(pluck<'id', any>('id', data.data)));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchProvidersSearchOptionsOperation;
