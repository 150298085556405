import format from 'date-fns/format';
import normalize from 'json-api-normalizer';
import { pluck, path } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchProviderAvailableTimeSlotsById } from '../actions';
import { setProviderAvailableTimeSlots, setProviderAllowedStateMeta } from '../actions';
import * as constants from '../constants';
import { fetchProviderAvailableTimeSlotsByIdEndpoint } from '../endpoints';

const fetchProviderAvailableTimeSlotsByIdOperation = createLogic<typeof fetchProviderAvailableTimeSlotsById>({
  type: constants.FETCH_PROVIDER_AVAILABLE_TIME_SLOTS_BY_ID,
  latest: true,

  async process({ action: { id: providerId, dateFrom, dateTo } }, dispatch, done) {
    const { endpoint, url } = fetchProviderAvailableTimeSlotsByIdEndpoint(providerId);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const params = {
        filter: {
          date_from: format(dateFrom, 'yyyy-MM-dd'),
          date_to: format(dateTo, 'yyyy-MM-dd'),
        },
      };

      const { data } = await httpClient.get(url, {
        params,
      });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setProviderAvailableTimeSlots(pluck<any, any>('id', data.data)));
      dispatch(setProviderAllowedStateMeta(path<any>(['meta', 'allowed_state'], data)));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchProviderAvailableTimeSlotsByIdOperation;
