import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { compose, groupBy, sortBy, prop, filter, identity, path, propOr } from 'ramda';

import type { Message } from 'types/entities/chat';
import type { PatientProfile, ProviderProfile } from 'types/entities/session';

export const subscribeChatCommand = (id: string) => ({
  command: 'message',
  channel: 'ChatChannel',
  action: 'subscribe',
  params: {
    id,
  },
});

export const groupMessagesByDate = compose(
  groupBy(({ createdAt }) => format(new Date(createdAt), 'EEE, LLLL dd, yyyy')),
  sortBy(compose(parseISO, prop('createdAt'))),
  filter<any>(identity),
);

export const isCurrentUsersMessage = (message: Message, currentUser: PatientProfile | ProviderProfile | null) => {
  const messageChatUserId = path(['chatUser', 'chatable', 'id'], message);
  const currentChatUserId = (propOr(null, 'id', currentUser) as any)?.toString();

  return messageChatUserId === currentChatUserId;
};

export const isUnreadMessage = (message: Message, currentChatUserId: number | string | undefined) => {
  if (!prop('chatUser', message) || !currentChatUserId) {
    return false;
  }

  return !message.readAt && message.chatUser?.chatable?.id !== currentChatUserId.toString();
};
