import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'calendar';

export const SET_SELECTED_DATE = createActionType(CONCEPT_NAME, 'SET_SELECTED_DATE');
export const SET_CALENDAR_BOUNDARIES = createActionType(CONCEPT_NAME, 'SET_CALENDAR_BOUNDARIES');
export const SET_WORKING_BRAKES = createActionType(CONCEPT_NAME, 'SET_WORKING_BRAKES');
export const SET_WORKING_HOURS = createActionType(CONCEPT_NAME, 'SET_WORKING_HOURS');
export const RESET_TO_WEEKLY_HOURS = createActionType(CONCEPT_NAME, 'RESET_TO_WEEKLY_HOURS');
export const CREATE_WORKING_BREAK = createActionType(CONCEPT_NAME, 'CREATE_WORKING_BREAK');
export const CREATE_SINGLE_DATE_AVAILABILITY = createActionType(CONCEPT_NAME, 'CREATE_SINGLE_DATE_AVAILABILITY');
export const FETCH_PROVIDER_CALENDAR = createActionType(CONCEPT_NAME, 'FETCH_PROVIDER_CALENDAR');
