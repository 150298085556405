// ---Session---
export const userSessionRoute = '/session';
export const userLoginRoute = '/auth/login';
export const userSignUpRoute = (role: string) => `/auth/sign-up?role=${role}`;
export const userLogoutRoute = '/auth/logout';
export const resendVerificationLinkRoute = '/confirmation/resend';
export const patientSignUpRoute = '/patients/registration';
export const providerSignUpRoute = '/users/registration';
export const checkEmailCorrectnessRoute = '/confirmation';
export const resetPasswordRoute = '/reset_password';

// ---Cancellation reason option---
export const cancellationReasonOptionsRoute = '/cancellation_reason_options';

// ---Common---
export const socialLinkListRoute = '/settings';
export const specialtiesRoute = '/specialities';
export const timezonesRoute = '/timezones';
export const medicalTitlesRoute = '/medical_titles';
export const contactUsRoute = '/contact_us';
export const statesRoute = '/states';
export const symptomsRoute = '/symptoms';
export const boardCertificationsRoute = '/board_certifications';
export const notificationSettingsRoute = '/notification_settings';
export const notificationsRoute = '/notifications';
export const notificationRoute = (id: string) => `/notifications/${id}`;
export const notificationMarkAllAsReadRoute = '/notifications/mark_all_as_read';
export const notificationReadRoute = (id: string) => `/notifications/${id}/read`;
export const notificationUnreadRoute = (id: string) => `/notifications/${id}/unread`;
export const varifyAndLoginRoute = '/auth/verify-and-login';

// ---Book Appointment---
export const patientSearchRoute = '/patients/search';
export const patientAppointmentsRoute = '/patients/appointments';
export const patientAppointmentDetailsRoute = (id: string) => `/patients/appointments/${id}`;
export const patientBookingAppointmentRoute = (id: string) => `/patients/booking/appointments/${id}`;
export const patientAppointmentGeneralInfoRoute = (id: string) => `/patients/appointments/${id}/general_information`;
export const patientAppointmentPaymentRoute = (id: string) => `/patients/appointments/${id}/paying`;
export const patientAppointmentCancelRoute = (id: string) => `/patients/appointments/${id}/cancel`;
export const patientAppointmentQuestionnairesRoute = (id: string) => `/patients/appointments/${id}/questionnaire`;
export const patientAppointmentRescheduleRoute = (id: string) => `/patients/appointments/${id}/reschedule`;
export const patientAppointmentVideoCallRoute = (id: string) => `/patients/appointments/${id}/video_call`;
export const patientSearchAutocompleteRoute = '/patients/search/autocomplete';

export const providerAppointmentsRoute = '/users/appointments';
export const providerAppointmentCancelRoute = (id: string) => `/users/appointments/${id}/cancel`;
export const providerAppointmentDetailsRoute = (id: string) => `/users/appointments/${id}`;
export const providerAppointmentAssessmentsRoute = (id: string) => `/users/appointments/${id}/assessments`;
export const providerAppointmentVideoCallRoute = (id: string) => `/users/appointments/${id}/video_call`;

// ---Provider Assessments---
export const providerAssessmentDetailRoute = (id: string) => `/users/assessments/${id}`;
// ---Provider Assessments-diagnoses---
export const providerAssessmentDiagnosesRoute = '/users/diagnoses';

// ---Book Appointment/Provider profile
export const patientProviderProfileRoute = (id: string) => `/patients/users/profiles/${id}`;
export const patientProviderProfileTimeSlotsRoute = (id: string) => `/patients/users/profiles/${id}/time_slots`;
// --- Appointment Questionnaires
export const patientQuestionnairesDetailsRoute = (id: string) => `/patients/questionnaires/${id}`;
// ---Onboarding Patient---
export const patientOnboardingPersonalInformationRoute = '/patients/onboarding/personal_information';
export const patientOnboardingContactInformationRoute = '/patients/onboarding/contact_information';
export const patientOnboardingHealthProfileRoute = '/patients/onboarding/health_profile';
export const patientOnboardingRoute = '/patients/onboarding';

// ---Onboarding Provider---
export const providerOnboardingPersonalInformationRoute = '/users/onboarding/personal_information';
export const providerOnboardingContactInformationRoute = '/users/onboarding/contact_information';
export const providerOnboardingSpecialityAndWorkingHoursRoute = '/users/onboarding/working_hour';
export const providerOnboardingMedicalLicenseRoute = '/users/onboarding/medical_license';
export const providerOnboardingCancellationScenarioRoute = '/users/onboarding/cancellation_policy';
export const providerOnboardingSignatureRoute = '/users/onboarding/signature';
export const providerOnboardingRoute = '/users/onboarding';

// ---Profile Common---
export const updatePasswordRoute = (role: string) => `/profile/password-update?role=${role}`;
export const accountPayoutRoute = '/accounts/payout_method';

// ---Profile provider---
export const providerProfileRoute = '/users/profile';
export const providerAccountEmailRoute = '/users/accounts/email';
export const providerProfilePersonalInformationRoute = '/users/profiles/personal_information';
export const providerProfileContactInformationRoute = '/users/profiles/contact_information';
export const providerProfileCancellationPolicyRoute = '/users/profiles/cancellation_policy';
export const providerProfileWorkingHourRoute = '/users/profiles/working_hour';
export const providerProfileAvatarRoute = '/users/profiles/avatar';
export const providerAccountPasswordRoute = '/users/accounts/password';
export const providerAccountDeactivateRoute = '/users/accounts/deactivate';
export const providerAccountActivateRoute = '/users/accounts/activate';
export const providerProfileCalendarRoute = '/users/profiles/calendar';
export const providerProfileDateAvailabilityRoute = '/users/profiles/date_availability';
export const providerProfileMedicalLicenseRoute = '/users/profiles/medical_licenses/';
export const providerProfileChecklistRoute = '/users/profiles/checklists';

// ---Provider - patient profile---
export const providerPatientProfileRoute = (id: string) => `/users/patients/${id}`;
export const providerPatientProfileAppointmentsRoute = (id: string) => `/users/patients/${id}/appointments`;
export const providerPatientProfileAssessmentsRoute = (id: string) => `/users/patients/${id}/assessments`;
export const providerPatientProfileAssessmentRoute = (patientId: string, assessmentId: string) =>
  `/users/patients/${patientId}/assessments/${assessmentId}`;
export const providerPatientProfilesRoute = `/users/patients`;

// ---Profile patient---
export const patientProfileRoute = '/patients/profile';
export const patientAccountEmailRoute = '/patients/accounts/email';
export const patientProfilePersonalInformationRoute = '/patients/profile/personal_information';
export const patientProfileContactInformationRoute = '/patients/profile/contact_information';
export const patientProfileHealthProfileRoute = '/patients/profile/health_profile';
export const patientProfileAvatarRoute = '/patients/profile/avatar';
export const patientAccountPasswordRoute = '/patients/accounts/password';
export const patientAccountDeactivateRoute = '/patients/accounts/deactivate';
export const patientAccountActivateRoute = '/patients/accounts/activate';

// ---Patient assessments---
export const patientAssessmentsRoute = '/patients/assessments';
export const patientAssessmentRoute = (assessmentId: string) => `/patients/assessments/${assessmentId}`;
export const downloadPatientAssessmentRoute = (assessmentId: string) =>
  `/patients/assessments/${assessmentId}/download_pdf`;

// ---Web sockets---
export const webSocketsAuthenticateRoute = '/web_sockets/authenticate';

// ---Chats---
export const chatMessagesRoute = (chatId: string) => `/chats/${chatId}/messages`;
export const chatMessagesMessageRoute = (chatId: string, messageId: string) => `/chats/${chatId}/messages/${messageId}`;
export const chatsMessagesReadRoute = (chatId: string) => `/chats/${chatId}/messages/read`;
export const chatAttachmentsRoute = (chatId: string) => `/chats/${chatId}/attachments`;
export const chatAttachmentRoute = (attachmentId: string, chatId: string) =>
  `/chats/${chatId}/attachments/${attachmentId}`;
