import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

import * as types from './constants';
import type { SocialLinksProps, SocialLinksActions } from './types';

export const isOnline = (state = true, action: AnyAction) => {
  switch (action.type) {
    case types.SET_NETWORK_CONNECTION_STATUS:
      return action.isOnline;
    default:
      return state;
  }
};

export const isServerReady = (state = true, action: AnyAction) => {
  switch (action.type) {
    case types.SET_SERVER_CONNECTION_STATUS:
      return action.isReady;
    default:
      return state;
  }
};

export const providerSpecialtiesIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_PROVIDER_SPECIALTIES_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const availableTimezonesIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_AVAILABLE_TIMEZONES_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const availableMedicalTitlesIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_AVAILABLE_MEDICAL_TITLES_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const availableStatesIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_AVAILABLE_STATES_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const boardLicensesIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_BOARD_LICENCE_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const routerPages = (state = { currentPage: null, prevPage: null }, action: AnyAction) => {
  switch (action.type) {
    case types.SET_CURRENT_AND_PREVIOUS_PAGES:
      return {
        currentPage: action.currentPage,
        prevPage: action.prevPage,
      };
    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.page,
      };
    default:
      return state;
  }
};

export const symptomsIds = (state = [], action: AnyAction) => {
  switch (action.type) {
    case types.SET_SYMPTOMS_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const socialLinksList = (
  state: SocialLinksProps = { facebook: '', twitter: '', instagram: '' },
  action: SocialLinksActions,
) => {
  switch (action.type) {
    case types.SET_SOCIAL_LINK_LIST:
      return action.socialLinks;
    default:
      return state;
  }
};

const appReducer = combineReducers({
  availableMedicalTitlesIds,
  availableStatesIds,
  availableTimezonesIds,
  isOnline,
  isServerReady,
  providerSpecialtiesIds,
  routerPages,
  boardLicensesIds,
  symptomsIds,
  socialLinksList,
});

export default appReducer;
