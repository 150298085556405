/**
 * Patient
 */
const onboardingPatientPathChunk = '/onboarding/patient';
const onboardingPatientKeyChunk = '/ONBOARDING/PATIENT';

const dashboardPatientPathChunk = '/dashboard/patient';
const dashboardPatientKeyChunk = '/DASHBOARD/PATIENT';

/**
 * Provider
 */
const onboardingProviderPathChunk = '/onboarding/provider';
const onboardingProviderKeyChunk = '/ONBOARDING/PROVIDER';

const dashboardProviderPathChunk = '/dashboard/provider';
const dashboardProviderKeyChunk = '/DASHBOARD/PROVIDER';

const ROUTES = {
  HOME: {
    KEY: 'HOME',
    PATH: '/',
  },
  REGISTRATION: {
    KEY: 'REGISTRATION',
    PATH: '/registration',
  },
  SIGN_IN: {
    KEY: 'SIGN_IN',
    PATH: '/sign-in',
  },
  SIGN_UP: {
    KEY: 'SIGN_UP',
    PATH: '/[role]/sign-up',
  },
  VERIFY_EMAIL: {
    KEY: 'VERIFY_EMAIL',
    PATH: '/[role]/verify-email',
  },
  EMAIL_VERIFICATION: {
    KEY: 'EMAIL_VERIFICATION',
    PATH: '/[role]/email-verification',
  },
  EMAIL_UPDATE_VERIFICATION: {
    KEY: 'EMAIL_UPDATE_VERIFICATION',
    PATH: '/[role]/email-update-verification',
  },
  EMAIL_VERIFICATION_EXPIRED: {
    KEY: 'EMAIL_VERIFICATION_EXPIRED',
    PATH: '/[role]/email-verification-expired',
  },
  DASHBOARD: {
    PATIENT: {
      DOCTORS: {
        KEY: `${dashboardPatientKeyChunk}/DOCTORS_LIST`,
        PATH: `${dashboardPatientPathChunk}/doctors`,
      },
      DOCTORS_PROFILE: {
        KEY: `${dashboardPatientKeyChunk}/DOCTORS_LIST`,
        PATH: (id: string) => `${dashboardPatientPathChunk}/doctors/${id}`,
      },
      ASSESSMENTS: {
        KEY: `${dashboardPatientKeyChunk}/ASSESSMENTS`,
        PATH: `${dashboardPatientPathChunk}/assessments`,
      },
      ASSESSMENTS_PRESCRIPTION: {
        KEY: `${dashboardPatientKeyChunk}/ASSESSMENTS`,
        PATH: (id: string) => `${dashboardPatientPathChunk}/assessments/${id}`,
      },
      APPOINTMENTS: {
        KEY: `${dashboardPatientKeyChunk}/APPOINTMENTS`,
        PATH: `${dashboardPatientPathChunk}/appointments`,
      },
      CONSULTATION: {
        KEY: `${dashboardPatientKeyChunk}/CONSULTATION`,
        PATH: (id: string) => `${dashboardPatientPathChunk}/appointments/${id}`,
      },
      VIDEO_CONFERENCE: {
        KEY: `${dashboardPatientKeyChunk}/VIDEO_CONFERENCE`,
        PATH: (id: string) => `${dashboardPatientPathChunk}/appointments/${id}/video_conference`,
      },
      ACCOUNT_SETTINGS: {
        KEY: `${dashboardPatientKeyChunk}/ACCOUNT_SETTINGS`,
        PATH: `${dashboardPatientPathChunk}/account-settings`,
      },
      PERSONAL_INFORMATION: {
        KEY: `${dashboardPatientKeyChunk}/PERSONAL_INFORMATION`,
        PATH: `${dashboardPatientPathChunk}/personal-information`,
      },
      EDIT_PERSONAL_INFORMATION: {
        KEY: `${dashboardPatientKeyChunk}/EDIT_PERSONAL_INFORMATION`,
        PATH: `${dashboardPatientPathChunk}/personal-information/edit`,
      },
      CONTACT_INFORMATION: {
        KEY: `${dashboardPatientKeyChunk}/CONTACT_INFORMATION`,
        PATH: `${dashboardPatientPathChunk}/contact-information`,
      },
      EDIT_CONTACT_INFORMATION: {
        KEY: `${dashboardPatientKeyChunk}/EDIT_CONTACT_INFORMATION`,
        PATH: `${dashboardPatientPathChunk}/contact-information/edit`,
      },
      HEALTH_PROFILE: {
        KEY: `${dashboardPatientKeyChunk}/HEALTH_PROFILE`,
        PATH: `${dashboardPatientPathChunk}/health-profile`,
      },
      EDIT_HEALTH_PROFILE: {
        KEY: `${dashboardPatientKeyChunk}/EDIT_HEALTH_PROFILE`,
        PATH: `${dashboardPatientPathChunk}/health-profile/edit`,
      },
      BOOK_APPOINTMENT: {
        KEY: `${dashboardPatientKeyChunk}/BOOK_APPOINTMENT`,
        PATH: (id: string) => `${dashboardPatientPathChunk}/book-appointment/${id}`,
      },
    },
    PROVIDER: {
      ACCOUNT_SETTINGS: {
        KEY: `${dashboardProviderKeyChunk}/ACCOUNT_SETTINGS`,
        PATH: `${dashboardProviderPathChunk}/account-settings`,
      },
      APPOINTMENTS: {
        KEY: `${dashboardProviderKeyChunk}/APPOINTMENTS`,
        PATH: `${dashboardProviderPathChunk}/appointments`,
      },
      CONSULTATION: {
        KEY: `${dashboardProviderKeyChunk}/CONSULTATION`,
        PATH: (id: string) => `${dashboardProviderPathChunk}/appointments/${id}`,
      },
      VIDEO_CONFERENCE: {
        KEY: `${dashboardProviderKeyChunk}/VIDEO_CONFERENCE`,
        PATH: (id: string) => `${dashboardProviderPathChunk}/appointments/${id}/video_conference`,
      },
      PERSONAL_INFORMATION: {
        KEY: `${dashboardProviderKeyChunk}/PERSONAL_INFORMATION`,
        PATH: `${dashboardProviderPathChunk}/personal-information`,
      },
      EDIT_PERSONAL_INFORMATION: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_PERSONAL_INFORMATION`,
        PATH: `${dashboardProviderPathChunk}/personal-information/edit`,
      },
      CONTACT_INFORMATION: {
        KEY: `${dashboardProviderKeyChunk}/CONTACT_INFORMATION`,
        PATH: `${dashboardProviderPathChunk}/contact-information`,
      },
      EDIT_CONTACT_INFORMATION: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_CONTACT_INFORMATION`,
        PATH: `${dashboardProviderPathChunk}/contact-information/edit`,
      },
      CANCELLATION_SCENARIO: {
        KEY: `${dashboardProviderKeyChunk}/CANCELLATION_SCENARIO`,
        PATH: `${dashboardProviderPathChunk}/cancellation-scenario`,
      },
      EDIT_CANCELLATION_SCENARIO: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_CANCELLATION_SCENARIO`,
        PATH: `${dashboardProviderPathChunk}/cancellation-scenario/edit`,
      },
      MEDICAL_LICENCE: {
        KEY: `${dashboardProviderKeyChunk}/MEDICAL_LICENCE`,
        PATH: `${dashboardProviderPathChunk}/medical-licence`,
      },
      EDIT_MEDICAL_LICENCE: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_MEDICAL_LICENCE`,
        PATH: `${dashboardProviderPathChunk}/medical-licence/edit`,
      },
      SPECIALITY_AND_HOURS_LIST: {
        KEY: `${dashboardProviderKeyChunk}/SPECIALITY_AND_HOURS_LIST`,
        PATH: `${dashboardProviderPathChunk}/speciality-and-hours/list`,
      },
      EDIT_SPECIALITY_AND_HOURS_LIST: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_SPECIALITY_AND_HOURS_LIST`,
        PATH: `${dashboardProviderPathChunk}/speciality-and-hours/list/edit`,
      },
      SPECIALITY_AND_HOURS_CALENDAR: {
        KEY: `${dashboardProviderKeyChunk}/SPECIALITY_AND_HOURS_CALENDAR`,
        PATH: `${dashboardProviderPathChunk}/speciality-and-hours/calendar`,
      },
      EDIT_SPECIALITY_AND_HOURS_CALENDAR: {
        KEY: `${dashboardProviderKeyChunk}/EDIT_SPECIALITY_AND_HOURS_CALENDAR`,
        PATH: `${dashboardProviderPathChunk}/speciality-and-hours/calendar/edit`,
      },
      ACCOUNT_STATUS: {
        KEY: `${dashboardProviderKeyChunk}/ACCOUNT_STATUS`,
        PATH: `${dashboardProviderPathChunk}/account-status`,
      },
      PAYOUT_INFORMATION: {
        KEY: `${dashboardProviderKeyChunk}/PAYOUT_INFORMATION`,
        PATH: `${dashboardProviderPathChunk}/payout-information`,
      },
      PATIENTS: {
        KEY: `${dashboardProviderKeyChunk}/PATIENTS`,
        PATH: `${dashboardProviderPathChunk}/patients`,
      },
      PATIENT_DETAILS: {
        KEY: `${dashboardProviderKeyChunk}/PATIENTS`,
        PATH: (id: string) => `${dashboardProviderPathChunk}/patients/${id}`,
      },
    },
    SUPPORT: {
      KEY: 'SUPPORT',
      PATH: '/support',
    },
  },
  ONBOARDING: {
    PATIENT: {
      PERSONAL_INFORMATION: {
        KEY: `${onboardingPatientKeyChunk}/PERSONAL_INFORMATION`,
        PATH: `${onboardingPatientPathChunk}/personal-information`,
      },
      CONTACT_INFORMATION: {
        KEY: `${onboardingPatientKeyChunk}/CONTACT_INFORMATION`,
        PATH: `${onboardingPatientPathChunk}/contact-information`,
      },
      HEALTH_PROFILE: {
        KEY: `${onboardingPatientKeyChunk}/HEALTH_PROFILE`,
        PATH: `${onboardingPatientPathChunk}/health-profile`,
      },
      DONE: {
        KEY: `${onboardingPatientKeyChunk}/DONE`,
        PATH: `${onboardingPatientPathChunk}/done`,
      },
    },
    PROVIDER: {
      PERSONAL_INFORMATION: {
        KEY: `${onboardingProviderKeyChunk}/PERSONAL_INFORMATION`,
        PATH: `${onboardingProviderPathChunk}/personal-information`,
      },
      CONTACT_INFORMATION: {
        KEY: `${onboardingProviderKeyChunk}/CONTACT_INFORMATION`,
        PATH: `${onboardingProviderPathChunk}/contact-information`,
      },
      MEDICAL_LICENCE: {
        KEY: `${onboardingProviderKeyChunk}/MEDICAL_LICENCE`,
        PATH: `${onboardingProviderPathChunk}/medical-licence`,
      },
      SPECIALITY_AND_HOURS: {
        KEY: `${onboardingProviderKeyChunk}/SPECIALITY_AND_HOURS`,
        PATH: `${onboardingProviderPathChunk}/speciality-and-hours`,
      },
      CANCELLATION_SCENARIO: {
        KEY: `${onboardingProviderKeyChunk}/CANCELLATION_SCENARIO`,
        PATH: `${onboardingProviderPathChunk}/cancellation-scenario`,
      },
      RESCHEDULE_SCENARIO: {
        KEY: `${onboardingProviderKeyChunk}/RESCHEDULE_SCENARIO`,
        PATH: `${onboardingProviderPathChunk}/reschedule-scenario`,
      },
      SIGNATURE: {
        KEY: `${onboardingProviderKeyChunk}/SIGNATURE`,
        PATH: `${onboardingProviderPathChunk}/signature`,
      },
      DONE: {
        KEY: `${onboardingProviderKeyChunk}/DONE`,
        PATH: `${onboardingProviderPathChunk}/done`,
      },
    },
  },
  LOGIN: {
    KEY: 'LOGIN',
    PATH: '/login',
  },
  HIPAA_AND_PHI: {
    KEY: 'HIPAA_AND_PHI',
    PATH: '/hipaa-and-phi',
  },
  TERMS_OF_USE: {
    KEY: 'TERMS_OF_USE',
    PATH: '/terms-of-use',
  },
  PRIVACY_POLICY: {
    KEY: 'PRIVACY_POLICY',
    PATH: '/privacy-policy',
  },
  PASSWORD_RECOVERY: {
    KEY: 'PASSWORD_RECOVERY',
    PATH: '/password-recovery',
  },
  PASSWORD_RECOVERY_CHECK_EMAIL: {
    KEY: 'PASSWORD_RECOVERY',
    PATH: '/password-recovery-check-email',
  },
  PASSWORD_RECOVERY_NEW_PASSWORD: {
    KEY: 'PASSWORD_RECOVERY',
    PATH: '/password-recovery-new',
  },
  PASSWORD_RECOVERY_EXPIRED: {
    KEY: 'PASSWORD_RECOVERY_EXPIRED',
    PATH: '/password-recovery-expired',
  },
  NOTIFICATION_SETTINGS: {
    KEY: 'NOTIFICATION_SETTINGS',
    PATH: '/[role]/notification-settings',
  },
  ABOUT_US: {
    KEY: 'ABOUT_US',
    PATH: '/about-us',
  },
  WORK_WITH_US: {
    KEY: 'WORK_WITH_US',
    PATH: '/work-with-us',
  },
  CONTACT_US: {
    KEY: 'CONTACT_US',
    PATH: '/contact-us',
  },
  FAQ: {
    KEY: 'FAQ',
    PATH: '/faq',
  },
};

export default ROUTES;
