import type { Dispatch } from 'react';
import type { AnyAction } from 'redux';

const progressCallback =
  (resultCallback: (data: any) => AnyAction, dispatch: Dispatch<AnyAction>, fileName: string | undefined) =>
  (progressEvent: any) => {
    const percentFraction = progressEvent.loaded / progressEvent.total;
    const progress = Math.floor(percentFraction * 100);
    dispatch(resultCallback({ fileName, progress }));
  };

export default progressCallback;
