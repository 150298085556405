import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'assessments';

export const FETCH_PATIENT_ASSESSMENTS = createActionType(CONCEPT_NAME, 'FETCH_PATIENT_ASSESSMENTS');
export const SET_ASSESSMENT_IDS = createActionType(CONCEPT_NAME, 'SET_ASSESSMENT_IDS');
export const SET_ASSESSMENTS_LIST_TOTAL = createActionType(CONCEPT_NAME, 'SET_ASSESSMENTS_LIST_TOTAL');
export const SET_CURRENT_ASSESSMENTS_PAGE = createActionType(CONCEPT_NAME, 'SET_CURRENT_ASSESSMENTS_PAGE');

export const FETCH_PATIENT_ASSESSMENT_BY_ID = createActionType(CONCEPT_NAME, 'FETCH_PATIENT_ASSESSMENT_BY_ID');
export const DOWNLOAD_PATIENT_ASSESSMENT_BY_ID = createActionType(CONCEPT_NAME, 'DOWNLOAD_PATIENT_ASSESSMENT_BY_ID');
export const CREATE_ASSESSMENT = createActionType(CONCEPT_NAME, 'CREATE_ASSESSMENT');
export const UPDATE_ASSESSMENT = createActionType(CONCEPT_NAME, 'UPDATE_ASSESSMENT');
export const FETCH_DIAGNOSES = createActionType(CONCEPT_NAME, 'FETCH_DIAGNOSES');
export const SET_DIAGNOSES_IDS = createActionType(CONCEPT_NAME, 'SET_DIAGNOSES_IDS');
