import normalize from 'json-api-normalizer';
import type { AnyAction } from 'redux';

import httpClient from 'lib/clients/proxyClient';
import { dataApiRequest, dataApiSuccess } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import progressCallback from 'utils/progressCallback';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { createChatMessage } from '../actions';
import { setUploadProgress } from '../actions';
import { CANCEL_UPLOAD_FILE, CREATE_CHAT_MESSAGE } from '../constants';
import { createChatMessageEndpoint } from '../endpoints';

const createChatMessageOperation = createLogic<typeof createChatMessage>({
  type: CREATE_CHAT_MESSAGE,
  latest: true,
  warnTimeout: 0,

  async process({ action: { chatId, text, attachments, form }, action$ }, dispatch, done) {
    const { endpoint, url } = createChatMessageEndpoint(chatId);
    const params = new FormData();

    params.append('text', text);
    const uploadFileRequestController = new AbortController();
    const attachment = attachments[0];
    if (attachment) {
      params.append('file', attachment);
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      action$.subscribe((newAction: AnyAction) => {
        if (newAction.type === CANCEL_UPLOAD_FILE) {
          uploadFileRequestController.abort();
        }
      });

      const { data } = await httpClient.post(url, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressCallback(setUploadProgress, dispatch, attachment?.name),
        signal: uploadFileRequestController.signal,
      });
      const response = normalize(data, { endpoint });

      dispatch(dataApiSuccess({ response, endpoint }));
      if (attachment?.name) {
        dispatch(
          showMessage({
            messageText: { id: 'appointment.chat.attachment.added.success', values: { filename: attachment.name } },
          }),
        );
      }

      form.resetForm();
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    dispatch(setUploadProgress({ fileName: null, progress: null }));
    done();
  },
});

export default createChatMessageOperation;
