import { notification as AntdNotification } from 'antd';
import { useCallback, useEffect } from 'react';

import useLocale from 'hooks/useLocale';

import type { FlashMessageProps } from '../types';

const useContainer = (props: FlashMessageProps) => {
  const { id, hideMessage, messageText, messageType, duration } = props;

  const { getTranslation } = useLocale();

  /**
   * Close message
   */
  const closeMessage = useCallback(() => {
    hideMessage({ id });

    AntdNotification.close(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get message text
   */
  const getMessageText = () => {
    if (typeof messageText === 'object') {
      return getTranslation(messageText);
    }

    return messageText;
  };

  /**
   * Get message icon
   */
  const getMessageIcon = () => {
    const icons = {
      success: <i className="icon icon-checked" />,
      info: <i className="icon icon-info" />,
      warning: <i className="icon icon-alert" />,
      error: <i className="icon icon-alert" />,
    };

    type KeyType = keyof typeof icons;

    return icons[messageType as KeyType];
  };

  useEffect(() => {
    // @ts-ignore
    const messageMethod = AntdNotification[messageType];

    messageMethod({
      key: id,
      message: getMessageText(),
      duration,
      onClose: closeMessage,
      icon: getMessageIcon(),
      closeIcon: <i className="icon icon-cross" />,
      placement: 'topRight',
      top: 80,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    closeMessage,
    getMessageText,
    getMessageIcon,
  };
};

export default useContainer;
