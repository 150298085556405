import normalize from 'json-api-normalizer';
import { pluck, path } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { Notification } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchNotifications } from '../actions';
import { setNotificationsIds, setUnreadNotificationsCount, setNotificationsHasMore } from '../actions';
import { FETCH_NOTIFICATIONS } from '../constants';
import { fetchNotificationsEndpoint } from '../endpoints';

const fetchNotificationsOperation = createLogic<typeof fetchNotifications>({
  type: FETCH_NOTIFICATIONS,
  latest: true,

  async process({ action: { before } }, dispatch, done) {
    const { endpoint, url } = fetchNotificationsEndpoint;

    const params = {
      page: { before },
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, { params });
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      dispatch(setNotificationsIds(pluck<'id', Notification>('id', data.data)));
      dispatch(setUnreadNotificationsCount(path<any>(['meta', 'unread_count'], data)));
      dispatch(setNotificationsHasMore(path<any>(['meta', 'has_more'], data)));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchNotificationsOperation;
