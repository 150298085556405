import type { AxiosError } from 'axios';
import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import handleUnprocessableEntity from 'utils/form/assignFormErrorOrShowMessage';
import { isErrorStatusGone } from 'utils/getErrorStatus';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { submitAppointmentGeneralInfo } from '../actions';
import { SUBMIT_GENERAL_INFO } from '../constants';
import { submitAppointmentGeneralInfoEndpoint } from '../endpoints';

interface IBody {
  include: string;
  speciality_id: string;
  symptom_id: string | undefined;
  phone_number: string | null;
  note: string | undefined;
}

const submitAppointmentGeneralInfoOperation = createLogic<typeof submitAppointmentGeneralInfo>({
  type: SUBMIT_GENERAL_INFO,
  latest: true,

  async process({ action }, dispatch, done) {
    const { userProfileId, phoneNumber, appointmentId, specialityId, symptomId, note, onSuccess } = action.values;
    const { form } = action;
    const { endpoint, url } = submitAppointmentGeneralInfoEndpoint(appointmentId);

    const body: IBody = {
      include:
        'user_profile,user_profile.specialities,user_profile.specialities.symptoms,speciality,patient_profile,symptom,chat',
      speciality_id: specialityId,
      symptom_id: symptomId,
      phone_number: phoneNumber,
      note,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      onSuccess();
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
        withStatus: true,
      });

      if (isErrorStatusGone(error as AxiosError)) {
        dispatch(showModal({ modalType: 'MODAL_BOOKING_APPOINTMENT_GONE', modalProps: { userProfileId } }));
      } else {
        handleUnprocessableEntity({ form, error: error as AxiosError, dispatch });
      }
    }

    done();
  },
});

export default submitAppointmentGeneralInfoOperation;
