import mergeWith from 'lodash/mergeWith';
import { flatten, is } from 'ramda';

const updateDataHelper = (stateData: any, type: string, ids: (string | number)[], data: any) => {
  const objects: { [key: string]: any } = {};

  flatten([ids]).forEach((id) => {
    objects[id] = mergeWith({}, stateData[type][id], { ...data }, (objValue, srcValue) =>
      is(Array)(objValue) ? srcValue : undefined,
    );
  });

  return { [type]: objects };
};

export default updateDataHelper;
