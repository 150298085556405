import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import { actionCreator } from 'utils/state';

import {
  SET_NETWORK_CONNECTION_STATUS,
  SET_SERVER_CONNECTION_STATUS,
  FETCH_PROVIDER_SPECIALTIES,
  SET_PROVIDER_SPECIALTIES_IDS,
  FETCH_AVAILABLE_TIMEZONES,
  SET_AVAILABLE_TIMEZONES_IDS,
  FETCH_AVAILABLE_MEDICAL_TITLES,
  SET_AVAILABLE_MEDICAL_TITLES_IDS,
  FETCH_AVAILABLE_STATES,
  SET_AVAILABLE_STATES_IDS,
  SET_CURRENT_AND_PREVIOUS_PAGES,
  SET_CURRENT_PAGE,
  FETCH_BOARD_LICENSES,
  SET_BOARD_LICENCE_IDS,
  FETCH_SYMPTOMS,
  SET_SYMPTOMS_IDS,
  SEND_CONTACT_US,
  DOWNLOAD_FILE,
  SET_SOCIAL_LINK_LIST,
  FETCH_SOCIAL_LINK_LIST,
} from './constants';

export const downloadFile = actionCreator((fileUrl: string, fileName: string) => ({
  type: DOWNLOAD_FILE,
  fileUrl,
  fileName,
}));

export const setNetworkConnectionStatus = (isOnline: boolean) => ({
  type: SET_NETWORK_CONNECTION_STATUS,
  isOnline,
});

export const setServerConnectionStatus = (isReady: boolean) => ({
  type: SET_SERVER_CONNECTION_STATUS,
  isReady,
});

export const fetchProviderSpecialties = actionCreator(FETCH_PROVIDER_SPECIALTIES);

export const setProviderSpecialtiesIds = actionCreator((ids: string[]) => ({
  type: SET_PROVIDER_SPECIALTIES_IDS,
  ids,
}));

export const fetchAvailableTimezones = actionCreator(FETCH_AVAILABLE_TIMEZONES);

export const setAvailableTimezonesIds = actionCreator((ids: string[]) => ({
  type: SET_AVAILABLE_TIMEZONES_IDS,
  ids,
}));

export const fetchAvailableMedicalTitles = actionCreator(FETCH_AVAILABLE_MEDICAL_TITLES);

export const setAvailableMedicalTitlesIds = actionCreator((ids: string[]) => ({
  type: SET_AVAILABLE_MEDICAL_TITLES_IDS,
  ids,
}));

export const fetchAvailableStates = actionCreator(FETCH_AVAILABLE_STATES);

export const setAvailableStatesIds = actionCreator((ids: string[]) => ({
  type: SET_AVAILABLE_STATES_IDS,
  ids,
}));

export const fetchBoardLicenses = actionCreator(FETCH_BOARD_LICENSES);

export const setBoardLicenseIds = actionCreator((ids: string[]) => ({
  type: SET_BOARD_LICENCE_IDS,
  ids,
}));

export const setCurrentAndPreviousPages = actionCreator(
  ({ currentPage, prevPage }: { currentPage: string | null; prevPage: string | null }) => ({
    type: SET_CURRENT_AND_PREVIOUS_PAGES,
    currentPage,
    prevPage,
  }),
);

export const setCurrentPage = actionCreator((page: string) => ({
  type: SET_CURRENT_PAGE,
  page,
}));

export const sendContactUsAction = makeFormSubmitAction<{
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  description: string;
}>()(SEND_CONTACT_US);

export const fetchSymptoms = actionCreator(FETCH_SYMPTOMS);
export const setSymptomsIds = actionCreator((ids: string[]) => ({ type: SET_SYMPTOMS_IDS, ids }));

export const setSocialLinkList = actionCreator((socialLinks) => {
  return {
    type: SET_SOCIAL_LINK_LIST,
    socialLinks,
  };
});

export const fetchSocialLinkList = actionCreator(FETCH_SOCIAL_LINK_LIST);
