import type { AxiosError } from 'axios';
import normalize from 'json-api-normalizer';
import { includes, is, replace } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showModal } from 'state/modal/actions';
import type { MiddlewareErrorType } from 'types/errors';
import handleUnprocessableEntity from 'utils/form/assignFormErrorOrShowMessage';
import { isErrorStatusGone } from 'utils/getErrorStatus';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { submitAppointmentQuestionnaire } from '../actions';
import { SUBMIT_QUESTIONNAIRE } from '../constants';
import { submitAppointmentQuestionnaireEndpoint } from '../endpoints';

interface IBody {
  include: string;
  blood_pressure: string | undefined;
  heart_rate: number | undefined;
  discuss_about: string | undefined;
  symptoms: string | undefined;
  respiratory_rate: number | undefined;
  temperature: number | null;
  visit_goal: string | undefined;
  medications: boolean;
  medication_description: string | undefined;
  allergies: boolean;
  allergies_description: string | undefined;
  surgeries: boolean;
  surgeries_description: string | undefined;
  hospitalisations: boolean;
  hospitalisations_description: string | undefined;
  other_information: string | undefined;
  update_health_profile: boolean;
}

const submitAppointmentQuestionnaireOperation = createLogic<typeof submitAppointmentQuestionnaire>({
  type: SUBMIT_QUESTIONNAIRE,
  latest: true,

  async process({ action }, dispatch, done) {
    const {
      bloodPressure,
      appointmentId,
      discussAbout,
      heartRate,
      respiratoryRate,
      symptoms,
      temperature,
      visitGoal,
      medications,
      allergies,
      surgeries,
      other,
      hospitalizations,
      updateHealthProfile,
      userProfileId,
      onSuccess,
    } = action.values;
    const { form } = action;

    const { endpoint, url } = submitAppointmentQuestionnaireEndpoint(appointmentId);

    try {
      const body: IBody = {
        include: 'questionnaire',
        blood_pressure: is(String, bloodPressure) ? replace('.', '/', bloodPressure) : undefined,
        discuss_about: discussAbout,
        heart_rate: heartRate,
        respiratory_rate: respiratoryRate,
        symptoms,
        temperature,
        visit_goal: visitGoal,
        medications: medications.checked || false,
        medication_description: medications.description,
        allergies: allergies.checked || false,
        allergies_description: allergies.description,
        surgeries: surgeries.checked || false,
        surgeries_description: surgeries.description,
        hospitalisations: hospitalizations.checked || false,
        hospitalisations_description: hospitalizations.description,
        other_information: other,
        update_health_profile: includes('checked', updateHealthProfile),
      };

      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
      onSuccess();
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        skipHandlers: true,
        withStatus: true,
      });

      if (isErrorStatusGone(error as AxiosError)) {
        dispatch(showModal({ modalType: 'MODAL_BOOKING_APPOINTMENT_GONE', modalProps: { userProfileId } }));
      } else {
        handleUnprocessableEntity({ form, error: error as AxiosError, dispatch });
      }
    }

    done();
  },
});

export default submitAppointmentQuestionnaireOperation;
