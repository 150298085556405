import { SortingKeyTypes, AppointmentsSortingKeyTypes, AssessmentsSortingKeyTypes } from 'state/concepts/patient/types';

export const PATIENT_LIST_COLUMNS = [
  { title: { id: 'appointment.patient' }, dataIndex: 'patient', sortKey: SortingKeyTypes.full_name, width: '40%' },
  { title: { id: 'appointment.symptom' }, dataIndex: 'symptom', sortKey: SortingKeyTypes.symptom_name, width: '35%' },
  {
    title: { id: 'appointment.assessment.appointment_date_and_time' },
    dataIndex: 'date_time',
    sortKey: SortingKeyTypes.appointment_start_time,
  },
];

export const PATIENT_LIST_OF_APPOINTMENTS_LENGTH = 5;

export const PATIENT_LIST_OF_APPOINTMENTS_COLUMNS = [
  {
    title: { id: 'appointment.specialty' },
    key: 'specialty',
    dataIndex: 'specialty',
    sortKey: AppointmentsSortingKeyTypes.specialty_name,
  },
  {
    title: { id: 'appointment.price' },
    key: 'price',
    dataIndex: 'price',
    sortKey: AppointmentsSortingKeyTypes.price,
  },
  {
    title: { id: 'appointment.dateAndTime' },
    key: 'date_time',
    dataIndex: 'date_time',
    sortKey: AppointmentsSortingKeyTypes.appointment_start_time,
  },
  { key: 'controls', dataIndex: 'controls', width: 72, align: 'center', className: 'p-24' },
];

export const PATIENT_LIST_OF_ASSESSMENTS_LENGTH = 5;

export const PATIENT_LIST_OF_ASSESSMENTS_COLUMNS = [
  {
    title: { id: 'patientProfile.speciality.cell' },
    key: 'specialty',
    dataIndex: 'specialty',
    sortKey: AssessmentsSortingKeyTypes.specialty_name,
  },
  {
    title: { id: 'patientProfile.dateAndTime.cell' },
    key: 'date_time',
    dataIndex: 'date_time',
    sortKey: AssessmentsSortingKeyTypes.appointment_start_time,
  },
  {
    title: { id: 'patientProfile.diagnosis.cell' },
    key: 'diagnosis',
    dataIndex: 'diagnosis',
    sortKey: AssessmentsSortingKeyTypes.diagnosis,
  },
];
