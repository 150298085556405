import { createActionType } from 'utils/state';

const namespace = 'chat';

export const CREATE_CHAT_MESSAGE = createActionType(`${namespace}/CREATE_CHAT_MESSAGE`);
export const SET_MESSAGES = createActionType(`${namespace}/SET_MESSAGES`);
export const FLUSH_MESSAGES = createActionType(`${namespace}/FLUSH_MESSAGES`);
export const FETCH_CHAT_MESSAGES = createActionType(`${namespace}/FETCH_CHAT_MESSAGES`);
export const DELETE_CHAT_MESSAGE = createActionType(`${namespace}/DELETE_CHAT_MESSAGE`);
export const SET_UPLOAD_PROGRESS = createActionType(`${namespace}/SET_UPLOAD_PROGRESS`);
export const CANCEL_UPLOAD_FILE = createActionType(`${namespace}/CANCEL_UPLOAD_FILE`);
export const DELETE_CHAT_MESSAGE_SUCCESS = createActionType(`${namespace}/DELETE_CHAT_MESSAGE_SUCCESS`);
export const UPDATE_CHAT_MESSAGE = createActionType(`${namespace}/UPDATE_CHAT_MESSAGE`);
export const DELETE_CHAT_ATTACHMENT = createActionType(`${namespace}/DELETE_CHAT_ATTACHMENT`);
export const DELETE_CHAT_ATTACHMENT_SUCCESS = createActionType(`${namespace}/DELETE_CHAT_ATTACHMENT_SUCCESS`);
export const SET_EDITING_CHAT_MESSAGE = createActionType(`${namespace}/SET_EDITING_CHAT_MESSAGE`);
export const SET_SELECTED_ATTACHMENT_ID = createActionType(`${namespace}/SET_SELECTED_ATTACHMENT_ID`);
export const SET_ATTACHMENT_IDS = createActionType(`${namespace}/SET_ATTACHMENT_IDS`);
export const FETCH_CHAT_ATTACHMENTS = createActionType(`${namespace}/FETCH_CHAT_ATTACHMENTS`);
export const MARK_MESSAGES_AS_READ = createActionType(`${namespace}/MARK_MESSAGES_AS_READ`);
