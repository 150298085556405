import normalize from 'json-api-normalizer';
import { mergeAll, pickBy } from 'ramda';

import { PROVIDER_PROFILE_INCLUSIONS } from 'constants/profile';
import httpClient from 'lib/clients/proxyClient';
import { fetchProvideCalendar } from 'state/concepts/calendar/actions';
import { providerProfileSelector } from 'state/concepts/session/selectors';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { ProviderProfile, WorkingHour } from 'types/entities/session';
import type { MiddlewareErrorType } from 'types/errors';
import constructFormikWorkingHoursFromProviderData from 'utils/constructFormikWorkingHoursFromProviderData';
import { constructSpecialties, constructWorkingHours } from 'utils/constructProviderOnboardingBody';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updateProviderSpecialityAndWorkingHours, updateProviderWeekDayAvailability } from '../actions';
import { UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS, UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY } from '../constants';
import { updateProviderSpecialityAndWorkingHoursEndpoint } from '../endpoints';

const updateProviderSpecialityAndWorkingHoursOperation = createLogic<
  typeof updateProviderSpecialityAndWorkingHours | typeof updateProviderWeekDayAvailability
>({
  type: [UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS, UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY],
  latest: true,

  async process({ action, getState }, dispatch, done) {
    const { endpoint, url } = updateProviderSpecialityAndWorkingHoursEndpoint;

    const body = {
      include: `${PROVIDER_PROFILE_INCLUSIONS.WORKING_HOURS},${PROVIDER_PROFILE_INCLUSIONS.USER_PROFILES_SPECIALTIES}`,
      consultation_price_cents: action.values.consultationPrice,
      user_profiles_specialities: [],
      working_hours: [],
    };

    const providerProfile = providerProfileSelector(getState());

    // Construct specialties
    (body.user_profiles_specialities as any) = constructSpecialties(
      providerProfile?.userProfilesSpecialities || [],
      action.values.speciality as string,
    );

    // Construct working hours
    if (typeof action.values.hours !== 'undefined') {
      const workingHours = constructFormikWorkingHoursFromProviderData(providerProfile as ProviderProfile);
      const predefinedFormikHours = mergeAll([
        workingHours,
        pickBy(Boolean, action.values.hours) as { [key: string]: WorkingHour[] },
      ]);
      if (action.type === UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY) {
        (body.working_hours as any) = constructWorkingHours(
          providerProfile?.workingHours as WorkingHour[],
          predefinedFormikHours,
        );
      } else {
        // Construct working hours
        (body.working_hours as any) = constructWorkingHours(
          providerProfile?.workingHours as WorkingHour[],
          action.values.hours,
        );
      }
    }

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      if ('redirectRoute' in action.values && typeof action.values?.redirectRoute !== 'undefined') {
        redirect(action.values.redirectRoute as string);
      }

      if (action.type === UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY) {
        dispatch(fetchProvideCalendar());

        dispatch(hideModal());
      }

      dispatch(dataApiSuccess({ response, endpoint }));

      dispatch(showMessage({ messageText: { id: 'shared.changesHasBeenSaved' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default updateProviderSpecialityAndWorkingHoursOperation;
