import normalize from 'json-api-normalizer';
import { equals, propOr } from 'ramda';
import build from 'redux-object';

import { ONBOARDING_STEPS_PATIENTS, PatientOnboardingSteps } from 'constants/onboarding';
import httpClient from 'lib/clients/proxyClient';
import { setPatientProfileId } from 'state/concepts/session/actions';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchInitialPatientProfile, fetchPatientProfile } from '../actions';
import { FETCH_PATIENT_PROFILE, INITIAL_FETCH_PATIENT_PROFILE } from '../constants';
import { fetchPatientProfileEndpoint } from '../endpoints';

const fetchPatientProfileOperation = createLogic<typeof fetchPatientProfile | typeof fetchInitialPatientProfile>({
  type: [FETCH_PATIENT_PROFILE, INITIAL_FETCH_PATIENT_PROFILE],

  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = fetchPatientProfileEndpoint(action.type as any);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, {
        params: { ...((action as any)?.include && { include: (action as any).include }) },
      });

      const response = normalize(data);

      dispatch(setPatientProfileId(data.data.id));
      dispatch(dataApiSuccess({ endpoint, response }));

      const currentProfile = build(response, 'patientProfile', data.data.id);
      const redirectTo: any = propOr(undefined, currentProfile.onboardingStatus, ONBOARDING_STEPS_PATIENTS);
      if (redirectTo && !equals(PatientOnboardingSteps.completed, currentProfile.onboardingStatus)) {
        redirect(redirectTo as string);
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default fetchPatientProfileOperation;
