import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'book-appointment';

export const FETCH_PROVIDERS_SEARCH_OPTIONS = createActionType(CONCEPT_NAME, 'FETCH_PROVIDERS_SEARCH_OPTIONS');

export const FETCH_INITIAL_PROVIDERS_SEARCH_LIST = createActionType(
  CONCEPT_NAME,
  'FETCH_INITIAL_PROVIDERS_SEARCH_LIST',
);

export const FETCH_PROVIDERS_SEARCH_LIST = createActionType(CONCEPT_NAME, 'FETCH_PROVIDERS_SEARCH_LIST');

export const SET_PROVIDERS_SEARCH_LIST_IDS = createActionType(CONCEPT_NAME, 'SET_PROVIDERS_SEARCH_LIST_IDS');

export const SET_PROVIDERS_SEARCH_LIST_TOTAL = createActionType(CONCEPT_NAME, 'SET_PROVIDERS_SEARCH_LIST_TOTAL');

export const SET_PROVIDERS_SEARCH_OPTIONS_IDS = createActionType(CONCEPT_NAME, 'SET_PROVIDERS_SEARCH_OPTIONS_IDS');

export const SET_PROVIDERS_SEARCH_ACTIVE_OPTION = createActionType(CONCEPT_NAME, 'SET_PROVIDERS_SEARCH_ACTIVE_OPTION');

export const FETCH_PATIENT_PROVIDER_PROFILE_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PATIENT_PROVIDER_PROFILE_BY_ID',
);

export const FETCH_PROVIDER_AVAILABLE_TIME_SLOTS_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_AVAILABLE_TIME_SLOTS_BY_ID',
);

export const SET_PROVIDER_AVAILABLE_TIME_SLOTS = createActionType(CONCEPT_NAME, 'SET_PROVIDER_AVAILABLE_TIME_SLOTS');
export const SET_PROVIDER_ALLOWED_STATE_META = createActionType(CONCEPT_NAME, 'SET_PROVIDER_ALLOWED_STATE_META');
export const CREATE_NEW_APPOINTMENT = createActionType(CONCEPT_NAME, 'CREATE_NEW_APPOINTMENT');
export const FETCH_PATIENT_BOOK_APPOINTMENT_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PATIENT_BOOK_APPOINTMENT_BY_ID',
);

export const SUBMIT_GENERAL_INFO = createActionType(CONCEPT_NAME, 'SUBMIT_GENERAL_INFO');
export const SUBMIT_QUESTIONNAIRE = createActionType(CONCEPT_NAME, 'SUBMIT_QUESTIONNAIRE');
export const PAY_APPOINTMENT = createActionType(CONCEPT_NAME, 'PAY_APPOINTMENT');
export const PATIENT_DELETE_APPOINTMENT = createActionType(CONCEPT_NAME, 'PATIENT_DELETE_APPOINTMENT');
