import type { AxiosError } from 'axios';
import type { FormikHelpers } from 'formik';
import type { AnyAction } from 'redux';

import { isErrorStatusUnprocessableEntity } from 'utils/getErrorStatus';
import showErrorNotifications from 'utils/showErrorNotifications';

import assignFormErrors from './assignFormErrors';

const handleUnprocessableEntity = ({
  error,
  form,
  dispatch,
}: {
  error: AxiosError;
  form: FormikHelpers<any> | undefined;
  dispatch: (fn: AnyAction) => void;
}) =>
  isErrorStatusUnprocessableEntity(error) ? assignFormErrors(form, error) : showErrorNotifications({ error, dispatch });

export default handleUnprocessableEntity;
