export enum MessageMetaTypes {
  appMessage = 'air_pmd',
  startInfoMessageType = 'start_info_message',
  simpleMessage = 'simple_message',
  joinedVideoConferenceMessage = 'joined_videoconference_message',
}

export enum SlideType {
  file = 'file',
  image = 'image',
}
export enum ChatStatusTypes {
  active = 'active',
  inactive = 'inactive',
}
