import normalize from 'json-api-normalizer';

import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { fetchPatientProviderProfileById } from '../actions';
import * as constants from '../constants';
import { fetchPatientProviderProfileByIdEndpoint } from '../endpoints';

const fetchPatientProviderProfileByIdOperation = createLogic<typeof fetchPatientProviderProfileById>({
  type: constants.FETCH_PATIENT_PROVIDER_PROFILE_BY_ID,
  latest: true,

  async process({ action: { id } }, dispatch, done) {
    const { endpoint, url } = fetchPatientProviderProfileByIdEndpoint(id);

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.get(url, {
        params: {
          include:
            'contact_information,cancellation_policy,board_license_certifications,board_license_certifications.board_certification,state_licenses,user_title,specialities,working_hours',
        },
      });

      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default fetchPatientProviderProfileByIdOperation;
