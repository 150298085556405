import { cancellationReasonOptionsRoute } from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchCancellationReasonOptionsEndpoint = endpoint(
  constants.FETCH_CANCELLATION_REASON_OPTIONS,
  'GET',
  cancellationReasonOptionsRoute,
);
