import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/apiClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { saveNewPassword } from '../actions';
import { SAVE_NEW_PASSWORD } from '../constants';
import { saveNewPasswordEndpoint } from '../endpoints';

const saveNewPasswordOperation = createLogic<typeof saveNewPassword>({
  type: SAVE_NEW_PASSWORD,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = saveNewPasswordEndpoint;

    const redirectRoute = ROUTES.LOGIN.PATH;

    const body = {
      token: action.values.token,
      password: action.values.password,
      redirect_to: redirectRoute,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      await httpClient.put(url, body);

      dispatch(dataApiSuccess({ endpoint }));

      redirect(redirectRoute);
      dispatch(showMessage({ messageText: { id: 'shared.passwordUpdated' } }));
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
      });
    }

    done();
  },
});

export default saveNewPasswordOperation;
