import type { ProviderProfile } from 'types/entities/session';
import { createDateFromTimeString } from 'utils/dateHelpers';

const constructFormikWorkingHoursFromProviderData = (providerProfile: ProviderProfile) => {
  const initialHours = {
    sunday: null,
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
  };

  // Working hours
  const hours = (providerProfile?.workingHours || []).reduce((acc, item) => {
    const hour = {
      id: item.id,
      start: createDateFromTimeString(item.beginTime),
      end: createDateFromTimeString(item.endTime),
      dayWeek: item.weekday,
      fromBackend: true,
    };

    let existedHours = acc[item.weekday as keyof typeof acc] || [];
    (existedHours as any) = [...existedHours, hour];

    (acc as any)[item.weekday] = existedHours;

    return acc;
  }, initialHours);

  return hours;
};

export default constructFormikWorkingHoursFromProviderData;
