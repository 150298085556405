import { combineReducers } from 'redux';

import * as constants from './constants';
import type { ErrorState, ErrorActions } from './types';

const statusCode = (state: ErrorState['statusCode'] = null, action: ErrorActions) => {
  switch (action.type) {
    case constants.SET_STATUS_CODE:
      return action.statusCode;
    default:
      return state;
  }
};

export default combineReducers({
  statusCode,
});
