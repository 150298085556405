export enum SortingKeyTypes {
  appointment_start_time = 'appointment_start_time',
  full_name = 'full_name',
  symptom_name = 'symptom_name',
}

export type PatientState = {
  sort: SortType;
  search: string;
};

export enum SortingDirectionTypes {
  asc = 'asc',
  desc = 'desc',
}

export type SortType = {
  sortKey: keyof typeof SortingKeyTypes;
  direction: keyof typeof SortingDirectionTypes;
};

export enum AppointmentsSortingKeyTypes {
  appointment_start_time = 'appointments.start_time',
  specialty_name = 'appointments.specialty_name',
  price = 'appointments.price_cents',
}

export type AppointmentsState = {
  patientProfileAppointmentsSort: AppointmentsSortType;
  patientProfileAppointmentsSearch: string;
};

export type AppointmentsSortType = {
  sortKey: keyof typeof AppointmentsSortingKeyTypes;
  direction: keyof typeof SortingDirectionTypes;
};

export enum AssessmentsSortingKeyTypes {
  appointment_start_time = 'appointment.start_time',
  specialty_name = 'speciality.name',
  diagnosis = 'diagnosis.description',
}

export type AssessmentsState = {
  patientProfileAssessmentsSort: AssessmentsSortType;
  patientProfileAssessmentsSearch: string;
};

export type AssessmentsSortType = {
  sortKey: keyof typeof AssessmentsSortingKeyTypes;
  direction: keyof typeof SortingDirectionTypes;
};
