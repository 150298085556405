import type { GetServerSidePropsContext } from 'next';

import type { UserRole } from 'constants/app';
import type { BoardLicense, StateLicense, UploadAttachment } from 'types/entities/session';
import makeFormSubmitAction from 'utils/form/makeFormSubmitAction';
import actionCreator from 'utils/state/actionCreator';
import type { WorkingHour } from 'views/shared/WorkingHourField/types';

import * as constants from './constants';

export type PatientHealthProfileTypes = {
  medications: {
    checked: boolean | undefined;
    description: string;
  };
  allergies: {
    checked: boolean | undefined;
    description: string;
  };
  surgeries: {
    checked: boolean | undefined;
    description: string;
  };
  hospitalizations: {
    checked: boolean | undefined;
    description: string;
  };
  other: string;
};

export const updateProviderPersonalInformation = makeFormSubmitAction<{
  about: string | undefined;
  attachments: UploadAttachment[];
  deletedAttachmentsIds: string[];
  avatarFile: File | undefined;
  avatarUrl: string | undefined;
  birthDate: Date | undefined;
  collegeUniversitySchool: string | undefined;
  ethnicity: string | undefined;
  fellowship: string | undefined;
  focus: string | undefined;
  furtherProfessionalInterests: string | undefined;
  genderIdentity: string | undefined;
  goals: string | undefined;
  graduateSchool: string | undefined;
  hobbies: string | undefined;
  internship: string | undefined;
  languages: string[] | undefined;
  maritalStatus: string | undefined;
  publishedResearch: string | undefined;
  race: string | undefined;
  residency: string | undefined;
  sex: string | undefined;
  timeZone: string | undefined;
  undergraduateSchool: string | undefined;
  volunteerWork: string | undefined;
}>()(constants.UPDATE_PROVIDER_PERSONAL_INFORMATION);

export const updatePatientPersonalInformation = makeFormSubmitAction<{
  avatarFile: File | undefined;
  avatarUrl: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthDate: Date | undefined;
  timeZone: string | undefined;
  genderIdentity: string | undefined;
  sex: string | undefined;
  maritalStatus: string | undefined;
  age: string | undefined;
  race: string | undefined;
  ethnicity: string | undefined;
  languages: string[] | undefined;
  about: string | undefined;
}>()(constants.UPDATE_PATIENT_PERSONAL_INFORMATION);

export const updateProviderContactInformation = makeFormSubmitAction<{
  phone: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  city: string | undefined;
  streetAddress: string | undefined;
}>()(constants.UPDATE_PROVIDER_CONTACT_INFORMATION);

export const updatePatientContactInformation = makeFormSubmitAction<{
  phone: string | undefined;
  state: string | undefined;
  zip: string | undefined;
  city: string | undefined;
  streetAddress: string | undefined;
}>()(constants.UPDATE_PATIENT_CONTACT_INFORMATION);

export const updateProviderCancellationScenario = makeFormSubmitAction<{
  hours72: undefined | string;
  hours48: undefined | string;
  hours24: undefined | string;
  hour: undefined | string;
}>()(constants.UPDATE_PROVIDER_CANCELLATION_SCENARIO);

export const deleteProviderAvatar = actionCreator(constants.DELETE_PROVIDER_AVATAR);

export const updateProviderAvatar = actionCreator((file: File) => ({
  type: constants.UPDATE_PROVIDER_AVATAR,
  file,
}));

export const deletePatientAvatar = actionCreator(constants.DELETE_PATIENT_AVATAR);

export const updatePatientAvatar = actionCreator((file: File) => ({
  type: constants.UPDATE_PATIENT_AVATAR,
  file,
}));

export const fetchInitialProviderProfile = actionCreator(constants.INITIAL_FETCH_PROVIDER_PROFILE);
export const fetchProviderProfile = actionCreator(
  ({ include, ctx }: { include?: string; ctx?: GetServerSidePropsContext }) => ({
    type: constants.FETCH_PROVIDER_PROFILE,
    include,
    ctx,
  }),
);

export const fetchInitialPatientProfile = actionCreator(constants.INITIAL_FETCH_PATIENT_PROFILE);
export const fetchPatientProfile = actionCreator(
  ({ include, ctx }: { include?: string; ctx?: GetServerSidePropsContext }) => ({
    type: constants.FETCH_PATIENT_PROFILE,
    include,
    ctx,
  }),
);

export const updateProviderEmail = makeFormSubmitAction<{
  isEmailSent: boolean;
  email: string;
  emailUpdated: string;
  password: string;
}>()(constants.UPDATE_PROVIDER_EMAIL);

export const updatePatientEmail = makeFormSubmitAction<{
  isEmailSent: boolean;
  email: string;
  emailUpdated: string;
  password: string;
}>()(constants.UPDATE_PATIENT_EMAIL);

export const updatePassword = makeFormSubmitAction<{
  userRole: UserRole;
  password: string;
  passwordChanged: string;
}>()(constants.UPDATE_PASSWORD);
export const updatePatientHealthProfile = makeFormSubmitAction<PatientHealthProfileTypes>()(
  constants.UPDATE_PATIENT_HEALTH_PROFILE,
);

export const updatePatientEmailByToken = actionCreator((emailToken: string, ctx: GetServerSidePropsContext) => ({
  type: constants.UPDATE_PATIENT_EMAIL_BY_TOKEN,
  emailToken,
  ctx,
}));

export const updateProviderEmailByToken = actionCreator((emailToken: string, ctx: GetServerSidePropsContext) => ({
  type: constants.UPDATE_PROVIDER_EMAIL_BY_TOKEN,
  emailToken,
  ctx,
}));

export const editProviderMedicalLicense = makeFormSubmitAction<{
  boardLicenses: BoardLicense[];
  medicalDegree: string | undefined;
  nationalProviderIdentifier: string | undefined;
  stateLicenses: StateLicense[];
  title: string | undefined;
  redirectRoute?: string | undefined;
}>()(constants.EDIT_PROVIDER_MEDICAL_LICENSE);

export const updateProviderSpecialityAndWorkingHours = makeFormSubmitAction<{
  speciality: string | undefined;
  consultationPrice: number | undefined;
  hours?: {
    sunday: WorkingHour[] | null;
    monday: WorkingHour[] | null;
    tuesday: WorkingHour[] | null;
    wednesday: WorkingHour[] | null;
    thursday: WorkingHour[] | null;
    friday: WorkingHour[] | null;
    saturday: WorkingHour[] | null;
  };
  redirectRoute?: string;
}>()(constants.UPDATE_PROVIDER_SPECIALITY_AND_WORKING_HOURS);

export const updateProviderWeekDayAvailability = makeFormSubmitAction<{
  speciality: string | undefined;
  consultationPrice: number | undefined;
  hours: {
    [weekday: string]: WorkingHour[] | null;
  };
}>()(constants.UPDATE_PROVIDER_WEEK_DAY_AVAILABILITY);

export const deactivateProviderAccount = actionCreator(constants.DEACTIVATE_PROVIDER_ACCOUNT);

export const deactivatePatientAccount = actionCreator(constants.DEACTIVATE_PATIENT_ACCOUNT);

export const createPayoutMethod = makeFormSubmitAction<{
  routingNumber: string;
  accountNumber: string;
}>()(constants.CREATE_PAYOUT_METHOD);

export const fetchPayoutMethod = actionCreator(constants.FETCH_PAYOUT_METHOD);
export const fetchChecklist = actionCreator(constants.FETCH_CHECKLIST);
export const removePayoutMethod = actionCreator(constants.REMOVE_PAYOUT_METHOD);
