import { createActionType } from 'utils/state';

export const CONCEPT_NAME = 'patientProfile';

export const PATIENT_LIST_LENGTH = 5;

export const FETCH_PROVIDER_PATIENT_PROFILES = createActionType(CONCEPT_NAME, 'FETCH_PROVIDER_PATIENT_PROFILES');
export const SET_PROVIDER_PATIENT_PROFILE_IDS = createActionType(CONCEPT_NAME, 'SET_PROVIDER_PATIENT_PROFILE_IDS');
export const SET_CURRENT_PATIENTS_PAGE = createActionType(CONCEPT_NAME, 'SET_CURRENT_PATIENTS_PAGE');
export const SET_PATIENT_SORT_ORDER = createActionType(CONCEPT_NAME, 'SET_PATIENT_SORT_ORDER');
export const SET_PATIENTS_LIST_TOTAL = createActionType(CONCEPT_NAME, 'SET_PATIENTS_LIST_TOTAL');
export const SET_PATIENT_SEARCH = createActionType(CONCEPT_NAME, 'SET_PATIENT_SEARCH');

export const FETCH_PROVIDER_PATIENT_PROFILE_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_PATIENT_PROFILE_BY_ID',
);
export const FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENT_BY_ID',
);
// Appointments
export const FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_BY_ID',
);
export const SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_IDS = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_IDS',
);
export const SET_PROVIDER_PATIENT_PROFILE_CURRENT_APPOINTMENTS_PAGE = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_CURRENT_APPOINTMENTS_PAGE',
);
export const SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_LIST_TOTAL = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_LIST_TOTAL',
);
export const SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SEARCH = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SEARCH',
);
export const SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SORT_ORDER = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_APPOINTMENTS_SORT_ORDER',
);
// Assessments
export const FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID = createActionType(
  CONCEPT_NAME,
  'FETCH_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_BY_ID',
);
export const SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_IDS = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_IDS',
);
export const SET_PROVIDER_PATIENT_PROFILE_CURRENT_ASSESSMENTS_PAGE = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_CURRENT_ASSESSMENTS_PAGE',
);
export const SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_LIST_TOTAL = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_LIST_TOTAL',
);
export const SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SEARCH = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SEARCH',
);
export const SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SORT_ORDER = createActionType(
  CONCEPT_NAME,
  'SET_PROVIDER_PATIENT_PROFILE_ASSESSMENTS_SORT_ORDER',
);
