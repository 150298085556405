import fetchProviderPatientProfileAppointmentsById from './fetchProviderPatientProfileAppointmentsById';
import fetchProviderPatientProfileAssessmentById from './fetchProviderPatientProfileAssessmentById';
import fetchProviderPatientProfileAssessmentsById from './fetchProviderPatientProfileAssessmentsById';
import fetchProviderPatientProfileById from './fetchProviderPatientProfileById';
import fetchProviderPatientProfiles from './fetchProviderPatientProfiles';

const operations = [
  fetchProviderPatientProfiles,
  fetchProviderPatientProfileById,
  fetchProviderPatientProfileAppointmentsById,
  fetchProviderPatientProfileAssessmentsById,
  fetchProviderPatientProfileAssessmentById,
];

export default operations;
