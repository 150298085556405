import { combineReducers } from 'redux';

import * as constants from './constants';
import type { AppointmentActions } from './types';

export const appointmentIds = (state = [], action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_APPOINTMENT_IDS:
      return action.ids;
    default:
      return state;
  }
};

export const appointmentsListTotal = (state: number | null = null, action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_APPOINTMENTS_LIST_TOTAL:
      return action.total;
    default:
      return state;
  }
};

export const appointmentsPageNumber = (state = 1, action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_CURRENT_APPOINTMENTS_PAGE:
      return action.page;
    default:
      return state;
  }
};

export const assessmentVisible = (state = false, action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_ASSESSMENT_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

export const drawerVisible = (state = false, action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_DRAWER_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

export const questionnaireVisible = (state = false, action: AppointmentActions) => {
  switch (action.type) {
    case constants.SET_QUESTIONNAIRE_VISIBLE:
      return action.visible;
    default:
      return state;
  }
};

const appointmentReducer = combineReducers({
  appointmentIds,
  appointmentsListTotal,
  appointmentsPageNumber,
  assessmentVisible,
  drawerVisible,
  questionnaireVisible,
});

export default appointmentReducer;
