import type { BoardLicense } from 'types/entities/session';
import isPresent from 'utils/isPresent';

const constructBoardLicenses = <T extends BoardLicense[], V extends BoardLicense[]>(
  existedBoardLicenses: T,
  newBoardLicenses: V,
) => {
  const result = [];
  const existedBoardLicensesClone = [...existedBoardLicenses];
  const newBoardLicensesClone = [...newBoardLicenses];

  // Check existed licenses
  if (Array.isArray(existedBoardLicensesClone)) {
    for (let i = 0; i < existedBoardLicensesClone.length; i += 1) {
      const existingBoardLicense = existedBoardLicensesClone[i] as BoardLicense;

      // Add destroy attribute
      result.push({
        id: existingBoardLicense.id,
        _destroy: true,
      });
    }
  }

  // Add board licenses
  if (Array.isArray(newBoardLicensesClone)) {
    for (let j = 0; j < newBoardLicensesClone.length; j += 1) {
      const license = newBoardLicensesClone[j] as BoardLicense;

      result.push({
        board_certification_id: license.certificationType,
        number: isPresent(license.number) ? license.number : null,
      });
    }
  }

  return result;
};

export default constructBoardLicenses;
