import format from 'date-fns/format';
import { equals, flatten } from 'ramda';

import type { WorkingHour as WorkingHourProvider } from 'types/entities/session';
import isPresent from 'utils/isPresent';
import type { WorkingHour } from 'views/shared/WorkingHourField/types';

const constructWorkingHours = <T extends Array<WorkingHourProvider>, V extends object>(
  existedWorkingHours: T,
  newWorkingHours: V,
) => {
  const result = [];

  // Array of the new working hours.
  let arrayOfNewHours = Object.values(newWorkingHours);
  arrayOfNewHours = flatten(arrayOfNewHours).filter(isPresent);

  // Check existed hours
  for (let i = 0; i < existedWorkingHours.length; i += 1) {
    const existedWorkingHour = existedWorkingHours[i] as WorkingHourProvider;

    const findIndex = arrayOfNewHours.findIndex((item) => {
      const startTime = format(item.start as Date, 'HH:mm');
      const endTime = format(item.end as Date, 'HH:mm');

      return (
        equals(item.dayWeek, existedWorkingHour.weekday) &&
        equals(startTime, existedWorkingHour.beginTime) &&
        equals(endTime, existedWorkingHour.endTime)
      );
    });
    // Add destroy attribute (The hour was deleted by user on the UI)
    if (findIndex === -1) {
      result.push({
        id: existedWorkingHour.id,
        _destroy: true,
      });
    }

    // Delete from body if already exists (The hour already existed)
    if (findIndex !== -1) {
      arrayOfNewHours.splice(findIndex, 1);
    }
  }

  // Add new working hour
  for (let j = 0; j < arrayOfNewHours.length; j += 1) {
    const hour = arrayOfNewHours[j] as WorkingHour;

    result.push({
      weekday: hour.dayWeek,
      begin_time: format(hour.start as Date, 'HH:mm'),
      end_time: format(hour.end as Date, 'HH:mm'),
    });
  }

  return result;
};

export default constructWorkingHours;
