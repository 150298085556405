import dynamic from 'next/dynamic';

const DynamicModalAvatarUpload = dynamic(() => import('views/shared/ModalAvatarUpload'));
const DynamicModalConfirm = dynamic(() => import('views/shared/ModalConfirm'));
const DynamicModalBookingAppointmentGone = dynamic(() => import('views/shared/ModalBookingAppointmentGone'));
const DynamicModalInfo = dynamic(() => import('views/shared/ModalInfo'));
const DynamicModalChangePassword = dynamic(() => import('views/dashboard/ModalChangePassword'));
const DynamicModalSupport = dynamic(() => import('views/dashboard/ModalSupport'));
const DynamicModalPayoutMethod = dynamic(() => import('views/dashboard/ModalPayoutMethod'));
const DynamicModalUpdateEmail = dynamic(() => import('views/dashboard/ModalUpdateEmail'));
const DynamicModalAssignWorkingHourSingleDay = dynamic(() => import('views/shared/ModalAssignWorkingHour/SingleDay'));
const DynamicModalAssignWorkingHourDayWeek = dynamic(() => import('views/shared/ModalAssignWorkingHour/DayWeek'));
const DynamicModalBookAppointment = dynamic(() => import('views/shared/ModalBookAppointment'));
const DynamicModalCancelAppointment = dynamic(() => import('views/shared/ModalCancelAppointment'));
const DynamicModalRescheduleAppointment = dynamic(() => import('views/shared/ModalRescheduleAppointment'));
const DynamicModalPreviewAssessment = dynamic(() => import('views/dashboard/ModalPreviewAssessment'));
const DynamicVideoConferenceSettingsModal = dynamic(() => import('views/shared/VideoConference/SettingsModal'));
const DynamicModalSignature = dynamic(() => import('views/shared/ModalSignature'));

export const MODAL_COMPONENTS = {
  MODAL_AVATAR_UPLOAD: DynamicModalAvatarUpload,
  MODAL_CONFIRM: DynamicModalConfirm,
  MODAL_BOOKING_APPOINTMENT_GONE: DynamicModalBookingAppointmentGone,
  MODAL_INFO: DynamicModalInfo,
  MODAL_PASSWORD_CHANGE: DynamicModalChangePassword,
  MODAL_SUPPORT: DynamicModalSupport,
  MODAL_PAYOUT_METHOD: DynamicModalPayoutMethod,
  MODAL_EMAIL_UPDATE: DynamicModalUpdateEmail,
  MODAL_ASSIGN_WORKING_HOUR_SINGLE_DAY: DynamicModalAssignWorkingHourSingleDay,
  MODAL_ASSIGN_WORKING_HOUR_EACH_DAY_WEEK: DynamicModalAssignWorkingHourDayWeek,
  MODAL_BOOK_APPOINTMENT: DynamicModalBookAppointment,
  MODAL_CANCEL_APPOINTMENT: DynamicModalCancelAppointment,
  MODAL_RESCHEDULE_APPOINTMENT: DynamicModalRescheduleAppointment,
  MODAL_PREVIEW_ASSESSMENT: DynamicModalPreviewAssessment,
  MODAL_VIDEO_CONFERENCE_SETTINGS_MODAL: DynamicVideoConferenceSettingsModal,
  MODAL_SIGNATURE: DynamicModalSignature,
};
