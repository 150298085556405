import {
  patientAssessmentsRoute,
  patientAssessmentRoute,
  downloadPatientAssessmentRoute,
  providerAppointmentAssessmentsRoute,
  providerAssessmentDetailRoute,
  providerAssessmentDiagnosesRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchPatientAssessmentsEndpoint = endpoint(
  constants.FETCH_PATIENT_ASSESSMENTS,
  'GET',
  patientAssessmentsRoute,
);

export const fetchPatientAssessmentByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PATIENT_ASSESSMENT_BY_ID, 'GET', patientAssessmentRoute(id));
export const downloadPatientAssessmentByIdEndpoint = (id: string) =>
  endpoint(constants.DOWNLOAD_PATIENT_ASSESSMENT_BY_ID, 'POST', downloadPatientAssessmentRoute(id));
export const createAssessmentEndpoint = (id: string) =>
  endpoint(constants.CREATE_ASSESSMENT, 'POST', providerAppointmentAssessmentsRoute(id));
export const updateAssessmentEndpoint = (id: string) =>
  endpoint(constants.UPDATE_ASSESSMENT, 'PATCH', providerAssessmentDetailRoute(id));
export const fetchDiagnosesEndpoint = endpoint(constants.FETCH_DIAGNOSES, 'GET', providerAssessmentDiagnosesRoute);
