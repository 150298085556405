import React from 'react';

import FlashMessage from './FlashMessage';
import useContainer from './hook';
import type { FlashMessageProps } from './types';

function FlashMessageRootComponent() {
  const { messages, hideMessage } = useContainer();

  return (
    <>
      {Object.keys(messages).map((key) => {
        const message: FlashMessageProps = messages[key as keyof typeof messages];

        return <FlashMessage {...message} key={message.id} hideMessage={hideMessage} />;
      })}
    </>
  );
}

export default FlashMessageRootComponent;
