import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest, dataApiFailure } from 'state/data/actions';
import { showMessage } from 'state/flash-messages/actions';
import { hideModal } from 'state/modal/actions';
import type { JsonApiError } from 'types/json-api/raw';
import showErrorNotifications from 'utils/showErrorNotifications';
import createLogic from 'utils/state/createLogic';

import type { sendContactUsAction } from '../actions';
import { SEND_CONTACT_US } from '../constants';
import { sendContactUsEndpoint } from '../endpoints';

const sendContactUsOperation = createLogic<typeof sendContactUsAction>({
  type: SEND_CONTACT_US,
  latest: true,

  async process({ action }, dispatch, done) {
    const { endpoint, url } = sendContactUsEndpoint;
    const { email, firstName, lastName, phoneNumber, description } = action.values;

    dispatch(dataApiRequest({ endpoint }));

    try {
      const body = { email, first_name: firstName, last_name: lastName, phone_number: phoneNumber, description };

      await httpClient.post(url, body);

      dispatch(dataApiSuccess({ endpoint }));
      dispatch(showMessage({ messageText: { id: 'shared.successSendContactUs' } }));
      action.form.resetForm({ values: { email: '', firstName: '', lastName: '', phoneNumber: '+1', description: '' } });
      dispatch(hideModal());
    } catch (error) {
      const status = (error as JsonApiError).response?.status;

      dispatch(dataApiFailure({ endpoint, status }));

      showErrorNotifications({ error, dispatch });
    }

    done();
  },
});

export default sendContactUsOperation;
