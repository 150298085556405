import createLogic from 'utils/state/createLogic';

import type { setNotificationSidebarVisibility } from '../actions';
import { fetchNotifications } from '../actions';
import { SET_SIDEBAR_VISIBILITY } from '../constants';

const observeSidebarVisibilityOperation = createLogic<typeof setNotificationSidebarVisibility>({
  type: SET_SIDEBAR_VISIBILITY,
  latest: true,

  async process({ action }, dispatch, done) {
    if (action.isVisible === true) {
      dispatch(fetchNotifications());
    }

    done();
  },
});

export default observeSidebarVisibilityOperation;
