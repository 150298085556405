import normalize from 'json-api-normalizer';

import ROUTES from 'constants/routes';
import httpClient from 'lib/clients/proxyClient';
import { dataApiSuccess, dataApiRequest } from 'state/data/actions';
import type { MiddlewareErrorType } from 'types/errors';
import redirect from 'utils/redirect';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { updatePatientHealthProfile } from '../actions';
import { UPDATE_PATIENT_HEALTH_PROFILE } from '../constants';
import { updatePatientHealthProfileEndpoint } from '../endpoints';

const updatePatientHealthProfileOperation = createLogic<typeof updatePatientHealthProfile>({
  type: UPDATE_PATIENT_HEALTH_PROFILE,
  latest: true,

  async process({ action }, dispatch, done) {
    const redirectRoute = ROUTES.ONBOARDING.PATIENT.DONE.PATH;

    const { endpoint, url } = updatePatientHealthProfileEndpoint;

    const body = {
      include: 'health_profile',
      medications: action.values.medications.checked || false,
      medication_description: action.values.medications.description,
      allergies: action.values.allergies.checked || false,
      allergies_description: action.values.allergies.description,
      surgeries: action.values.surgeries.checked || false,
      surgeries_description: action.values.surgeries.description,
      hospitalisations: action.values.hospitalizations.checked || false,
      hospitalisations_description: action.values.hospitalizations.description,
      other_information: action.values.other,
    };

    try {
      dispatch(dataApiRequest({ endpoint }));

      const { data } = await httpClient.put(url, body);
      const response = normalize(data);

      dispatch(dataApiSuccess({ endpoint, response }));

      redirect(redirectRoute);
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        form: action.form,
      });
    }

    done();
  },
});

export default updatePatientHealthProfileOperation;
