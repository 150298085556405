import {
  patientAppointmentCancelRoute,
  patientAppointmentRescheduleRoute,
  patientAppointmentsRoute,
  providerAppointmentDetailsRoute,
  providerAppointmentsRoute,
  providerAppointmentAssessmentsRoute,
  patientAppointmentDetailsRoute,
  patientQuestionnairesDetailsRoute,
  providerAppointmentCancelRoute,
} from 'lib/apiRoutes';
import endpoint from 'utils/endpoint';

import * as constants from './constants';

export const fetchPatientAppointmentsEndpoint = endpoint(
  constants.FETCH_PATIENT_APPOINTMENTS,
  'GET',
  patientAppointmentsRoute,
);

export const fetchProviderAppointmentsEndpoint = endpoint(
  constants.FETCH_PROVIDER_APPOINTMENTS,
  'GET',
  providerAppointmentsRoute,
);

export const cancelPatientAppointmentEndpoint = (id: string) =>
  endpoint(constants.PATIENT_CANCEL_APPOINTMENT, 'POST', patientAppointmentCancelRoute(id));

export const cancelProviderAppointmentEndpoint = (id: string) =>
  endpoint(constants.PROVIDER_CANCEL_APPOINTMENT, 'POST', providerAppointmentCancelRoute(id));

export const fetchProviderAppointmentByIdWithChatEndpoint = (id: string) =>
  endpoint(constants.FETCH_PROVIDER_APPOINTMENT_BY_ID_WITH_CHAT, 'POST', providerAppointmentDetailsRoute(id));

export const reschedulePatientAppointmentByIdEndpoint = (id: string) =>
  endpoint(constants.RESCHEDULE_PATIENT_APPOINTMENT, 'GET', patientAppointmentRescheduleRoute(id));
export const fetchProviderAppointmentsAssessmentByIdEndpoint = (id: string) =>
  endpoint(constants.FETCH_PROVIDER_APPOINTMENTS_ASSESSMENT_BY_ID, 'POST', providerAppointmentAssessmentsRoute(id));
export const fetchPatientAppointmentByIdWithChatEndpoint = (id: string) =>
  endpoint(constants.FETCH_PATIENT_APPOINTMENT_BY_ID_WITH_CHAT, 'GET', patientAppointmentDetailsRoute(id));
export const submitEditAppointmentQuestionnaireEndpoint = (id: string) =>
  endpoint(constants.SUBMIT_EDIT_QUESTIONNAIRE, 'PUT', patientQuestionnairesDetailsRoute(id));
