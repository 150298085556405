import type { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { path } from 'ramda';

export const errorStatus = (error: AxiosError) => path(['response', 'status'], error);

export const isErrorStatusUnprocessableEntity = (error: AxiosError) =>
  errorStatus(error) === StatusCodes.UNPROCESSABLE_ENTITY;

export const isErrorStatusUnauthorized = (error: AxiosError) => errorStatus(error) === StatusCodes.UNAUTHORIZED;

export const isErrorStatusForbidden = (error: AxiosError) => errorStatus(error) === StatusCodes.FORBIDDEN;

export const isErrorStatusNotFound = (error: AxiosError) => errorStatus(error) === StatusCodes.NOT_FOUND;

export const isErrorStatusGone = (error: AxiosError) => errorStatus(error) === StatusCodes.GONE;
