import { isEmpty, pluck } from 'ramda';

import httpClient from 'lib/clients/proxyClient';
import { dataApiRequest, dataApiSuccess, dataUpdateItems, removeMetaFieldsOfEndpoint } from 'state/data/actions';
import type { Message } from 'types/entities/chat';
import type { MiddlewareErrorType } from 'types/errors';
import requestErrorHandler from 'utils/requestErrorHandler';
import createLogic from 'utils/state/createLogic';

import type { markMessagesAsRead } from '../actions';
import { MARK_MESSAGES_AS_READ } from '../constants';
import { fetchChatMessagesEndpoint, markChatMessagesAsReadEndpoint } from '../endpoints';
import { unreadMessagesSelector } from '../selectors';

const markMessagesAsReadOperation = createLogic<typeof markMessagesAsRead>({
  type: MARK_MESSAGES_AS_READ,
  latest: true,
  warnTimeout: 0,

  async process({ action: { chatId }, getState }, dispatch, done) {
    const state = getState();
    const unreadMessages = unreadMessagesSelector(state) as Message[];
    const messageIds = pluck('id', unreadMessages);

    const { endpoint, url } = markChatMessagesAsReadEndpoint(chatId);

    try {
      if (!isEmpty(messageIds)) {
        dispatch(dataApiRequest({ endpoint }));

        await httpClient.put(url, { message_ids: messageIds });

        const kind = 'message';
        const ids = messageIds;
        const data = {
          attributes: {
            readAt: new Date(),
          },
        };

        dispatch(dataUpdateItems({ kind, ids, data }));
        dispatch(removeMetaFieldsOfEndpoint(fetchChatMessagesEndpoint(chatId).endpoint, 'unreadCount'));
        dispatch(dataApiSuccess({ endpoint }));
      }
    } catch (error) {
      requestErrorHandler({
        error: error as MiddlewareErrorType,
        dispatch,
        endpoint,
        withStatus: true,
      });
    }

    done();
  },
});

export default markMessagesAsReadOperation;
