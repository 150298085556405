import createAssessmentOperation from './createAssessment';
import downloadPatientAssessmentByIdOperation from './downloadPatientAssessmentById';
import fetchDiagnosesOperation from './fetchDiagnoses';
import fetchPatientAssessmentByIdOperation from './fetchPatientAssessmentById';
import fetchPatientAssessmentsOperation from './fetchPatientAssessments';
import updateAssessmentOperation from './updateAssessment';

const operations = [
  fetchDiagnosesOperation,
  updateAssessmentOperation,
  createAssessmentOperation,
  fetchPatientAssessmentsOperation,
  fetchPatientAssessmentByIdOperation,
  downloadPatientAssessmentByIdOperation,
];

export default operations;
