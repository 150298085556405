import type { FormikHelpers } from 'formik';
import { pick, pathOr, omit } from 'ramda';

import type { MiddlewareErrorType } from 'types/errors';
import formatJsonApiErrors from 'utils/formatJsonApiErrors';

const assignFormErrors = (form: FormikHelpers<any> | undefined, error: MiddlewareErrorType, statusKeys = ['base']) => {
  const errors = pathOr(null, ['response', 'data', 'errors'], error);
  const formattedErrors = formatJsonApiErrors(errors);

  if (typeof form !== 'undefined') {
    form.setStatus(pick(statusKeys, formattedErrors));
    form.setErrors(omit(statusKeys, formattedErrors));
  }
};

export default assignFormErrors;
