/* istanbul ignore file // TODO write tests */

import normalize from 'json-api-normalizer';
import { keys, pathOr, propOr } from 'ramda';

import { MessageMetaTypes } from 'constants/chat';
import { setAttachmentIds, setMessages } from 'state/concepts/chat/actions';
import { currentUserProfileSelector } from 'state/concepts/session/selectors';
import { dataApiSuccess } from 'state/data/actions';
import createLogic from 'utils/state/createLogic';

import type { receiveData } from '../actions';
import { RECEIVE_DATA } from '../constants';

const receiveDataOperation = createLogic<typeof receiveData>({
  type: RECEIVE_DATA,

  process({ action, getState }, dispatch, done) {
    const { data } = action;

    const response = normalize(data);
    const profile = currentUserProfileSelector(getState());
    const messageAttachments = propOr(null, 'messageAttachment', response);
    dispatch(
      dataApiSuccess({
        response,
      }),
    );

    const metaType = pathOr(undefined, ['data', 'attributes', 'meta', 'type'], data);
    const recipientChatUserId = pathOr(null, ['data', 'attributes', 'recipient_chat_user_ids', 0], data);
    const currentChatUserId = pathOr(undefined, ['chatUser', 'id'], profile);
    if (metaType === MessageMetaTypes.joinedVideoConferenceMessage) {
      if (Number(currentChatUserId) === recipientChatUserId) {
        dispatch(setMessages([data.data.id]));
      }
    } else {
      dispatch(setMessages([data.data.id]));
    }
    if (messageAttachments) {
      dispatch(setAttachmentIds(keys(messageAttachments)));
    }
    done();
  },
});

export default receiveDataOperation;
